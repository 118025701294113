<template>
  <b-card
    class="card-congratulation-medal"
  >
    <b-row>
      <b-col cols="12">
        <h5>Welcome 🎉 {{ adminInfo.firstname }}!</h5>
        <b-card-text class="font-small-3">
          You look awesome today
        </b-card-text>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <h3 class="mb-75 mt-2 pt-50 text-primary text-capitalize">
          {{ adminInfo.role.toLowerCase() }}
        </h3>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          View Profile
        </b-button>
      </b-col>
      <b-col cols="4">
        <b-avatar
          size="70"
          variant="primary"
          class="font-large-1 ml-auto d-block"
          badge-variant="success"
        >
          {{ adminInfo.firstname[0] }}{{ adminInfo.lastname[0] }}
        </b-avatar>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getAuthUser } from '@/auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    const { firstname, lastname, userrole: { role } } = getAuthUser()
    return {
      adminInfo: { firstname, lastname, role },
    }
  },
}
</script>
