<template>
  <div>
    <fundraisers-filter
      @filter="filterTable($event)"
      @reset="resetTable"
    />
    <b-card
      title="Row details support"
      no-body
    >
      <div style="padding: 20px">
        <b-row>
          <b-col cols="4">
            <div style="display: flex;">
              <span style="margin-top: 7px">Show</span>
              <div style="margin-left: 15px">
                <b-form-select
                  v-model="limit"
                  :options="options"
                  @change="setPerPage"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div style="display: flex;">
              <span style="margin-top: 7px">Search</span>
              <div style="margin-left: 15px; width: 100%">
                <b-form-input
                  id="search"
                  v-model="filter"
                  placeholder="Search"
                  style="width: 100%"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="outline-primary"
              style="width: 100%; color: #7367f0"
            >
              <feather-icon
                icon="ExternalLinkIcon"
                size="16"
                class="mr-1"
              />
              Export
            </b-button>
          </b-col>
          <b-col cols="3">
            <b-button
              v-b-modal.create-fundraiser-type
              variant="primary"
              style="width: 100%; color: #7367f0"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-1"
              />
              Create Fundraiser
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center h-100 mt-16"
        style="margin-top: 4rem"
      >
        <feather-icon
          icon="LoaderIcon"
          class="spinner"
          size="60"
        />
      </div>
      <b-table
        v-else
        :items="response.data"
        :fields="fields"
        responsive
        :filter="filter"
        class="mb-0"
      >
        <template #cell(id)="data">
          <a
            class="cursor-pointer"
            @click="routeToViewFundraiser(data.item.id)"
          >
            {{ data.item.id }}
          </a>
        </template>

        <template #cell(date)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(startdate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(enddate)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.nextPayoutDate).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.nextPayoutDate).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(accountname)="data">
          <div>
            <div style="margin-left: 12px;">
              <div>
                {{
                  data.item.bankdetail ? data.item.bankdetail.accountname : "-"
                }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(targetAmount)="data">
          <div>
            <div style="margin-left: 12px;">
              <div>
                {{ getFormattedAmount(data.item.targetAmount) }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(raisedAmount)="data">
          <div>
            <div style="margin-left: 12px;">
              <div>
                {{ getFormattedAmount(data.item.raisedAmount) }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(name)="data">
          <div
            v-if="data.item.userdetail"
            style="display: flex"
            class="cursor-pointer"
            @click="routeToViewUser(data.item.userdetail.userid)"
          >
            <b-avatar
              :text="getAvatarText(data.item.userdetail)"
              variant="light-danger"
            />
            <div style="margin-left: 12px;">
              <div>
                {{ data.item.userdetail.firstname }}
                {{ data.item.userdetail.lastname }}
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.userdetail.email }}
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(bankname)="data">
          <div v-if="data.item.bankdetail">
            <div style="margin-left: 12px;">
              <div>
                {{ data.item.bankdetail ? data.item.bankdetail.bankname : "-" }}
              </div>
              <div class="font-small-2 text-muted">
                {{
                  data.item.bankdetail
                    ? data.item.bankdetail.accountnumber
                    : "-"
                }}
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(userCategory)="data">
          <b-badge
            v-if="data.item.userdetail"
            :variant="status[0][data.item.userdetail.userCategory]"
          >
            {{ data.item.userdetail.userCategory }}
          </b-badge>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(fundraiserCategory)="data">
          <b-badge
            v-if="data.item.category"
            :variant="catstatus[0][data.item.category.categoryname]"
          >
            {{ data.item.category.categoryname }}
          </b-badge>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            :variant="withdrawalstatus[0][data.item.status]"
          >
            {{ data.item.status }}
          </b-badge>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(fundraiserCycle)="data">
          <div v-if="data.item.fundraiserCycle">
            {{ data.item.fundraiserCycle }}
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(actions)="data">
          <div style="display: flex">
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="align-middle mr-25"
              style="cursor: pointer"
              @click="routeTo(`/fundraisers/${data.item.id}/view`)"
            />

            <feather-icon
              icon="Edit2Icon"
              size="16"
              class="align-middle mr-25"
              style="margin-left: 20px; cursor: pointer"
              @click="routeTo(`/fundraisers/${data.item.id}/edit`)"
            />

            <feather-icon
              v-b-modal.action-modal-2
              icon="TrashIcon"
              size="16"
              class="align-middle mr-25"
              style="margin-left: 20px; cursor: pointer"
              color="red"
              @click="fundraiserId = data.item.id"
            />
          </div>
        </template>
      </b-table>
      <div
        style="padding: 20px;display: flex; justify-content: space-between; "
      >
        <div style="color: #B9B9C3;">
          Showing {{ getEntryNumber }} to {{ getEntriesCount }} of
          {{ response.metadata ? response.metadata.totalRecords : 0 }} entries
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="response.metadata ? response.metadata.totalRecords : 0"
          :per-page="limit"
          align="right"
          size="sm"
          class="my-0"
          @change="handlePaginationChange"
        />
      </div>
    </b-card>
    <create-fundraiser-type-modal />
    <action-modal-2
      :action="'delete'"
      @action="actOnFundraiser($event)"
    />
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import FundraisersFilter from "@/@core/components/filters/FundraisersFilter.vue";
import CreateFundraiserTypeModal from "@/@core/components/fundraisers/modals/CreateFundraiserTypeModal.vue";
import {
  doBankApproval,
  doFundraiserApproval,
  doWithdrawalApproval,
  updateVerificationStatus, setFundraiserStatus
} from "@/service/api.service";
import ActionModal2 from '@/@core/components/fundraisers/modals/ActionModal2.vue';

const [res, apiRequest] = useApi("approvals/fundraisers");

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    FundraisersFilter,
    CreateFundraiserTypeModal,
    ActionModal2,
  },
  data() {
    return {
      response: res,
      currentPage: 1,
      offset: 0,
      limit: 50,
      perPage: 10,
      filters: {},
      filter: '',
      imageURL: "",
      fundraiserId: '',
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      fields: [
        // 'row_selector',
        { key: "id", label: "fundraiser id" },
        {key: 'title', label: 'Title'},
        { key: "name", label: "name" },
        { key: "date", label: "created date" },
        { key: "userCategory", label: "user category" },
        { key: "fundraiserCategory", label: "fundraiser category" },
        { key: "fundraiserType", label: "fundraiser type" },
        { key: "fundraiserCycle", label: "fundraiser cycle" },
        { key: "targetAmount", label: "target" },
        {key: 'raisedAmount', label: 'Amount raised'},
        { key: "duration", label: "duration" },
        { key: "startdate", label: "start date" },
        { key: "enddate", label: "end date" },
        { key: "status", label: "Status" },
        "actions",
      ],
      status: [
        {
          ABSENT: "light-primary",
          STANDARD: "light-success",
          PROLIFICFUNDRAISER: "light-danger",
          VIP: "light-warning",
          6: "light-info",
        },
      ],
      catstatus: [
        {
          Music: "light-primary",
          Wedding: "light-success",
          "Community Projects": "light-danger",
          Health: "light-warning",
          Charity: "light-info",
          Education: "light-info",
          Startups: "light-info",
        },
      ],
      withdrawalstatus: [
        {
          PAID: "light-success",
          APPROVED: "light-success",
          HIDDEN: "light-success",
          PENDING: "light-warning",
          REJECTED: "light-danger",
          UNSUCCESFUL: "light-danger",
          EXPIRED: "light-danger",
          UNSUCCESSFUL: "light-danger",
          SUSPENDED: "light-danger",
        },
      ],
    };
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  watch: {
    "response.isFailure": function(isFailure) {
      if (!isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: this.response.error || "Failed to fetch resource",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  async created() {
    await this.getFundraisers();
  },
  methods: {
    dayjs,
    async filterTable(filters) {
      this.filters = filters;
      await this.getFundraisers();
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    actOnFundraiser(type) {
      setFundraiserStatus(this.fundraiserId, type)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast("Success", "success", response.message);
            this.getFundraisers()
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    async resetTable() {
      this.filters = {};
      this.currentPage = 1;
      await this.getFundraisers();
    },
    getFundraisers(offset = 0) {
      apiRequest({ ...this.filters, offset, limit: this.limit });
    },
    getFormattedAmount(amount) {
      return `NGN ${amount.toLocaleString()}`;
    },
    routeTo(path) {
      this.$router.push(path);
    },
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    setPerPage(e) {
      this.limit = e;
      this.getFundraisers(this.offset);
    },
    handlePaginationChange(event) {
      this.offset = (event - 1) * this.limit;
      this.getFundraisers(this.offset);
    },

    routeToNewUser() {
       this.$router.push(`/users/new-user`);
    },
    routeToViewFundraiser(fundraiserId){
      this.$router.push(`fundraisers/${fundraiserId}/view`)
    },
    routeToViewUser(userId){
      this.$router.push(`/users/${userId}/view`)
    }
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}
</style>
