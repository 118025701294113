<template>
  <div>
    <sign-ups-filter @filter="filterTable($event)" @reset="resetTable" />
    <b-card title="Row details support" no-body>
      <div style="padding: 20px">
        <b-row>
          <b-col cols="7">
            <div style="display: flex">
              <span style="margin-top: 7px">Show</span>
              <div style="margin-left: 15px">
                <b-form-select
                  v-model="limit"
                  :options="options"
                  @change="setPerPage"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div style="display: flex">
              <span style="margin-top: 7px">Search</span>
              <div style="margin-left: 15px; width: 100%">
                <b-form-input
                  id="search"
                  v-model="filter"
                  placeholder="Search"
                  style="width: 100%"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="outline-primary"
              style="width: 100%; color: #7367f0"
            >
              <feather-icon icon="ExternalLinkIcon" size="16" class="mr-1" />
              Export
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center h-100 mt-16"
        style="margin-top: 4rem"
      >
        <feather-icon icon="LoaderIcon" class="spinner" size="60" />
      </div>
      <b-table
        v-else
        :items="response.data"
        :fields="fields"
        :filter="filter"
        responsive
        class="mb-0"
      >
        <template #cell(idFile)="data">
          <div
            v-if="data.value"
            v-b-modal.modal-center
            class="file"
            @click="viewImage(data)"
          >
            <img class="file-img" :src="data.value" />
          </div>
        </template>
        <template #cell(date)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(name)="data">
          <div
            style="display: flex"
            class="cursor-pointer"
            @click="routeToViewUser(data.item.userid)"
          >
            <b-avatar :text="getAvatarText(data.item)" variant="light-danger" />
            <div style="margin-left: 12px">
              <div>{{ data.item.firstname }} {{ data.item.lastname }}</div>
              <div class="font-small-2 text-muted">
                {{ data.item.email }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(accountname)="data">
          <div>
            <div style="margin-left: 12px">
              <div>
                {{
                  data.item.bankdetail ? data.item.bankdetail.accountname : "-"
                }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(bankname)="data">
          <div>
            <div style="margin-left: 12px">
              <div>
                {{ data.item.bankdetail ? data.item.bankdetail.bankname : "-" }}
              </div>
              <div class="font-small-2 text-muted">
                {{
                  data.item.bankdetail
                    ? data.item.bankdetail.accountnumber
                    : "-"
                }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(userCategory)="data">
          <b-badge :variant="status[0][data.value]">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(verificationStatus)="data">
          <b-badge :variant="verstatus[0][data.value]">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <feather-icon
            icon="XIcon"
            size="16"
            class="align-middle mr-25"
            style="cursor: pointer"
            @click="(reasonModal = true), (userToReject = data)"
          />
          <feather-icon
            icon="CheckIcon"
            size="16"
            class="text-body align-middle mr-25"
            style="margin-left: 20px; cursor: pointer"
            @click="updateUserVerification(data, 'VERIFIED')"
          />
        </template>
      </b-table>
      <div style="padding: 20px; display: flex; justify-content: space-between">
        <div style="color: #b9b9c3">
          Showing {{ getEntryNumber }} to {{ getEntriesCount }} of
          {{ response.metadata ? response.metadata.totalRecords : 0 }} entries
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="response.metadata ? response.metadata.totalRecords : 0"
          :per-page="limit"
          align="right"
          size="sm"
          class="my-0"
          @change="handlePaginationChange"
        />
      </div>
    </b-card>
    <b-modal id="modal-center" centered hide-footer>
      <div style="padding: 30px; text-align: center; padding-bottom: 40px">
        <div style="font-size: 24px; font-weight: 500">User ID</div>
        <div
          style="
            font-size: 14px;
            font-weight: 400;
            padding-top: 10px;
            padding-bottom: 24px;
          "
        >
          Uploaded User ID
        </div>
        <b-img
          :src="imageURL"
          fluid
          alt="Responsive image"
          style="width: 369px; height: 222px"
        />
      </div>
    </b-modal>
    <b-modal
      id="reason-modal"
      v-model="reasonModal"
      title="Reject User's Registration?"
      centered
      hide-footer
    >
      <b-form
        @submit.prevent="
          updateUserVerification(
            userToReject,
            'UNVERIFIED',
            reason == 'Others' ? other_reason : reason
          )
        "
      >
        <b-form-group class="" label="Select a reason for rejection">
          <b-form-checkbox-group v-model="reason" :options="reasons" />
          <b-form-checkbox v-model="others">
            Other, please specify
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="">
          <b-form-textarea
            v-if="others"
            v-model="other_reason"
            placeholder="Enter your reasons here"
          />
        </b-form-group>
        <b-button
          :disabled="
            loading || !reason || (reason == 'Others' && !other_reason)
          "
          type="submit"
          variant="primary"
        >
          <b-spinner v-if="loading" small />
          <span v-else>Reject User Account</span>
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
    BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BForm,
  BFormTextarea,
  BFormCheckboxGroup,
  BSpinner,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import SignUpsFilter from "@/@core/components/filters/SignUpsFilter.vue";
import { updateVerificationStatus } from "@/service/api.service";

const [res, apiRequest] = useApi("approvals/users");

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    SignUpsFilter,
    BForm,
    BFormTextarea,
    BFormCheckboxGroup,
    BSpinner, BFormCheckbox
  },
  data() {
    return {
      loading: false,
      reasonModal: false,
      response: res,
      currentPage: 1,
      offset: 0,
      limit: 50,
      perPage: 10,
      filters: {},
      filter: "",
      imageURL: "",
      reason: [],
      reasons: [
        "Name does not match the name on your bank account",
        "Did not provide a real bank account. You must provide a real bank account",
        "Provide a valid government Issued ID - Drivers License, International Passport, NIN card, or Voter's card",
        "Provide a valid BVN",
        "You registered as a corporate but used a personal name",
        "You registered as personal but used a corporate name",
        "Invalid Bank details provided",
      ],
      other_reason: "",others:false,
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      fields: [
        // 'row_selector',
        { key: "userid", label: "user id" },
        { key: "name", label: "Name" },
        { key: "accountname", label: "Bank Account Name" },
        { key: "idFile", label: "Uploaded ID" },
        { key: "bankname", label: "Bank Account" },
        { key: "date", label: "registration date" },
        { key: "userCategory", label: "User Category" },
        { key: "verificationStatus", label: "Status" },
        "actions",
      ],
      status: [
        {
          ABSENT: "light-primary",
          STANDARD: "light-success",
          PROLIFICFUNDRAISER: "light-danger",
          VIP: "light-warning",
          6: "light-info",
        },
      ],
      verstatus: [
        {
          VERIFIED: "light-success",
          UNVERIFIED: "light-warning",
        },
      ],
    };
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  watch: {
    "response.isFailure": function (isFailure) {
      if (!isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: this.response.error || "Failed to fetch resource",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    dayjs,
    async filterTable(filters) {
      this.filters = filters;
      await this.getUsers();
    },
    async resetTable() {
      this.filters = {};
      this.currentPage = 1;
      await this.getUsers();
    },
    getUsers(offset = 0) {
      apiRequest({ ...this.filters, offset, limit: this.limit });
    },
    updateUserVerification(data, status, reason) {
      this.loading = true;
      if(this.others) {
        this.reason.push(this.other_reason)
      }
      const payload = {
        userid: data.item.userid,
        verificationStatus: status,
        reasonForRejection: reason,
      };
      updateVerificationStatus(payload)
        .then((res) => res.json())
        .then(async (resp) => {
          this.showToast("Success", "success", resp.message);
          this.reason = [];
          this.others = false;
          await this.getUsers();
          this.currentPage = 1;
          this.loading = false;
          this.reasonModal = false;
        });
    },
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    setPerPage(e) {
      this.limit = e;
      this.getUsers(this.offset);
    },
    handlePaginationChange(event) {
      this.offset = (event - 1) * this.limit;
      this.getUsers(this.offset);
    },
    routeToNewUser() {
      this.$router.push(`/users/new-user`);
    },
    routeToViewUser(userId) {
      this.$router.push(`/users/${userId}/view`);
    },
    viewImage(data) {
      this.imageURL = data.item.idFile;
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant,
        },
      });
    },
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}
</style>
