<template>
  <b-modal id="reset-password" centered hide-footer>
    <div style="padding: 30px; text-align: center;">
      <div style="font-size: 24px; font-weight: 500">User ID</div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px;"
      >
        Reset Password
      </div>
      <b-img
        :src="''"
        fluid
        alt="Responsive image"
        style="width: 369px; height: 222px;"
      />
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
export default {};
</script>

<style scoped></style>
