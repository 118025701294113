/* eslint-disable */
export const USER_PERMISSIONS = {
    CREATE_FUNDRAISERS: 'CREATE_FUNDRAISERS',
    VIEW_FUNDRAISERS: 'VIEW_FUNDRAISERS', 
    EDIT_FUNDRAISERS: 'EDIT_FUNDRAISERS', 
    APPROVE_FUNDRAISERS: 'APPROVE_FUNDRAISERS',
    REJECT_FUNDRAISERS: 'REJECT_FUNDRAISERS',
    VIEW_DASHBOARD: 'VIEW_DASHBOARD',
    VIEW_BVN: 'VIEW_BVN',
    VIEW_USER: 'VIEW_USER',
    CREATE_USER: 'CREATE_USER',
    EDIT_USER: 'EDIT_USER',
    MESSAGE_USER: 'MESSAGE_USER',
    APPROVE_SIGNUP: 'APPROVE_SIGNUP',
    APPROVE_BANK_ACCOUNT: 'APPROVE_BANK_ACCOUNT',
    APPROVE_WITHDRAWALS: 'APPROVE_WITHDRAWALS',
    MANAGE_USERS: 'MANAGE_USERS',
    MANAGE_ROLES: 'MANAGE_ROLES',
    MANAGE_PAYMENT_SYSTEMS: 'MANAGE_PAYMENT_SYSTEMS',
    MANAGE_CMS: 'MANAGE_CMS',
    MANAGE_KPI: 'MANAGE_KPI',
    MANAGE_REPORTS: 'MANAGE_REPORTS',
    MANAGE_CRM: 'MANAGE_CRM',
    VIEW_ANALYTICS: 'VIEW_ANALYTICS',
    CREATE_WITHDRAWALS: 'CREATE_WITHDRAWALS',
    DELETE_USER: 'DELETE_USER'
}
