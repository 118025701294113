<template>
  <b-modal
    id="transaction-details"
    ref="transaction-details-modal"
    centered
    hide-footer
  >
    <div style="padding: 25px; padding-top: 0px">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Transaction Details
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        View details of your transaction below
      </div>
      <div v-if="transactionData">
        <div class="flex space-between">
          <div>
            <div class="bold">
              {{
                transactionData.userdetail
                  ? `${transactionData.userdetail.firstname} ${transactionData.userdetail.lastname}`
                  : "--"
              }}
            </div>
            <div>{{ transactionData.createdAt }}</div>
          </div>
          <div class="text-right">
            <div class="bold text-green">
              {{ transactionData.currency }} {{ transactionData.amount }}
            </div>
            <div>
              via
              {{
                transactionData.paymentMethod
                  ? transactionData.paymentMethod
                  : "--"
              }}
            </div>
          </div>
        </div>

        <div class="flex space-between mt-2">
          <div>
            <div>BANK</div>
            <div class="bold">
              {{
                transactionData.bankCode
                  ? getBank(transactionData.bankCode)
                  : "--"
              }}
            </div>
          </div>
          <div class="text-right">
            <div class="">ACCOUNT NO</div>
            <div class="bold">
              {{
                transactionData.accountNumber
                  ? transactionData.accountNumber
                  : "--"
              }}
            </div>
          </div>
        </div>

        <div class="flex space-between mt-2">
          <div>
            <div>CARD TYPE</div>
            <div class="bold">
              {{ transactionData.cardType ? transactionData.cardType : "--" }}
            </div>
          </div>
          <div class="text-right">
            <div class="">CARD LAST FOUR DIGITS</div>
            <div class="bold">
              {{
                transactionData.cardLast4Digits
                  ? transactionData.cardLast4Digits
                  : "--"
              }}
            </div>
          </div>
        </div>

        <div class="flex space-between mt-2">
          <div>
            <div>REMARKS</div>
            <div class="bold">--</div>
          </div>
          <div class="text-right">
            <div class="">TRANSACTION REFERENCE</div>
            <div class="bold">{{ transactionData.transactionReference }}</div>
          </div>
        </div>

        <div class="flex space-between mt-2">
          <div>
            <div>FUNDRAISER ID</div>
            <div
              class="bold cursor-pointer"
              @click="routeToViewFundraiser(transactionData.fundraiserId)"
              v-if="transactionData.fundraiserId"
            >
              {{ transactionData.fundraiserId }}
            </div>
            <div v-else>--</div>
          </div>
          <div class="text-right">
            <div class="">TRANSACTION TYPE</div>
            <div
              class="bold "
              :class="
                transactionData
                  ? transTypes[0][transactionData.transactionType]
                  : ''
              "
            >
              {{ transactionData.transactionType }}
            </div>
          </div>
        </div>

        <div class="flex space-between mt-2">
          <div>
            <div>PAYMENT SYSTEM</div>
            <div class="bold">
              {{
                transactionData.paymentChannel
                  ? transactionData.paymentChannel
                  : "--"
              }}
            </div>
          </div>
          <div class="text-right">
            <div class="">CREATED BY</div>
            <div class="bold">
              {{ transactionData.email ? transactionData.email : "--" }}
            </div>
          </div>
        </div>

        <div class="flex space-between mt-2">
          <div>
            <div>NOTES</div>
            <div class="bold">
              --
            </div>
          </div>
          <div class="text-right">
            <div class="">STATUS</div>
            <b-badge :variant="status[0][transactionData.status]">
              {{ transactionData.status }}
            </b-badge>
          </div>
        </div>
      </div>
      <div style="text-align: center" v-else>No data</div>

      <div style="text-align: center" class="mt-1">
        <b-button variant="primary" type="submit" @click="closeModal"
          >Done</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { banksdata } from "@/data/banks";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  props: {
    data: {
      type: Object,
    },
  },

  data() {
    return {
      question: null,
      answer: null,
      transactionData: null,
      transTypes: [
        {
          DONATION: "text-green",
          TOPUP: "text-green",
          "TOP UP": "text-green",
          PROMOTION: "text-red",
          WITHDRAWAL: "text-red",
        },
      ],
      status: [
        {
          PAID: "light-success",
          INITIATED: "light-warning",
          INITIALIZED: "light-warning",
        },
      ],
    };
  },

  watch: {
    data(data) {
      this.transactionData = data;
    },
  },

  methods: {
    closeModal() {
      this.$nextTick(() => {
        this.$refs["transaction-details-modal"].toggle("#toggle-btn");
      });
    },
    getBank(bankCode) {
      const findBank = banksdata.find(
        (bank) => bankCode === bank.code || bankCode === bank.nipCode
      );
      if (findBank) {
        return findBank.name;
      }

      return "--";
    },
    routeToViewFundraiser(fundraiserId) {
      this.$router.push(`fundraisers/${fundraiserId}/view`);
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.text-right {
  text-align: right;
}
.bold {
  font-weight: bold;
}
.text-green {
  color: #017622;
}
.text-red {
  color: #fd5f5f;
}
</style>
