/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { isAuthValid, isUserLoggedIn } from '@/auth/utils'
import store from '../store'
import { isPermitted } from '@/@core/utils/utils'
import { USER_PERMISSIONS } from '@/data/permissions'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/index.vue'),
      meta: {},
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/index.vue'),
      meta: {},
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: () => import('../views/transactions/index.vue'),
      meta: {},
    },
    {
      path: '/messages',
      name: 'messages',
      component: () => import('../views/messages/index.vue'),
      meta: {},
    },
    {
      path: '/users/new-user',
      name: 'new-user',
      component: () => import('@/views/users/NewUser.vue'),
      meta: {},
    },
    {
      path: '/approvals/sign-ups',
      name: 'sign-ups',
      component: () => import('@/views/approvals/SignUps.vue'),
      meta: {},
    },
    {
      path: '/approvals/bank-account',
      name: 'bank-account',
      component: () => import('@/views/approvals/BankAccounts.vue'),
      meta: {},
    },
    {
      path: '/approvals/withdrawals',
      name: 'withdrawals',
      component: () => import('@/views/approvals/Withdrawals.vue'),
      meta: {},
    },
    {
      path: '/approvals/promote-and-earn-withdrawals',
      name: 'promote-and-earn-withdrawals',
      component: () => import('@/views/approvals/PnEWithdrawals.vue'),
      meta: {},
    },
    {
      path: '/approvals/fundraisers',
      name: 'fundraisers',
      component: () => import('@/views/approvals/Fundraisers.vue'),
      meta: {},
    },
    {
      path: '/approvals/edited-fundraisers',
      name: 'edited-fundraisers',
      component: () => import('@/views/approvals/EditedFundraisers.vue'),
      meta: {},
    },
    {
      path: '/approvals/advert-promo',
      name: 'advert-promo',
      component: () => import('@/views/approvals/AdvertPromo.vue'),
      meta: {},
    },
    {
      path: '/fundraisers',
      name: 'fundraiser',
      component: () => import('@/views/fundraisers/index.vue'),
      meta: {},
    },
    {
      path: '/fundraisers/onetime/create',
      name: 'create-onetime-fundraiser',
      component: () => import('@/views/fundraisers/create/CreateOneTimeFundraiser.vue'),
      meta: {},
    },
    {
      path: '/fundraisers/permanent/create',
      name: 'create-permanent-fundraiser',
      component: () => import('@/views/fundraisers/create/CreatePermanentFundraiser.vue'),
      meta: {},
    },
    {
      path: '/fundraisers/:fundraiserId/view',
      name: 'view-fundraiser',
      component: () => import('@/views/fundraisers/ViewFundraiser.vue'),
      meta: {},
    },
    {
      path: '/fundraisers/:fundraiserId/view-details',
      name: 'view-fundraiser-details',
      component: () => import('@/views/fundraisers/ViewFundraiserDetails.vue'),
      meta: {},
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/index.vue'),
      meta: {},
    },
    {
      path: '/settings/manage-users',
      name: 'manage-users',
      component: () => import('../views/adminusers/index.vue'),
      meta: {},
    },
    {
      path: '/settings/manage-roles',
      name: 'manage-roles',
      component: () => import('../views/roles/index.vue'),
      meta: {},
    },
    {
      path: '/settings/about-us',
      name: 'about-us',
      component: () => import('@/views/settings/AboutUs.vue'),
      meta: {},
    },
    {
      path: '/settings/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/settings/PrivacyPolicy.vue'),
      meta: {},
    },
    {
      path: '/settings/terms-conditions',
      name: 'terms-conditions',
      component: () => import('@/views/settings/TermsAndConditions.vue'),
      meta: {},
    },
    {
      path: '/settings/faqs',
      name: 'faqs',
      component: () => import('@/views/settings/FAQs.vue'),
      meta: {},
    },
    {
      path: '/settings/exchange-rates',
      name: 'exchange-rates',
      component: () => import('@/views/settings/ExchangeRates.vue'),
      meta: {},
    },
    {
      path: '/settings/withdraw-funds',
      name: 'withdraw-funds',
      component: () => import('@/views/settings/WithdrawFunds.vue'),
      meta: {},
    },
    {
      path: '/settings/charges-settings',
      name: 'charges-settings',
      component: () => import('@/views/settings/Charges.vue'),
      meta: {},
    },
    {
      path: '/users/:userId/view',
      name: 'user-details',
      component: () => import('@/views/users/UserDetails.vue'),
      meta: {},
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const permissions = router.app.$store.state.auth.permissions

  const isLoggedIn = isAuthValid()

  const message = 'You do not have permission to access this page'

  if (to.name !== 'login' && !isLoggedIn) return next({ name: 'login' })
  if (to.name === 'fundraiser' && !isPermitted(USER_PERMISSIONS.VIEW_FUNDRAISERS, permissions)) return alert(message)
  if (to.name === 'create-onetime-fundraiser' && !isPermitted(USER_PERMISSIONS.CREATE_FUNDRAISERS, permissions)) return alert(message)
  if (to.name === 'create-recurring-fundraiser' && !isPermitted(USER_PERMISSIONS.CREATE_FUNDRAISERS, permissions)) return alert(message)
  if (to.name === 'view-fundraiser' && !isPermitted(USER_PERMISSIONS.VIEW_FUNDRAISERS, permissions)) return alert(message)
  if (to.name === 'view-fundraiser-details' && !isPermitted(USER_PERMISSIONS.VIEW_FUNDRAISERS, permissions)) return alert(message)
  if (to.name === 'fundraisers' && !isPermitted(USER_PERMISSIONS.APPROVE_FUNDRAISERS, permissions)) return alert(message)
  if (to.name === 'bank-account' && !isPermitted(USER_PERMISSIONS.APPROVE_BANK_ACCOUNT, permissions)) return alert(message)
  if (to.name === 'withdrawals' && !isPermitted(USER_PERMISSIONS.APPROVE_WITHDRAWALS, permissions)) return alert(message)
  if (to.name === 'sign-ups' && !isPermitted(USER_PERMISSIONS.APPROVE_SIGNUP, permissions)) return alert(message)
  if (to.name === 'manage-users' && !isPermitted(USER_PERMISSIONS.MANAGE_USERS, permissions)) return alert(message)
  if (to.name === 'manage-roles' && !isPermitted(USER_PERMISSIONS.MANAGE_ROLES, permissions)) return alert(message)
  if (to.name === 'users' && !isPermitted(USER_PERMISSIONS.VIEW_USER, permissions)) return alert(message)
  if (to.name === 'new-user' && !isPermitted(USER_PERMISSIONS.CREATE_USER, permissions)) return alert(message)
  if (to.name === 'user-details' && !isPermitted(USER_PERMISSIONS.VIEW_USER, permissions)) return alert(message)
  if (to.name === 'messages' && !isPermitted(USER_PERMISSIONS.MESSAGE_USER, permissions)) return alert(message)
  if (to.name === 'withdraw-funds' && !isPermitted(USER_PERMISSIONS.CREATE_WITHDRAWALS, permissions)) return alert(message)

  // // if (!canNavigate(to)) return next({ name: 'misc-not-authorized' })

  if (to.meta?.redirectIfLoggedIn && isLoggedIn) return next({ name: 'dashboard' })

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
