<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="UserIcon" />
        <span>Overview</span>
      </template>
      <user-overview></user-overview>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="LockIcon" />
        <span>Fundraisers</span>
      </template>

      <user-fundraisers></user-fundraisers>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="LockIcon" />
        <span>Wallets</span>
      </template>

      <user-wallets></user-wallets>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="FlagIcon" />
        <span>Transactions</span>
      </template>

      <user-transactions></user-transactions>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="BellIcon" />
        <span>Messages</span>
      </template>

      <user-messages></user-messages>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="LinkIcon" />
        <span>Log History</span>
      </template>

      <user-log-history></user-log-history>
    </b-tab>
  </b-tabs>
</template>

<script>
/* eslint-disable */
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import UserOverview from "./UserOverview.vue";
import UserFundraisers from "./UserFundraisers.vue";
import UserTransactions from "./UserTransactions.vue";
import UserLogHistory from "./UserLogHistory.vue";
import UserMessages from "./UserMessages.vue";
import UserWallets from "./UserWallets.vue";

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    UserOverview,
    UserFundraisers,
    UserTransactions,
    UserLogHistory,
    UserMessages,
    UserWallets,
  },
};
</script>