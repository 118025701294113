<template>
  <div>

    <div style="font-size: 24px; font-weight: 500; text-align: center;">
      Crop Image {{ aspectRatio }}
    </div>
    <div
      style="padding: 25px;"
      class="cropper-container"
    >
      <cropper
        ref="cropper"
        class="cropper"
        :src="image"
        :stencil-props="{
          aspectRatio: aspectRatio,
        }"
        @change="change"
      />
    </div>
    <div class="text-center mb-2">
      <!-- basic -->
      <b-button
        variant="outline-secondary"
        class="ml-1"
        @click="$emit('close')"
      >Cancel</b-button>
      <b-button
        variant="primary"
        type="submit"
        class="ml-2"
        @click="handleSave"
      >Add Image</b-button>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
  import { BButton } from "bootstrap-vue";
  import { Cropper } from "vue-advanced-cropper";
  import "vue-advanced-cropper/dist/style.css";
  
  export default {
    components: {
      BButton,
      Cropper,
    },
  
    data() {
      return {
        croppedImage: null,
      };
    },
  
    props: {
      image: {
        type: String,
      },
      open: {
        type: Boolean,
        default: false,
      },
      aspectRatio: {
        type: Number,
        default: 3 / 2
      }
    },
  
    watch: {
      open(value) {
        this.toggleModal();
        this.$emit('close')
      },
    },
  
    methods: {
      toggleModal() {
        this.$nextTick(() => {
          this.$refs["image-cropper-modal"].toggle("#toggle-btn");
        });
      },
      change({ canvas }) {
        this.croppedImage = canvas.toDataURL('image/jpeg', 0.5);
      },
      handleSave() {
        this.$emit("add", this.croppedImage);
      },
    },
  };
  </script>
  
  <style scoped></style>
  