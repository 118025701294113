<template>
  <b-modal id="action-modal-2" ref="action-modal" centered hide-footer>
    <div style="padding: 25px;">
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Are you sure you want to {{ action }} this fundraiser ?
      </div>
      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button variant="primary" @click="$emit('action', action)" style="text-transform: capitalize">{{ action }}</b-button>
        <b-button variant="outline-secondary" class="ml-1" @click="closeModal">Discard</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  data() {
    return {
    };
  },

  props: {
    action: {
      type: String,
    },
  },

  methods: {
    closeModal() {
      this.$nextTick(() => {
        this.$refs["action-modal-2"].toggle("#toggle-btn");
      });
    },
  },
};
</script>

<style scoped></style>
