<template>
  <div>
    <b-card title="Row details support" no-body>
      <div style="padding: 20px">
        <b-row>
          <b-col cols="5">
            <div style="display: flex;">
              <span style="margin-top: 7px">Show</span>
              <div style="margin-left: 15px">
                <b-form-select
                  v-model="limit"
                  :options="options"
                  @change="setPerPage"
                  disabled
                />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div style="display: flex;">
              <span style="margin-top: 7px">Search</span>
              <div style="margin-left: 15px; width: 100%">
                <b-form-input
                  id="search"
                  placeholder="Search"
                  style="width: 100%"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="outline-primary"
              style="width: 100%; color: #7367f0"
            >
              <feather-icon icon="ExternalLinkIcon" size="16" class="mr-1" />
              Export
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              style="width: 100%"
              v-b-modal.new-admin-user
              @click="setEditMode(false)"
            >
              Add New User
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center h-100 mt-16"
        style="margin-top: 4rem"
      >
        <feather-icon icon="LoaderIcon" class="spinner" size="60" />
      </div>
      <b-table
        :items="response.data"
        :fields="fields"
        responsive
        class="mb-0"
        v-else
      >
        <template #cell(date)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(name)="data">
          <div
            style="display: flex"
            class="cursor-pointer"
            @click="viewUserDetails(data)"
          >
            <b-avatar :text="getAvatarText(data.item)" variant="light-danger" />
            <div style="margin-left: 12px;">
              <div>{{ data.item.firstname }} {{ data.item.lastname }}</div>
              <div class="font-small-2 text-muted">
                {{ data.item.email }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(role)="data">
          <div>{{ data.item.userrole.role }}</div>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[0][data.value]">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <div v-if="data.item.role !== 1">
            <feather-icon
              icon="EditIcon"
              size="16"
              class="text-body align-middle mr-25"
              @click="setEditMode(true, data.item)"
              style="cursor: pointer"
              v-b-modal.new-admin-user
            />
            <feather-icon
              icon="XIcon"
              size="16"
              class="text-body align-middle mr-25"
              @click="setSelectedAdminUser(data.item.id)"
              style="margin-left: 10px; cursor: pointer"
              v-b-modal.confirm-action-modal
            />
          </div>
          <div v-else>--</div>
        </template>
      </b-table>
    </b-card>
    <new-admin-user-modal
      :isEdit="isEdit"
      @close="isEdit = false"
      @refresh="getUsers"
      :adminUserData="adminUserData"
    ></new-admin-user-modal>
    <confirm-action-modal
      @action="deleteAdminUser($event)"
      action="delete"
      message="this user"
    ></confirm-action-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import UsersFilter from "@/@core/components/filters/UsersFilter.vue";
import NewAdminUserModal from "./modals/NewAdminUserModal.vue";
import ConfirmActionModal from "../../@core/components/fundraisers/modals/ConfirmActionModal.vue";
import { destroyAdminUser } from "@/service/api.service";

const [res, apiRequest] = useApi("adminusers");

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    UsersFilter,
    NewAdminUserModal,
    ConfirmActionModal,
  },
  data() {
    return {
      response: res,
      currentPage: 1,
      offset: 0,
      limit: 50,
      perPage: 10,
      filters: {},
      isEdit: false,
      adminUserData: null,
      imageURL: "",
      selectedAdminUserId: null,
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      fields: [
        // 'row_selector',
        "id",
        { key: "name", label: "Name" },
        { key: "role", label: "role" },
        { key: "date", label: "date" },
        { key: "status", label: "status" },
        "actions",
      ],
      status: [
        {
          ABSENT: "light-primary",
          ACTIVE: "light-success",
          INACTIVE: "light-danger",
          VIP: "light-warning",
          6: "light-info",
        },
      ],
    };
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  watch: {
    "response.isFailure": function(isFailure) {
      if (!isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: this.response.error || "Failed to fetch resource",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    dayjs,
    getUsers(offset = 0) {
      apiRequest({ ...this.filters });
    },
    openEdit(data) {
    },
    setSelectedAdminUser(id) {
      this.selectedAdminUserId = id;
    },
    setEditMode(value, data = null) {
      this.isEdit = value;
      if (value && data) {
        this.adminUserData = data;
      } else {
          this.adminUserData = null
      }
    },
    viewUserDetails(data) {
      this.$router.push(`/users/${data.item.userid}/view`);
    },
    viewImage(data) {
      this.imageURL = data.item.idFile;
    },
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    setPerPage(e) {
      this.limit = e;
      this.getUsers(this.offset);
    },
    handlePaginationChange(event) {
      this.offset = (event - 1) * this.limit;
      this.getUsers(this.offset);
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    deleteAdminUser(action) {
      if (action === "delete") {
        destroyAdminUser(this.selectedAdminUserId)
          .then((res) => res.json())
          .then((response) => {
            if (response.valid) {
              this.showToast("Success", "success", response.message);
              this.getUsers();
            }
          })
          .catch((e) => this.showToast("Error", "danger", e));
      }
    },
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}
</style>
