export default [
  {
    header: 'Approvals',
  },
  {
    title: 'Sign Ups',
    route: 'sign-ups',
    icon: 'TypeIcon',
    // tag: '2',
    // tagVariant: 'primary',
  },
  {
    title: 'Fundraisers',
    route: 'fundraisers',
    icon: 'DropletIcon',
    // tag: '2',
    // tagVariant: 'primary',
  },
  {
    title: 'Edited Fundraisers',
    route: 'edited-fundraisers',
    icon: 'DropletIcon',
    // tag: '2',
    // tagVariant: 'primary',
  },
  {
    title: 'Withdrawals',
    route: 'withdrawals',
    icon: 'EyeIcon',
    // tag: '2',
    // tagVariant: 'primary',
  },
  {
    title: 'PnE Withdrawals',
    route: 'promote-and-earn-withdrawals',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Media Package Requests',
    route: 'advert-promo',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Bank Account',
    route: 'bank-account',
    icon: 'EyeIcon',
    // tag: '2',
    // tagVariant: 'primary',
  },
  {
    title: 'Flagged',
    route: 'flagged',
    icon: 'EyeIcon',
    // tag: '2',
    // tagVariant: 'primary',
  },
]
