<template>
  <b-card
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          Today’s Traffic
        </b-card-title>
        <div>
          <div class="d-flex align-items-center mb-1">
            <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
            <span class="font-weight-bold">Web</span>
          </div>
          <div class="d-flex align-items-center mb-1">
            <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
            <span class="font-weight-bold">iOS</span>
          </div>
          <div class="d-flex align-items-center">
            <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
            <span class="font-weight-bold">Android</span>
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <div
          v-if="response.isLoading"
          class="d-flex align-items-center justify-content-center h-100"
        >
          <feather-icon
            icon="LoaderIcon"
            class="spinner"
            size="60"
          />
        </div>
        <template v-else>
          <!-- chart -->
          <vue-apex-charts
            height="120"
            :options="earningsChart.chartOptions"
            :series="series"
          />
          <!-- :series="series" -->
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useApi } from '@core/comp-functions/useApi'

const [res, apiRequest] = useApi('traffic')

const $web = '#7367F0'
const $ios = '#FF9F43'
const $android = '#55D38D'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    VueApexCharts,
  },
  data() {
    return {
      response: res,
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['Web', 'iOS', 'Android'],
          stroke: { width: 0 },
          colors: [$web, $ios, $android],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              // startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
  },
  created() {
    apiRequest()
  },
  computed: {
    series() {
      const { data } = this.response

      return data ? [data.web, data.ios, data.android] : []
    },
  },
  watch: {
    'response.isFailure': function (isFailure) {
      if (isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: this.response.error || 'Failed to fetch resource',
            icon: 'TrashIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
