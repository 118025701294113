import { render, staticRenderFns } from "./ResponseModal.vue?vue&type=template&id=fbc4f686&scoped=true&"
import script from "./ResponseModal.vue?vue&type=script&lang=js&"
export * from "./ResponseModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc4f686",
  null
  
)

export default component.exports