export default [
  {
    header: 'Insights',
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'CopyIcon',
  },
  {
    title: 'Analytics',
    route: 'analytics',
    icon: 'PackageIcon',
  },
  {
    title: 'KPIs',
    route: 'kpis',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'CRM',
    route: 'crm',
    icon: 'CopyIcon',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
