<template>
  <div>
    <b-card class="pb-2">
      <div
        class="pb-1"
        style="border-bottom: 1px solid #EBE9F1; font-size: 18px; font-weight: 500; color: #5E5873"
      >
        Create New Permanent Fundraiser
      </div>
      <div
        style="max-width: 550px; margin: auto;"
        class="mt-3"
      >
        <autocomplete
          :type="'organisation'"
          @itemSelected="selectHandler"
        />
      </div>
      <div
        v-if="userId"
        style="max-width: 600px; margin: auto"
      >
        <form
          id="onetimeform"
          @submit="onSubmit"
        >
          <div style="padding: 25px;">
            <b-row>
              <b-col cols="12">
                <div>
                  <b-form-group
                    label="Title"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="title"
                      placeholder="Title"
                      required
                      disabled
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <div>
                  <label for="message">About us</label>
                  <!-- <b-form-textarea
                      id="description"
                      placeholder="Enter description here"
                      rows="4"
                      v-model="fundraiserDesc"
                      required
                    /> -->
                  <ckeditor
                    v-model="fundraiserDesc"
                    :config="editorConfig"
                  />
                </div>
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Category"
                    label-for="categoryId"
                  >
                    <b-form-select
                      id="categoryId"
                      v-model="categoryId"
                      :options="fundcatoptions"
                      required
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Fundraiser Cycle"
                    label-for="fundraiserCycle"
                  >
                    <b-form-select
                      id="fundraiserCycle"
                      v-model="fundraiserCycle"
                      :options="cycles"
                      required
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Cycle Target Amount"
                    label-for="targetAmount"
                  >
                    <b-form-input
                      id="targetAmount"
                      v-model="targetAmount"
                      placeholder="Target Amount"
                      type="number"
                      required
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="isReservedAccount"
                >
                  <b-form-checkbox
                    id="isReservedAccount"
                    v-model="isReservedAccount"
                    class=""
                  >
                    Set custom account name
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                v-if="isReservedAccount"
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Custom Account Name"
                  label-for="reservedName"
                >
                  <b-form-input
                    id="reservedName"
                    v-model="reservedName"
                    placeholder=""
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="hideCycleTargetAmount"
                >
                  <b-form-checkbox
                    id="hideCycleTargetAmount"
                    v-model="hideCycleTargetAmount"
                    class=""
                  >
                    Hide cycle target amount from public
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <div class="mb-2">
                  <label for="message">Add Cover Photo</label>
                  <div style="text-align: center">
                    <form id="fileInputForm">
                      <input
                        id="fileCoverInput"
                        type="file"
                        style="display: none"
                        @change="onCoverFileSelected"
                      >
                    </form>
                    <label
                      style="border: 2px dashed #7666F8; width: 100%; height: 129px"
                      for="fileCoverInput"
                    >
                      <div
                        class="mt-4"
                        style="color: #7666F8;"
                      >
                        Click here to upload image
                      </div>
                    </label>
                  </div>
                  <b-row v-if="coverImage">
                    <b-col

                      cols="6"
                      class="mt-1"
                    >
                      <div
                        class=""
                        style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                      >
                        <div style="text-align: center; width: 100%">
                          <img
                            :src="coverImage"
                            style="width: 100%"
                          >
                        </div>
                        <div
                          style="text-align: right"
                          class="p-1 font-small"
                        >
                          <b-button
                            variant="outline-primary"
                            class="ml-1"
                            style="padding: 8px"
                            @click="removeCoverImage"
                          >Remove</b-button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="">
                  <label for="message">Add Images</label>
                  <div style="text-align: center">
                    <form id="fileInputForm">
                      <input
                        id="fileInput"
                        type="file"
                        style="display: none"
                        @change="onFileSelected"
                      >
                    </form>
                    <label
                      style="border: 2px dashed #7666F8; width: 100%; height: 129px"
                      for="fileInput"
                    >
                      <div
                        class="mt-4"
                        style="color: #7666F8;"
                      >
                        Click here to upload image
                      </div>
                    </label>
                  </div>
                  <b-row>
                    <b-col
                      v-for="(image, index) in selectedImages"
                      :key="index"
                      cols="6"
                      class="mt-1"
                    >
                      <div
                        class=""
                        style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                      >
                        <div style="text-align: center; width: 100%">
                          <img
                            :src="image"
                            style="width: 100%"
                          >
                        </div>
                        <div
                          style="text-align: right"
                          class="p-1 font-small"
                        >
                          <b-button
                            variant="outline-primary"
                            class="ml-1"
                            style="padding: 8px"
                            @click="removeImage(index)"
                          >Remove</b-button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>

              <b-col
                cols="12"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Organisation website"
                    label-for="youTubeLink"
                  >
                    <b-form-input
                      id="youTubeLink"
                      v-model="organisationWebsite"
                      required
                      placeholder="https://"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Organisation Social Media (Optional)"
                  label-for="twitter"
                >
                  <b-form-input
                    id="twitter"
                    v-model="twitter"
                    placeholder="Twitter"
                  />
                  <b-form-input
                    id="facebook"
                    v-model="facebook"
                    placeholder="Facebook"
                    class="mt-1"
                  />
                  <b-form-input
                    id="instagram"
                    v-model="instagram"
                    placeholder="Instagram"
                    class="mt-1"
                  />
                  <b-form-input
                    id="youtube"
                    v-model="youtube"
                    placeholder="YouTube"
                    class="mt-1"
                  />

                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Add Contact Information"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="contactAddress"
                    placeholder="Address"
                  />
                  <b-form-input
                    id="email"
                    v-model="contactEmail"
                    placeholder="Email"
                    class="mt-1"
                  />
                  <b-form-input
                    id="phone"
                    v-model="contactPhoneNumber"
                    placeholder="Phone Number"
                    class="mt-1"
                  />

                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Impact Story (Optional)"
                >
                  <b-form-input
                    id="title"
                    v-model="impactStoryTitle"
                    placeholder="Title"
                  />
                  <b-form-file
                    v-model="impactStoryFile"
                    placeholder="Add an image for the impact story"
                    type="file"
                    class="mt-1"
                    @change="onStoryFileSelected"
                  />

                  <b-row v-if="impactStoryImage">
                    <b-col

                      cols="6"
                      class="mt-1"
                    >
                      <div
                        class=""
                        style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                      >
                        <div style="text-align: center; width: 100%">
                          <img
                            :src="impactStoryImage"
                            style="width: 100%"
                          >
                        </div>
                        <div
                          style="text-align: right"
                          class="p-1 font-small"
                        >
                          <b-button
                            variant="outline-primary"
                            class="ml-1"
                            style="padding: 8px"
                            @click="removeStoryImage"
                          >Remove</b-button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>

                  <b-form-textarea
                    id="phone"
                    v-model="impactStoryDescription"
                    placeholder="Description"
                    class="mt-1"
                  />

                  <b-button
                    variant="info"
                    class="block float-right mt-1"
                    :disabled="!impactStoryTitle || !impactStoryDescription || !impactStoryImage"
                    @click="addImpactStory"
                  >
                    Add Impact Story
                  </b-button>

                </b-form-group>
                <b-row
                  v-for="(story, index) in impactStories"
                  :key="index"
                  class="p-1"
                  style="border: 1px dashed #ddd; border-radius: 8px;"
                >
                  <b-col cols="3">
                    <img
                      :src="story.image"
                      alt=""
                      style="width: 100%"
                    >
                  </b-col>
                  <b-col cols="9">
                    <feather-icon
                      icon="TrashIcon"
                      color="primary"
                      class="float-right"
                      @click="removeImpactStory(index)"
                    />
                    <h4>{{ story.title }}</h4>
                    <p>{{ story.description }}</p>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Team Members (Optional)"
                >
                  <b-form-input
                    id="name"
                    v-model="teamMemberName"
                    placeholder="Name"
                  />

                  <b-form-input
                    id="role"
                    v-model="teamMemberRole"
                    placeholder="Role"
                    class="mt-1"
                  />
                  <b-form-file
                    v-model="teamMemberFile"
                    placeholder="Add an image of the team member"
                    type="file"
                    class="mt-1"
                    @change="onTeamMemberFileSelected"
                  />

                  <b-row v-if="teamMemberImage">
                    <b-col

                      cols="6"
                      class="mt-1"
                    >
                      <div
                        class=""
                        style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                      >
                        <div style="text-align: center; width: 100%">
                          <img
                            :src="teamMemberImage"
                            style="width: 100%"
                          >
                        </div>
                        <div
                          style="text-align: right"
                          class="p-1 font-small"
                        >
                          <b-button
                            variant="outline-primary"
                            class="ml-1"
                            style="padding: 8px"
                            @click="removeTeamMemberImage"
                          >Remove</b-button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>

                  <b-form-textarea
                    id="phone"
                    v-model="teamMemberDescription"
                    placeholder="Description"
                    class="mt-1"
                  />

                  <b-button
                    variant="info"
                    class="block float-right mt-1"
                    :disabled="!teamMemberName || !teamMemberRole || !teamMemberDescription || !teamMemberImage"
                    @click="addTeamMember"
                  >
                    Add Team Member
                  </b-button>

                </b-form-group>
                <b-row
                  v-for="(member, index) in teamMembers"
                  :key="index"
                  class="p-1"
                  style="border: 1px dashed #ddd; border-radius: 8px;"
                >
                  <b-col cols="3">
                    <img
                      :src="member.image"
                      alt=""
                      style="width: 100%"
                    >
                  </b-col>
                  <b-col cols="9">
                    <feather-icon
                      icon="TrashIcon"
                      color="primary"
                      class="float-right"
                      @click="removeTeamMember(index)"
                    />
                    <h4>{{ member.name }}</h4>
                    <h6>{{ member.role }}</h6>
                    <p>{{ member.description }}</p>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                cols="12"
                class="mt-1"
              >
                <div
                  style="background: #E0F2FF; border-radius: 4px"
                  class="p-2"
                >
                  <div style="font-size: 14px">
                    Monthly Donation
                  </div>
                  <div
                    class="mt-1 text-muted"
                    style="font-size: 12px"
                  >
                    Solicit monthly donations from your donors by specifying
                    subscription amounts. E.g. N1,000/Month
                  </div>
                  <b-form-group
                    label="Silver Donor"
                    label-for="silver"
                    class="mt-1"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-input
                          id="silver"
                          v-model="silver"
                          placeholder="0"
                          type="number"
                        />
                      </b-col>
                      <b-col cols="3">
                        <div
                          class="mt-1"
                          style="font-weight: 500; font-size: 12px"
                        >
                          Per Month
                        </div>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Gold Donor"
                    label-for="gold"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-input
                          id="gold"
                          v-model="gold"
                          placeholder="0"
                          type="number"
                        />
                      </b-col>
                      <b-col cols="3">
                        <div
                          class="mt-1"
                          style="font-weight: 500; font-size: 12px"
                        >
                          Per Month
                        </div>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Diamond Donor"
                    label-for="diamond"
                  >
                    <b-row>
                      <b-col cols="9">
                        <b-form-input
                          id="diamond"
                          v-model="diamond"
                          placeholder="0"
                          type="number"
                        />
                      </b-col>
                      <b-col cols="3">
                        <div
                          class="mt-1"
                          style="font-weight: 500; font-size: 12px"
                        >
                          Per Month
                        </div>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="makePrivate"
                >
                  <b-form-checkbox
                    id="makePrivate"
                    v-model="makePrivate"
                    class=""
                  >
                    Make private
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="allowMakeAWish"
                >
                  <b-form-checkbox
                    id="allowMakeAWish"
                    v-model="allowMakeAWish"
                    class=""
                  >
                    Allow Make-A-Wish
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <!-- <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="turnOnComments"
                >
                  <b-form-checkbox
                    id="turnOnComments"
                    v-model="turnOnComments"
                    class=""
                  >
                    Turn on comments
                  </b-form-checkbox>
                </b-form-group>
              </b-col> -->
            </b-row>

            <div class="mb-0 text-center mt-2">
              <!-- basic -->
              <b-button
                variant="primary"
                type="submit"
              >Create Fundraiser</b-button>
            </div>
          </div>
        </form>
      </div>
    </b-card>
    <image-cropper
      :open="openCropper"
      :image="image"
      @add="addImage($event)"
      @close="close"
    />
    <image-cropper
      :open="openCoverCropper"
      :image="image"
      :aspect-ratio="3/1"
      @add="addCoverImage($event)"
      @close="close"
    />
    <image-cropper
      :open="openStoryCropper"
      :image="image"
      :aspect-ratio="1"
      @add="addStoryImage($event)"
      @close="close"
    />

    <image-cropper
      :open="openTeamCropper"
      :image="image"
      :aspect-ratio="1"
      @add="addTeamMemberImage($event)"
      @close="close"
    />
    <response-modal
      :open="openResponseModal"
      @close="closeCreate"
    />
  </div>
</template>

<script>
/* eslint-disable */
  import {
    BCard,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
    BImg,
    BFormInput,
    BModal,
    BButton,
    BFormCheckbox,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BFormFile,
    BPagination,
  } from "bootstrap-vue";
  import ImageCropper from "@/@core/components/fundraisers/galley/ImageCropper.vue";
  import ResponseModal from "@/@core/components/fundraisers/modals/ResponseModal.vue";
  import Autocomplete from "@/@core/components/common/Autocomplete.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { createFundraiser, uploadImages, postAnImage } from "@/service/api.service";
  export default {
    components: {
      BCard,
      BCardText,
      BAvatar,
      BRow,
      BCol,
      BFormGroup,
      BImg,
      BFormInput,
      BModal,
      BButton,
      BFormSelect,
      BBadge,
      BFormTextarea,
      BFormFile,
      BFormCheckbox,
      BPagination,
      ImageCropper,
      Autocomplete,
      ResponseModal,
    },
    data() {
      return {
        userId: null,
        categoryId: null,
        duration: null,
        fundraiserCycle: null,
        silver: 1000,
        gold: 5000,
        diamond: 10000,
        title: null,
        targetAmount: null,
        fundraiserDesc: null,
        fullname: null,
        youTubeLink: null,
        facebook: null,
        twitter: null,
        instagram: null,
        tiktok: null,
        youtube: null,
        hideCycleTargetAmount: false,
        link: null,
        makePrivate: false,
        allowMakeAWish: false,
        isOrganiserBeneficiary: false,
        openCropper: false,
        openCoverCropper: false,
        turnOnComments: true,
        openResponseModal: false,
        imageObject: null,
        image: null,
        selectedImages: [],
        filesToUpload: [],
        coverImage: null,
        observers: [],
        selectedMembers: [],
        isReservedAccount: false,
        reservedName: '',
        openStoryCropper: false,
        openTeamCropper: false,
        impactStories: [],
        impactStoryDescription: '',
        impactStoryTitle: '',
        impactStoryImage: null,
        impactStoryFile: null,
        teamMembers: [],
        teamMemberName: '',
        teamMemberRole: '',
        teamMemberImage: null,
        teamMemberFile: null,
        teamMemberDescription: '',
        contactAddress: '', contactEmail: '', contactPhoneNumber: '',
        organisationWebsite: '',
        fundcatoptions: [
          { value: null, text: "Select category" },
          { value: "3", text: "Wedding" },
          { value: "4", text: "Charity" },
          { value: "6", text: "Education" },
          { value: "7", text: "Health" },
          { value: "1", text: "Music" },
          { value: "2", text: "Religion" },
          { value: "11", text: "Funeral" },
          { value: "8", text: "Startups" },
          { value: "10", text: "Models" },
          { value: "5", text: "Community Projects" },
        ],
        cycles: [
          { text: null, text: "Select Fundraiser Cycle" },
          { text: "Monthly", value: "MONTHLY" },
          { text: "Quarterly", value: "QUARTERLY" },
        ],
        editorData: "<p>Type terms and conditions here...</p>",
        editorConfig: {
          // The configuration of the editor.
        },
      };
    },
    methods: {
      async onFileSelected(e) {
        console.log("E ", e.target.files[0]);
        const fileObject = e.target.files[0];
        this.imageObject = {
          name: fileObject.name,
          mimetype: fileObject.type,
        };
        this.image = await this.getImageData(e);
        this.openCropper = true;
      },
      async onCoverFileSelected(e) {
        const fileObject = e.target.files[0];
        this.imageObject = {
          name: fileObject.name,
          mimetype: fileObject.type,
        };
        this.image = await this.getImageData(e);
        this.openCoverCropper = true;
      },
      async onStoryFileSelected(e) {
        const fileObject = e.target.files[0];
        this.imageObject = {
          name: fileObject.name,
          mimetype: fileObject.type,
        };
        this.image = await this.getImageData(e);
        this.openStoryCropper = true;
      },
      async onTeamMemberFileSelected(e) {
        const fileObject = e.target.files[0];
        this.imageObject = {
          name: fileObject.name,
          mimetype: fileObject.type,
        };
        this.image = await this.getImageData(e);
        this.openTeamCropper = true;
      },
      getImageData(e) {
        const input = e.target;
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = function() {
            const dataUrl = reader.result;
            resolve(dataUrl);
          };
          reader.readAsDataURL(input.files[0]);
          this.resetFileInput();
        });
      },
      resetFileInput() {
        const fileInput = document.getElementById("fileInputForm");
        fileInput.reset();
      },
      addImage(image) {
        this.selectedImages.push(image);
        this.filesToUpload.push({ ...this.imageObject, base64: image });
        this.openCropper = false;
      },
      async addCoverImage  (image){
        const data = await postAnImage({file: { name, mimetype: 'image/jpeg', base64: image }})
        const response = await data.json()
        this.coverImage = response.data.imageurl
        this.openCoverCropper = false
      },
     async  addStoryImage(image){
        const data = await postAnImage({file: { name, mimetype: 'image/jpeg', base64: image }})
        const response = await data.json()
        this.impactStoryImage = response.data.imageurl
        this.openStoryCropper = false
      },
     async addTeamMemberImage(image){
        const data = await postAnImage({file: { name, mimetype: 'image/jpeg', base64: image }})
        const response = await data.json()
        this.teamMemberImage = response.data.imageurl
        this.openTeamCropper = false
      },
      close() {
        this.openCropper = false;
      },
      removeImage(index) {
        this.selectedImages = this.selectedImages.filter(
          (_, idx) => idx !== index
        );
        this.filesToUpload = this.filesToUpload.filter((_, idx) => idx !== index);
      },
      removeCoverImage() {
        this.coverImage = null
      },
      removeStoryImage() {
        this.impactStoryImage = null
      },
      removeTeamMemberImage() {
        this.teamMemberImage = null
      },
      selectHandler(data) {
        this.userId = data.userid;
        this.title = data.firstname
      },
      selectMemberHandler(data) {
        this.selectedMembers.push(data);
      },
    removeImpactStory  (index) {
      if (index > -1) {
        // only splice array when item is found
        this.impactStories.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    removeTeamMember  (index) {
      if (index > -1) {
        // only splice array when item is found
        this.teamMembers.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
      removeObserver(index) {
        this.selectedMembers = this.selectedMembers.filter(
          (_, idx) => idx !== index
        );
      },
      getAvatarText(item) {
        const avatarText = `${item.firstname.charAt(0)} ${item.lastname.charAt(
          0
        )}`;
        return avatarText.toUpperCase();
      },
      showToast(title, variant, text) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            text: text,
            icon: title === "Error" ? "TrashIcon" : "DoneIcon",
            variant: variant,
          },
        });
      },
      addImpactStory() {
        const data = {
            title: this.impactStoryTitle,
            image: this.impactStoryImage,
            description: this.impactStoryDescription
        }
        this.impactStories.push(data)

        this.impactStoryTitle = ''
        this.impactStoryImage = null
        this.impactStoryDescription = ''
      },
      addTeamMember() {
        const data = {
            name: this.teamMemberName,
            image: this.teamMemberImage,
            description: this.teamMemberDescription,
            role: this.teamMemberRole
        }
        this.teamMembers.push(data)

        this.teamMemberDescription = ''
        this.teamMemberImage = null
        this.teamMemberName = ''
        this.teamMemberRole = ''
      },
      getFundraiserPayload() {
        this.observers = [];
        this.selectedMembers.forEach((member) => {
          this.observers.push({
            memberId: member.userid,
            fullname: "",
            link: "",
            memberType: "OBSERVER",
          });
        });
        let beneficiary = {};
        if (this.fullname && !this.isOrganiserBeneficiary) {
          beneficiary = {
            fullname: this.fullname,
            link: this.link,
            memberId: "",
            memberType: "BENEFICIARY",
          };
        } else if (this.isOrganiserBeneficiary) {
          beneficiary = {
            fullname: "",
            link: "",
            memberId: this.userId,
            memberType: "BENEFICIARY",
          };
        } 
        this.observers = [...this.observers, beneficiary];
        const payload = {
          userId: this.userId,
          title: this.title,
          coverImage: this.coverImage,
          contactAddress: this.contactAddress,
          contactEmail: this.contactEmail,
          contactPhoneNumber: this.contactPhoneNumber,
          impactStories: this.impactStories,
          teamMembers: this.teamMembers,
          allowMakeAWish: this.allowMakeAWish,
          fundraiserDesc: this.fundraiserDesc,
          categoryId: this.categoryId,
          // duration: +this.duration,
          hideCycleTargetAmount: this.hideCycleTargetAmount,
          fundraiserCycle: this.fundraiserCycle,
          targetAmount: +this.targetAmount,
          makePrivate: this.makePrivate,
          isOrganiserBeneficiary: this.isOrganiserBeneficiary,
          turnOnComments: this.turnOnComments,
          youTubeLink: this.youTubeLink,
        //   members: this.observers,
        members: [],
          fundraiserType: "PERMANENT",
          reservedName: this.reservedName,
          organisationWebsite: this.organisationWebsite,
          rewards: [],
          smlinks: [
            { smname: "Twitter", link: this.twitter },
            { smname: "Facebook", link: this.facebook },
            { smname: "Instagram", link: this.instagram },
            { smname: "YouTube", link: this.youtube },
            { smname: "TikTok", link: this.tiktok },
          ],
          monthlyDonations: [
              { donationtype: 'SILVER', amount: this.silver },
              { donationtype: 'GOLD', amount: this.gold },
              { donationtype: 'DIAMOND', amount: this.diamond }
          ]
        };
        console.log("payload", payload, this.filesToUpload);
        if (this.filesToUpload.length >= 1) {
          return { payload, images: this.filesToUpload };
        } else {
          this.showToast("Error", "danger", "Please add at least one image");
          return { payload: null, images: null };
        }
      },
      async onSubmit(e) {
        e.preventDefault();
        const { payload, images } = this.getFundraiserPayload();
        if (payload && images) {
          try {
            const fundraiserResponse = await createFundraiser(payload);
            const response = await fundraiserResponse.json();
            if (response.valid) {
              const fundraiserId = response.data.id;
              const imageUploadResponse = await uploadImages({
                fundraiserId,
                deleteItems: [],
                files: images,
              });
              const resp = await imageUploadResponse.json();
              if (resp.valid) {
                this.resetForm();
                this.openResponseModal = true;
              }
            }
          } catch (e) {
            this.showToast("Error", "danger", `An error occurred: ${e}`);
          }
        }
      },
      resetForm() {
        this.title = null;
        this.fundraiserDesc = null;
        this.categoryId = null;
        this.targetAmount = null;
        this.fundraiserCycle = null;
        this.youTubeLink = null;
        this.userId = null;
        this.fullname = null;
        this.link = null;
        this.silver = 1000;
        this.gold = 5000;
        this.diamond = 10000;
        this.youtube = null;
        this.twitter = null;
        this.facebook = null;
        this.instagram = null;
        this.tiktok = null;
        this.image = null;
        this.imageObject = null;
        this.selectedMembers = [];
        this.selectedImages = [];
        this.filesToUpload = [];
        this.observers = [];
        this.makePrivate = false;
        this.hideCycleTargetAmount = false;
        this.isOrganiserBeneficiary = false;
        this.turnOnComments = true;
      },
      close() {
        this.openResponseModal = false;
      },
      closeCreate(){
        this.$router.push({name:'fundraiser'})
      }
    },
  };
  </script>
  
  <style scoped></style>
  