<template>
  <div>
    <b-card>
      <div v-if="fundraiserData" style="font-size: 18px; color: #5E5873;">
        {{ fundraiserData.title }}
      </div>
      <hr>
      <b-row align-h="center">
        <b-col md="8">
          <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
          >
            <b-form-group
              horizontal
              label="Activate Appreciation Email"
            >
              <b-form-checkbox
                v-model="sendApprEmail"

                required
                class="mt-2"
              >
                Send Appreciation Email?
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Email Subject">
              <b-form-input v-model="apprEmailSubject" />
            </b-form-group>

            <ckeditor
              v-model="appreciationEmail"
              placeholder="Enter"
              :config="editorConfig"
            />

            <b-button
              type="submit"
              class="mt-1"
              variant="primary"
            >
              <b-spinner v-if="loading" />
              <span v-else>  Activate Email</span>

            </b-button>
          </b-form>

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BForm, BCard, BFormGroup, BFormCheckbox, BRow, BCol, BButton, BSpinner, BFormInput,
} from 'bootstrap-vue'
import { getFundDetails, updateAppreciationEmail } from '@/service/api.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm, BCard, BFormGroup, BFormCheckbox, BRow, BCol, BButton, BSpinner, BFormInput,
  },
  data() {
    return {
      fundraiserId: null,
      fundraiserData: null,
      fundraiserObj: null,

      loading: false,
      action: null,
      sendApprEmail: null,
      appreciationEmail: null,
      apprEmailSubject: null,
      editorConfig: {
        // The configuration of the editor.
      },
    }
  },

  async created() {
    this.fundraiserId = this.$route.params.fundraiserId
    await this.getFundraiserDetails()
  },
  methods: {
    handleSubmit() {
      this.loading = true
      const payload = {
        sendApprEmail: this.sendApprEmail,
        appreciationEmail: this.appreciationEmail,
        fundraiserId: this.fundraiserId,
        apprEmailSubject: this.apprEmailSubject,
      }
      updateAppreciationEmail(payload).then(res => res.json()).then(response => {
        this.loading = false
        if (response.valid) {
          this.showToast('Success', 'success', response.message)
        } else {
          this.showToast('Error', 'danger', response.message)
        }
      })
    },
    getFundraiserDetails() {
      this.loading = true
      getFundDetails(this.fundraiserId)
        .then(res => res.json())
        .then(response => {
          this.loading = false
          if (response.valid) {
            this.fundraiserData = response.data
            this.sendApprEmail = this.fundraiserData.sendApprEmail
            this.appreciationEmail = this.fundraiserData.appreciationEmail
            this.apprEmailSubject = this.fundraiserData.apprEmailSubject
          } else {
            this.showToast('Error', 'danger', response.message)
          }
        })
        .catch(e => {
          this.loading = false
          this.showToast('Error', 'danger', e)
        })
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: title === 'Error' ? 'TrashIcon' : 'DoneIcon',
          variant,
        },
      })
    },
  },
}
</script>

<style>
</style>
