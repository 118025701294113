<template>
  <b-modal id="sms-user" ref="sms-user-modal" centered hide-footer>
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Edit User Information
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Please use the form below to send the user an SMS
      </div>
      <div>
        <label for="textarea-default">Message</label>
        <b-form-textarea
          id="textarea-default"
          placeholder="Type message here"
          rows="4"
          :value="message"
          @change="onChange"
        />
      </div>

      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button variant="primary" @click="sendSMSMessage">Send SMS</b-button>
        <b-button variant="outline-secondary" class="ml-1" @click="closeModal">Discard</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { sendSMS } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  data() {
    return {
      categories: [{ text: "Standard", value: "STANDARD" }],
      message: "",
    };
  },

  props: {
    userId: {
      type: String,
    },
  },

  methods: {
    onChange(value) {
      this.message = value;
    },

    sendSMSMessage() {
      sendSMS({ userid: this.userId, message: this.message })
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.message = "";
            this.closeModal()
            this.showToast("Success", "success", response.message);
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    closeModal() {
      this.$nextTick(() => {
        this.$refs["sms-user-modal"].toggle("#toggle-btn");
      });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped></style>
