<template>
  <div class="msg-card">
    <b-card>
      <b-row>
        <b-col
          class=""
          cols="4"
          style="border-right: 1px solid #EBE9F1; background: #fff; "
          :style="'min-height:' + '700px'"
        >
          <div
            class="flex pb-1 pr-1 pt-1"
            style="border-bottom: 1px solid #EBE9F1"
          >
            <div>
              <b-avatar text="BA" variant="light-danger" />
            </div>
            <div class="ml-2 w-full">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchValue"
                  placeholder="Search messages"
                  @keyup="onChange($event)"
                />
              </b-input-group>
            </div>
          </div>
          <div
            class="pb-1 pr-1 mt-1"
            style="color: #7367F0; font-weight: 500; font-size: 18px"
          >
            Messages
          </div>
          <div style="overflow: auto; height: 700px">
            <div v-for="(user, index) in data" :key="index">
              <div v-for="(thread, inx) in user.data" :key="inx">
                <div
                  class="flex pb-1 pr-1 cursor-pointer"
                  v-for="(sub, idx) in thread.data"
                  :key="idx"
                  @click="
                    getMessageAndFetchThread(
                      sub.data.userId,
                      thread.threadId,
                      sub.subjectId,
                      sub.data.createdAt
                    )
                  "
                >
                  <div style="margin-top: 4px">
                    <b-avatar
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.top="getUsername(sub.data.userId)"
                      :text="getAvatarText(sub.data.userId)"
                      variant="light-danger"
                    />
                  </div>
                  <div class="ml-2 w-full">
                    <div style="color: #5E5873; font-size: 15px">
                      {{ getSubject(sub.subjectId) }}
                    </div>
                    <div
                      class="text-muted"
                      style="font-size: 14px; margin-top: 2px"
                    >
                      {{ getTruncatedMessage(sub.data.message) }}
                    </div>
                  </div>
                  <div class="text-muted" style="font-size: 12px">
                    <div>{{ dayjs(sub.data.createdAt).format("HH:mm") }}</div>
                    <div
                      class=""
                      style="margin-top: 14px"
                      v-if="!sub.data.opened && sub.data.sender === 2"
                    >
                      <div
                        style="margin: auto; padding: 4px; width: 4px; height: 4px; background: red; border-radius: 50%"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="8">
          <div
            v-if="currentThreadName"
            class="p-1"
            style="background: #fff; width: 102%; margin-left: -1rem"
          >
            <div class="flex">
              <div>
                <b-avatar
                  :text="getAvatarName(currentThreadName)"
                  variant="light-danger"
                />
              </div>
              <div class="ml-2 w-full">
                <div
                  class=""
                  style="font-size: 15px; font-weight: 500; margin-top: 8px"
                >
                  {{ currentThreadName }}
                </div>
              </div>
            </div>
          </div>

          <div class="pb-3" style="overflow: auto; height: 700px">
            <div v-for="(thrd, idx) in thread" :key="idx">
              <div class="purple p-1 mt-2" v-if="thrd.sender === 1">
                {{ thrd.message }}
              </div>
              <div class="white p-1 mt-2" v-else>
                {{ thrd.message }}
              </div>
            </div>
          </div>

          <form @submit="onSubmit" v-if="currentThreadName">
            <div
              class="p-1 flex"
              style="background: #fff; width: 99%; margin-left: -1rem; bottom: 0px; position:absolute"
            >
              <b-form-input
                id="message"
                placeholder="Type your message here"
                rows="1"
                v-model="message"
              />
              <b-button
                variant="primary"
                type="submit"
                class="ml-1"
                :disabled="message !== null && message !== '' ? false : true"
                >Send</b-button
              >
            </div>
          </form>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  getAllMessages,
  getThread,
  replyMessage,
  updateAsRead,
} from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dayjs from "dayjs";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  computed: {
    getInnerHeight() {
      return window.innerHeight;
    },
  },
  data() {
    return {
      searchValue: null,
      responseData: null,
      messages: [],
      message: null,
      data: [],
      searchData: [],
      thread: null,
      currentThreadName: null,
      subjects: [
        { text: "Select subject", value: null },
        { text: "Welcome", value: "1" },
        { text: "Withdrawal Issue", value: "2" },
        { text: "Donation Issue", value: "3" },
        { text: "Fundraiser Issue", value: "4" },
        { text: "Profile Issue", value: "5" },
        { text: "Technical Issue", value: "6" },
        { text: "Suggestion", value: "7" },
        { text: "Other", value: "8" },
      ],
    };
  },

  async created() {
    await this.fetchAllMessages();
  },
  methods: {
    dayjs,
    fetchAllMessages() {
      getAllMessages()
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.messages = response.data.messages;
            this.users = response.data.users;
            this.data = this.messages;
          }
        })
        .catch((e) => console.error(e));
    },
    getTruncatedMessage(message) {
      return message.length > 25 ? message.substring(0, 25) + "..." : message;
    },
    getAvatarText(userId) {
      const obj = this.users.find((user) => (user !== null ? user.userid === userId : null));
      if (obj) {
        const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(
          0
        )}`;
        return avatarText.toUpperCase();
      }
      return "NA";
    },
    getAvatarName(name) {
      const obj = name.split(' ')
      if (obj && name !== 'N/A') {
        const avatarText = `${obj[0].charAt(0)} ${obj[1].charAt(
          0
        )}`;
        return avatarText.toUpperCase();
      }
      return "NA";
    },
    getUsername(userId) {
      const obj = this.users.find((user) => (user !== null ? user.userid === userId : null));
      if (obj) {
        const { firstname, lastname } = obj;
        const fullname = `${firstname} ${lastname}`;
        return fullname;
      }
      return "N/A";
    },
    getSubject(subjectId) {
      const subject = this.subjects.find((sub) => sub.value === subjectId);
      if (subject) {
        const { text } = subject;
        return text.length > 20 ? text.substring(0, 20) + "..." : text;
      }
      return "N/A";
    },
    onChange(e) {
      const value = e.target.value;
    },
    markAsRead(userId, threadId, subjectId, createdAt) {
      updateAsRead(userId, threadId, subjectId, createdAt)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.fetchAllMessages();
          }
        })
        .catch((e) => console.error(e));
    },
    getMessageAndFetchThread(userId, messageId, subjectId, createdAt) {
      if (createdAt) {
        this.selectedSubjectId = subjectId;
        this.selectedThreadId = messageId;
        this.selectedUserId = userId;
      }
      getThread(userId, messageId)
        .then((res) => res.json())
        .then(async (response) => {
          if (response.valid) {
            if (createdAt) {
              await this.markAsRead(userId, messageId, subjectId, createdAt);
            }
            this.thread = Object.values(response.data)[0];
            const obj = this.users.find((user) => (user !== null ? user.userid === userId : null));
            if (obj){
            const { firstname, lastname } = obj;
            this.currentThreadName = `${firstname} ${lastname}`;
            } else {
              this.currentThreadName = 'N/A'
            }
          }
        })
        .catch((e) => console.error(e));
    },
    onSubmit(e) {
      e.preventDefault();
      const payload = {
        subjectId: +this.selectedSubjectId,
        message: this.message,
        sender: 1,
        recepient: 2,
        messageId: this.selectedThreadId,
        userId: this.selectedUserId,
      };
      this.sendReply(payload);
    },
    sendReply(payload) {
      replyMessage(payload)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.message = "";
            const { userId, messageId, subjectId } = payload;
            this.getMessageAndFetchThread(userId, messageId, subjectId, null);
            this.fetchAllMessages();
            this.showToast("Success", "success", response.message);
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.w-full {
  width: 100% !important;
}

.msg-card .card-body {
  padding: 0px !important;
  margin-left: 20px;
  min-height: 700px;
  background-image: url("../../assets/images/bgimage.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.msg-user:hover {
  background: #f8f8f8;
}

.white {
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  max-width: 410px;
}
.purple {
  background: linear-gradient(46.62deg, #7367f0 0%, #9e95f5 93.64%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  max-width: 410px;
  color: #fff;
  /* float: right; */
  margin-right: 16px;
  margin-left: auto;
}
</style>
