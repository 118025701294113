<template>
  <div>
    <b-card>
      <h3>Exchange Rates</h3>
      <hr>
      <!-- {{ exchangeRates }} -->
      <b-form @submit.prevent="update">
        <b-row
          v-for="(rate, index) in exchangeRates"
          :key="index"
          align-v="center"
        >
          <b-col md="2">
            <p>{{ rate.currencyName }} ({{ rate.currencyCode }})</p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="rate.rate"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="3">
            <b-button
              v-if="exchangeRates.length"
              class="mt-4"
              block
              variant="primary"
              type="submit"
            >
              Save Changes
            </b-button>

          </b-col>
        </b-row>

      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { getExchangeRates, updateExchangeRates } from '@/service/api.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BButton,
  },
  data() {
    return {
      exchangeRates: [],
      loading: false,
    }
  },
  async created() {
    await this.fetchExchangeRates()
  },
  methods: {
    fetchExchangeRates() {
      this.loading = true
      // eslint-disable-next-line no-undef
      getExchangeRates()
        .then(res => res.json())
        .then(response => {
          this.loading = false

          if (response.valid) {
            this.exchangeRates = response.data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    update() {
      this.loading = true
      updateExchangeRates({ exchangeRates: this.exchangeRates })
        .then(res => res.json())
        .then(response => {
          this.loading = false

          if (response.valid) {
            this.showToast(
              'Success',
              'success',
              'Exchange rates updated successfully!',
            )
          }
        }).catch(e => {
          this.loading = false
          this.showToast('Error', 'danger', e)
        })
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: title === 'Error' ? 'TrashIcon' : 'DoneIcon',
          variant,
        },
      })
    },
  },
}
</script>

<style>
</style>
