<template>
  <b-modal id="edit-user" centered hide-footer>
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Edit User Information
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Updating user details
      </div>
      <b-row>
        <b-col md="6" xl="6" class="mb-0">
          <!-- basic -->
          <b-form-group label="First Name" label-for="firstname">
            <b-form-input
              id="firstname"
              placeholder="First Name"
              :value="firstname"
              @change="onChange($event, 'firstname')"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-0">
          <!-- basic -->
          <b-form-group label="Lasr Name" label-for="lastname">
            <b-form-input
              id="lastname"
              placeholder="Last Name"
              :value="lastname"
              @change="onChange($event, 'lastname')"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-0">
          <!-- basic -->
          <b-form-group label="Email" label-for="email">
            <b-form-input
              id="email"
              placeholder="Email"
              :value="email"
              @change="onChange($event, 'email')"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-0">
          <!-- basic -->
          <b-form-group label="Phone Number" label-for="phonenumber">
            <b-form-input
              id="phonenumber"
              placeholder="Phone Number"
              :value="phonenumber"
              @change="onChange($event, 'phonenumber')"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-0">
          <!-- basic -->
          <b-form-group label="User Category" label-for="userCategory">
            <b-form-select
              :options="categories"
              id="userCategory"
              :value="userCategory"
              @change="onChange($event, 'userCategory')"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-0">
          <!-- basic -->
          <b-form-group label="Commission Type" label-for="commission">
            <b-form-select
              :options="commissions"
              id="commission"
              :value="commission"
              @change="onChange($event, 'commission')"
            />
          </b-form-group>
        </b-col>

        <b-col md="12" xl="12" class="mb-0 text-center mt-2">
          <!-- basic -->
          <b-button variant="primary" @click="updateProfileData"
            >Submit</b-button
          >
          <b-button variant="outline-secondary" class="ml-1">Discard</b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import { updateProfile } from "@/service/api.service";
import { COMMISSION_TYPES } from "../../../utils/constants";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
  },

  props: {
    data: {
      type: Object,
    },
  },

  data() {
    return {
      categories: [{ text: "Standard", value: COMMISSION_TYPES.STANDARD }],
      commissions: [{ text: "Basic", value: COMMISSION_TYPES.BASIC }],
      firstname: this.data.firstname,
      lastname: this.data.lastname,
      email: this.data.email,
      phonenumber: this.data.phonenumber,
      userCategory: this.data.userCategory,
      commission: this.data.commission,
    };
  },
  methods: {
    updateProfileData() {
      updateProfile({
        userid: this.data.userid,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        phonenumber: this.phonenumber,
        userCategory: this.userCategory,
        commission: this.commission,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.$emit("refresh");
          }
        });
    },
    onChange(value, id) {
      this[id] = value;
    },
  },
};
</script>

<style scoped></style>
