/* eslint-disable */
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "./default.service";
export const getMessagesByUser = (userId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}messages/${userId}`
  );

export const getAllMessages = () =>
  getRequest(`${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}messages`);

  export const getCategories = () =>
  getRequest(`${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}categories`);

export const sendInternalMessage = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}messages`,
    payload
  );
};

export const updateVerificationStatus = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}users/updateVerification`,
    payload
  );
};

export const updateEmailVerificationStatus = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}users/updateEmailVerification`,
    payload
  );
};

export const updateProfile = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}users/updateProfile`,
    payload
  );
};

export const editBlessor = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}blessings/blessors/update`,
    payload
  );
};

export const doBankApproval = (type, userId, payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}bank/${type}/${userId}`,
    payload
  );
};

export const doWithdrawalApproval = (type, payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}withdrawals/${type}`,
    payload
  );
};

export const doPromoteAndEarnWithdrawalApproval = (type, payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}promote-and-earn/withdrawals/${type}`,
    payload
  );
};

export const doFundraiserApproval = (type, payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraisers/${type}`,
    payload
  );
};

export const doAdvertPromoApproval = (type, payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}advert-promo/${type}`,
    payload
  );
};

export const addMember = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}members/create`,
    payload
  );
};

export const deleteMember = (id) => {
  return deleteRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}members/deleteById/${id}`
  );
};

export const deleteUser = (id) => {
  return deleteRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}users/deleteById/${id}`
  );
};

export const deleteReward = (id) => {
  return deleteRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}rewards/deleteById/${id}`
  );
};

export const sendEmail = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}users/sendEmail`,
    payload
  );
};

export const sendSMS = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}users/sendSMS`,
    payload
  );
};

export const replyMessage = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}messages/reply`,
    payload
  );
};

export const resetUserPassword = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}as/verify-email`,
    payload
  );
};

export const verifyBankAccount = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}bank/account-verification`,
    payload
  );
};

export const verifyBVN = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}verify-bvn`,
    payload
  );
};

export const createNewUser = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}signup`,
    payload
  );
};

export const createNewCorporate = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}corporate-signup`,
    payload
  );
};

export const createBankDetails = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}bank/create`,
    payload
  );
};

export const editAboutUs = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}about-us`,
    payload
  );
};

export const fetchAboutUs = () => {
  return getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}about-us`
  );
};

export const editTerms = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}terms`,
    payload
  );
};

export const fetchTerms = () => {
  return getRequest(`${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}terms`);
};

export const editPrivacyPolicy = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}privacy`,
    payload
  );
};

export const fetchPrivacyPolicy = () => {
  return getRequest(`${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}privacy`);
};

export const fetchFaqs = () => {
  return getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}settings/faqs`
  );
};

export const editFaq = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}settings/faqs`,
    payload
  );
};

export const postFaq = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}settings/faqs`,
    payload
  );
};

export const destroyFaq = (id) => {
  return deleteRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}settings/faqs/${id}`
  );
};

export const getThread = (userId, messageId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}messages/${userId}/thread/${messageId}`
  );

export const getFundDetails = (fundraiserId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraisers/findById/${fundraiserId}`
  );

export const getFundUpdates = (fundraiserId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraiser/updates/${fundraiserId}`
  );

export const fetchBlessors = (fundraiserId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}blessings/blessors/${fundraiserId}`
  );

export const fetchComments = (fundraiserId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}comments/findAllByFundraiser/${fundraiserId}`
  );

export const setFundraiserStatus = (fundraiserId, type) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraisers/${fundraiserId}/${type}`,
    {}
  );
};

export const getFundraiserImages = (fundraiserId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}gallery/findByFundraiserId/${fundraiserId}`
  );

export const destroyImage = (fundraiserId, id) => {
  return deleteRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}gallery/${fundraiserId}/delete/${id}`
  );
};

export const uploadImages = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}gallery/upload`,
    payload
  );
};

export const postAnImage = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}gallery/singleupload`,
    payload
  );
};

export const fetchRewards = (fundraiserId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}rewards/findAllByFundraiser/${fundraiserId}`
  );

export const fetchFundraiserFlags = (fundraiserId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraisers/flags/${fundraiserId}`
  );

export const updateReward = (rewardId, payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}rewards/update/${rewardId}`,
    payload
  );
};

export const searchUsers = (query) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}users/autocomplete?query=${query}`
  );

export const createFundraiser = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraiser/create`,
    payload
  );
};

export const updateAppreciationEmail = (payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraiser/appreciation-email`,
    payload
  );
};

export const editFundraiser = (fundraiserId, payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraiser/update/${fundraiserId}`,
    payload
  );
};

export const updateAsRead = (userId, threadId, subjectId, createdAt) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}messages/markAsRead/${userId}/${threadId}/${subjectId}/${createdAt}`
  );

export const getUserRoles = () =>
  getRequest(`${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}roles`);

  export const getExchangeRates = () =>
  getRequest(`${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}exchange-rates`);

export const getAllPermissions = () =>
  getRequest(`${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}permissions`);

export const saveAdminUser = (payload) => {
  return postRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}adminusers`,
    payload
  );
};

export const destroyAdminUser = (id) => {
  return deleteRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}adminusers/${id}`
  );
};

export const updateAdminUser = (id, payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}adminusers/${id}`,
    payload
  );
};

export const updateAdminRole = (id, payload) => {
  return putRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}roles/${id}`,
    payload
  );
};

export const getBCData = (filter) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}blessingsAndCommissions?filter=${filter}`
  );


  export const createWithdrawal = (payload) => {
    return postRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}withdrawals/create`,
      payload
    );
  };

  export const manualTransfer = (payload) => {
    return postRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}manual-transfer`,
      payload
    );
  };

  export const manualWithdrawal = (payload) => {
    return postRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraisers/manual-withdrawal`,
      payload
    );
  };

  export const updateAutoWithdrawalApproval = (payload) => {
    return postRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraisers/auto-withdrawal-approval`,
      payload
    );
  };

  export const markAsFeaturedImage = (payload) => {
    return postRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraiser/gallery/mark-as-default`,
      payload
    );
  };

  export const savePaymentMethods = (id,payload) => {
    return putRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraiser/update-payment-method/${id}`,
      payload
    );
  };

  export const savefundraiserComponents = (id,payload) => {
    return putRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}fundraiser/update-fundraiser-components/${id}`,
      payload
    );
  };

  export const updateExchangeRates = (payload) => {
    return putRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}exchange-rates`,
      payload
    );
  };


  export const getCommissions = () =>
  getRequest(`${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}settings/commissions`);

  export const updateCommissions = (payload) => {
    return putRequest(
      `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}settings/update-commission`,
      payload
    );
  };

  export const getUserWallets = (userId) =>
  getRequest(
    `${process.env.VUE_APP_BLESS_DASH_SERVER_BASE_URL}users/wallet/${userId}`
  );