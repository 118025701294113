<template>
  <b-card
    no-body
    class="card-transaction"
  >
    <b-card-header>
      <div>
        <b-card-title>Top Blessors</b-card-title>
        <b-card-text class="font-small-2">
          {{ selectedFilter }}
        </b-card-text>
      </div>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item
          v-for="option in filterOptions"
          :key="option"
          @click="selectedFilter = option"
        >
          {{ option }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center h-100"
      >
        <feather-icon
          icon="LoaderIcon"
          class="spinner"
          size="60"
        />
      </div>
      <template v-else>
        <div
          v-for="blessor in response.data"
          :key="blessor.id"
          class="transaction-item"
        >
          <b-media
            no-body
            class="flex-grow-1 mr-1"
          >
            <b-media-aside>
              <div
                class="b-avatar rounded-circle"
                :style="{
                  fontSize: '14px',
                  width: '42px',
                  height: '42px',
                  backgroundColor: randomColor({
                    luminosity: 'light',
                    seed: `${blessor.firstname[0] + blessor.lastname[0]}`,
                    format: 'rgba',
                    alpha: 0.3,
                  }),
                  color: randomColor({
                    luminosity: 'bright',
                    seed: `${blessor.firstname[0] + blessor.lastname[0]}`,
                  }),
                }"
              >
                <b-img
                  v-if="blessor.profilePhoto"
                  :src="blessor.profilePhoto"
                  alt="profile_image"
                />
                <span v-else>
                  {{ blessor.firstname[0] + blessor.lastname[0] }}
                </span>
              </div>
            </b-media-aside>
            <b-media-body>
              <div class="d-flex">
                <h6
                  class="transaction-title text-truncate flex-grow-1"
                  style="width: 20px"
                >
                  {{ blessor.firstname }} {{ blessor.lastname }}
                </h6>
              </div>
              <small>{{ blessor.fundraiserId }}</small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bold text-body-heading">
            ₦{{ blessor.amount.toLocaleString() }}
          </div>
        </div>
      </template>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import randomColor from 'randomcolor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useApi } from '@core/comp-functions/useApi'

const [res, apiRequest] = useApi('blessings')

const filterOptions = ['Today', 'This Week', 'This Month', 'This Year']

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      response: res,
      selectedFilter: filterOptions[3],
    }
  },
  computed: {
    filterOptions: () => filterOptions,
  },
  watch: {
    'response.isFailure': function (isFailure) {
      if (isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: this.response.error || 'Failed to fetch resource',
            icon: 'TrashIcon',
            variant: 'danger',
          },
        })
      }
    },

    selectedFilter: {
      handler(value) {
        apiRequest({
          filter: value.replace(/^This\s+/, '').toUpperCase(),
          offset: 0,
          limit: 5,
        })
      },
      immediate: true,
    },
  },
  methods: {
    randomColor,
  },
}
</script>
