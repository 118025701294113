<template>
  <div class="image-card">
    <div
      v-if="isFeatured"
      class="badge"
    >
      Featured
    </div>
    <img
      :src="image"
      style="width: 100%"
    >

    <div class="d-flex justify-content-between pl-2">
      <div class="d-flex align-items-center">
        <div v-if="!isFeatured">

          <b-button
            variant="primary"
            @click="$emit('setFeaturedImage', id)"
          >
            Mark as Featured
          </b-button>
        </div>
      </div>
      <b-button
        variant="outline-primary m-2"
        @click="$emit('delete', { id, index })"
      >Delete</b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BImg, BButton } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BImg,
  },

  data() {
    return {};
  },

  props: {
    image: {
      type: String,
    },
    index: {
      type: Number,
    },
    id: {
      type: Number,
      default: 0,
    },
    isFeatured: {
      type: Boolean,
      default: false,
    }
  },

  methods: {},
};
</script>

<style scoped>
.image-card {
  width: 100%;
  /* height: 100px; */
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

img {
  border-radius: 6px 6px 0px 0px;
}
.badge{
  background: red;
  color: white;
  font-weight:bold;
  border-radius: 0;
  position: absolute;
}
</style>
