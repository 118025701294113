<template>
  <b-modal
    id="delete-user-modal"
    ref="delete-modal"
    centered v-model="deleteModal"
    hide-footer
  >
    <div style="padding: 25px;">
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Are you sure you want to delete this user? This action is not reversible and will delete user/user details completely from the database.
      </div>
      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button
          variant="primary"
          style="text-transform: capitalize"
          @click="$emit('delete')"
        >
          Delete
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="closeModal"
        >
          Discard
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
  import {
    BTable,
    //   BFormCheckbox,
    BCard,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BFormInput,
    BModal,
    BButton,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BPagination,
  } from "bootstrap-vue";
  
  export default {
    components: {
      BTable,
      // BButton,
      // BFormCheckbox,
      BFormSelect,
      BFormGroup,
      BFormInput,
      BButton,
      BCard,
      BRow,
      BModal,
      BImg,
      BCol,
      BBadge,
      BAvatar,
      BPagination,
      BFormTextarea,
    },
  
    data() {
      return {
        deleteModal: false
      };
    },
  
    props: {
      userId: {
        type: String | Number
      },
    },
  
    methods: {
      closeModal() {
        this.deleteModal = false
        // this.$nextTick(() => {
        //   this.$refs["delete-user-modal"].toggle("#toggle-btn");
        // });
      },
    },
  };
  </script>
  
  <style scoped></style>
  