<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <span>Personal</span>
      </template>
      <personal-registration></personal-registration>
    </b-tab>
    <b-tab>
      <template #title>
        <span>Organisation</span>
      </template>
      <organisation-registration></organisation-registration>
    </b-tab>
  </b-tabs>
</template>

<script>
/* eslint-disable */
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import UserOverview from "./UserOverview.vue";
import PersonalRegistration from './PersonalRegistration.vue';
import OrganisationRegistration from './OrganisationRegistration.vue';

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    UserOverview,
    PersonalRegistration,
    OrganisationRegistration
  },
};
</script>
