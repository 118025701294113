<template>
  <b-modal id="email-user" ref="email-user-modal" centered hide-footer>
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Email User
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Please use the form below to send the user an email
      </div>
      <div>
        <b-form-group label="Subject" label-for="subject">
          <b-form-input
            id="subject"
            placeholder="Type subject here"
            :value="subject"
            @change="onChange($event, 'subject')"
          />
        </b-form-group>
      </div>
      <div class="mt-2">
        <label for="message">Message</label>
        <b-form-textarea
          id="message"
          placeholder="Type message here"
          rows="4"
          :value="message"
          @change="onChange($event, 'message')"
        />
      </div>

      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button variant="primary" @click="sendEmailMessage"
          >Send Email</b-button
        >
        <b-button variant="outline-secondary" class="ml-1" @click="closeModal"
          >Discard</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { sendEmail } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  data() {
    return {
      categories: [{ text: "Standard", value: "STANDARD" }],
      subject: "",
      message: "",
    };
  },

  props: {
    userId: {
      type: String,
    },
  },

  methods: {
    sendEmailMessage() {
      sendEmail({
        userid: this.userId,
        subject: this.subject,
        message: this.message,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.message = "";
            this.subject = "";
            this.showToast("Success", "success", response.message);
            this.closeModal();
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    closeModal() {
      this.$nextTick(() => {
        this.$refs["email-user-modal"].toggle("#toggle-btn");
      });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    onChange(value, id) {
      this[id] = value;
    },
  },
};
</script>

<style scoped></style>
