<template>
  <b-card class="">
    <div
      v-if="!blessors || !blessors.length"
      style="text-align: center; font-size: 18px"
      class="mt-4 mb-4"
    >
      No blessors
    </div>
    <div
      v-else
      style="max-width: 500px; margin: auto"
    >
      <div
        style="font-size: 18px; font-weight: 500; color: #161722"
        class="mt-2"
      >
        Blessors ({{ blessors.length }})
      </div>
      <div
        v-for="(obj, index) in blessors"
        :key="index"
        class="mt-1 pb-1"
        style="border-bottom: 1px solid #f5f6f9"
      >
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex">
            <div>
              <b-avatar
                v-if="
                  obj.userdetail &&
                    obj.userdetail.account.accounttype === 'CORPORATE'
                "
                :src="obj.userdetail.profilePhoto"
                variant="light-danger"
              />
              <b-avatar
                v-else
                :text="getAvatarText(obj)"
                variant="light-danger"
              />
            </div>
            <div class="ml-1">
              <div
                v-if="
                  obj.userdetail &&
                    obj.userdetail.account.accounttype === 'CORPORATE'
                "
                style="color: #161722"
              >
                {{ obj.userdetail.username }}
              </div>
              <div
                v-else
                style="color: #161722"
              >
                {{ obj.firstname }} {{ obj.lastname }} <feather-icon
                  v-b-modal.edit-blessor
                  icon="EditIcon"
                  size="14"
                  @click="setBlessor(obj)"
                />
              </div>
              <div class="font-small-2">
                <span class="text-muted font-small-2">{{
                  dayjs().to(obj.createdAt)
                }}</span>
              </div>
            </div>
          </div>
          <div class="ml-1">
            <div>
              <div class="font-small">
                <span
                  style="font-weight: bold; color: #017622"
                >&#8358;{{ getFormattedAmount(obj.amount) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="edit-blessor"
      v-model="editModal"
      title="Edit Blessor Name"
      hide-footer
      centered
    >

      <b-form @submit.prevent="updateBlessor">
        <b-form-group label="Firstname">
          <b-form-input v-model="blessor.firstname" />
        </b-form-group>

        <b-form-group label="Lastname">
          <b-form-input v-model="blessor.lastname" />
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
        >
          Update
        </b-button>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab, BForm,
  BCardText,
} from "bootstrap-vue";
import { fetchBlessors, getFundUpdates, editBlessor } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
const flatObject = (object) => {
  let result = {};

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key]?.constructor === Object) {
        result = { ...result, ...flatObject(object[key]) };
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
};

export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText, BForm,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      editModal: false,
      fundraiserId: null,
      blessors: [],
      blessor: {},
      members: [
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Creator",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Beneficiary",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Observer",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Observer",
        },
      ],
    };
  },
  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getFundraiserBlessors();
  },
  methods: {
    dayjs,
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    setBlessor (obj){
      this.blessor = JSON.parse(JSON.stringify(obj))
   
    },
    getFundraiserBlessors() {
      fetchBlessors(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.blessors = response.data;
          }
        })
        .catch((e) => console.error(e));
    },
    updateBlessor() {
      editBlessor(this.blessor)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast("Success", "success", response.message);
            this.getFundraiserBlessors()
           this.editModal = false
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    getFormattedAmount(amount) {
      return amount.toLocaleString();
    },
  },
};
</script>

<style scoped></style>
