<template>
  <div>
    <b-card>
      <h3>Withdraw Funds</h3>
      <hr>
      <!-- {{ exchangeRates }} -->
      <b-row>
        <b-col md="5">
          <b-overlay
            spinner-variant="primary"
            opacity="0.4"
            :show="loading"
          >
            <b-form @submit.prevent="withdraw">
              <b-form-group label="Amount">
                <b-form-input
                  v-model="form.amount"
                  required
                />
              </b-form-group>
              <!-- <b-form-group label="Source Account Number">
                <b-form-input
                  v-model="form.sourceAccountNumber"
                  required
                />
              </b-form-group> -->
              <b-form-group label="Destination Bank">
                <b-form-select
                  v-model="form.destinationBankCode"
                  :options="banks"
                  required
                  @change="verifyAccount"
                />
              </b-form-group>

              <b-form-group label="Destination Account Number">
                <b-form-input
                  v-model="form.destinationAccountNumber"
                  required
                  @keyup="verifyAccount"
                />
              </b-form-group>
              <b-form-group
                v-if="account_name"
                label="Account Name"
              >
                <b-form-input
                  :value="account_name"
                  required
                  readonly
                  disabled
                />
              </b-form-group>
              <b-form-group label="Narration">
                <b-form-textarea
                  v-model="form.narration"
                  required
                />
              </b-form-group>
              <b-button
                :disabled="account_name === '' ? true : false"
                variant="primary"
                type="submit"
              >
                Withdraw
              </b-button>
            </b-form>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormSelect,
  BFormTextarea,
  BOverlay,
} from 'bootstrap-vue'
import { verifyBankAccount, manualTransfer } from '@/service/api.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { banksdata } from '../../data/banks'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormSelect,
    BFormTextarea,
    BOverlay,
  },
  data() {
    return {
      account_name: '',
      form: {
        amount: '',
        reference: '',
        destinationAccountNumber: '',
        destinationBankCode: '',
        currency: 'NGN',
        narration: '',
      },
      loading: false,
    }
  },
  computed: {
    banks() {
      const bankOptions = [{ text: 'Select bank', value: null }]
      banksdata.forEach(bank => {
        bankOptions.push({ text: bank.name, value: bank.code })
      })
      return bankOptions
    },
  },

  methods: {
    generateRef() {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 16; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        )
      }
      return result
    },

    verifyAccount() {
      if (
        this.form.destinationBankCode
        && this.form.destinationAccountNumber.length === 10
      ) {
        this.loading = true
        this.account_name = ''

        verifyBankAccount({
          account_number: this.form.destinationAccountNumber,
          account_bank: this.form.destinationBankCode,
        })
          .then(res => res.json())
          .then(response => {
            this.loading = false

            if (response.valid) {
              this.account_name = response.data.account_name
              this.form.reference = this.generateRef()
              this.showToast(
                'Success',
                'success',
                'Account number validated successfully!',
              )
            } else {
              this.showToast(
                'Error',
                'danger',
                'Account number resolution failed.',
              )
            }
          })
          .catch(e => {
            this.loading = false
            this.showToast('Error', 'danger', e)
          })
      }
    },

    async withdraw() {
      this.loading = true
      await manualTransfer(this.form)
        .then(res => res.json())
        .then(response => {
        //   this.loading = false

          if (response.valid) {
            this.showToast('Success', 'success', 'Transfer successful!')
          } else {
            this.showToast(
              'Error',
              'danger',
              'Transfer failed, please try again later!',
            )
          }
        })
        .catch(e => {
          this.loading = false
          this.showToast('Error', 'danger', e)
        })
      this.loading = false
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: title === 'Error' ? 'TrashIcon' : 'DoneIcon',
          variant,
        },
      })
    },
  },
}
</script>

  <style>
</style>
