<template>
  <main>
    <b-row>
      <b-col cols="12">
        <b-card>
          <div
            style="padding-bottom: 16px; border-bottom: 1px solid #EBE9F1; font-size: 18px; font-weight: 600"
          >
            Add a Personal Account
          </div>
          <div style="max-width: 500px; margin: auto" class="mt-4 mb-4">
            <form @submit="onSubmit">
              <b-row>
                <b-col cols="6">
                  <b-form-group label="Firstname" label-for="firstname">
                    <b-form-input
                      id="firstname"
                      required
                      placeholder="Firstname"
                      v-model="firstname"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Lastname" label-for="lastname">
                    <b-form-input
                      id="lastname"
                      required
                      placeholder="Lastname"
                      v-model="lastname"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Email" label-for="email">
                    <b-form-input
                      id="email"
                      required
                      placeholder="Email"
                      v-model="email"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Username" label-for="username">
                    <b-form-input
                      id="username"
                      required
                      placeholder="Username"
                      v-model="username"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Phone Number" label-for="phonenumber">
                    <b-form-input
                      id="phonenumber"
                      required
                      placeholder="+234 (81) 23456789"
                      type="number"
                      v-model="phonenumber"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Gender" label-for="gender">
                    <b-form-select
                      required
                      :options="genders"
                      id="gender"
                      v-model="gender"
                      @change="onChange($event, 'gender')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Date of Birth" label-for="dob">
                    <b-form-input
                      id="dob"
                      required
                      placeholder="dob"
                      type="date"
                      v-model="dob"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="User Category" label-for="userCategory">
                    <b-form-select
                      :options="categories"
                      required
                      id="userCategory"
                      v-model="userCategory"
                      @change="onChange($event, 'userCategory')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Commission Type" label-for="commission">
                    <b-form-select
                      disabled
                      required
                      :options="commissions"
                      id="commission"
                      v-model="commission"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address" label-for="address">
                    <b-form-input
                      id="address"
                      placeholder="Address"
                      required
                      type="text"
                      v-model="address"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Country" label-for="country">
                    <b-form-select
                      :options="countries"
                      required
                      id="country"
                      v-model="country"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="State/Province" label-for="state">
                    <b-form-select
                      :options="states"
                      required
                      id="state"
                      v-model="state"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="BVN" label-for="bvn">
                    <b-form-input
                      id="bvn"
                      placeholder="BVN"
                      type="number"
                      required
                      v-model="bvn"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Account Number"
                    label-for="accountnumber"
                  >
                    <b-form-input
                      required
                      id="accountnumber"
                      placeholder="Account No."
                      type="text"
                      v-model="accountnumber"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Bank" label-for="bank">
                    <b-form-select
                      :options="banks"
                      required
                      id="bank"
                      :value="bank"
                      @change="onChange($event, 'bank')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ID Type" label-for="idType">
                    <b-form-select
                      :options="idTypes"
                      required
                      id="idType"
                      :value="idType"
                      @change="onChange($event, 'idType')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <div style="text-align: center">
                    <input
                      id="file"
                      type="file"
                      style="display: none"
                      @change="onFileSelected"
                    />
                    <label
                      v-if="!fileBase64"
                      style="border: 2px dashed #7666F8; width: 100%; height: 129px"
                      for="file"
                    >
                      <div class="mt-4" style="color: #7666F8;">
                        Click here to upload ID
                      </div>
                    </label>
                  </div>
                  <div
                    v-if="fileBase64"
                    class="p-4"
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                  >
                    <div style="text-align: center;">
                      <img :src="fileBase64" style="width: 100%" />
                    </div>
                    <div style="text-align: right">
                      <b-button
                        variant="outline-primary"
                        class="ml-1 mt-2"
                        @click="removeFile"
                        >Remove</b-button
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div class="mb-0 text-center mt-2">
                <!-- basic -->
                <b-button variant="primary" type="submit">{{
                  isLoading ? "Creating user..." : "Create User"
                }}</b-button>

                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  @click="discard"
                  >Discard</b-button
                >
              </div>
            </form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
/* eslint-disable */
import {
  //   BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  //   BImg,
  BFormInput,
  BModal,
  BSpinner,
  BButton,
  BFormSelect,
  BBadge,
  //   BPagination,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  createBankDetails,
  createNewUser,
  resetUserPassword,
  verifyBankAccount,
  verifyBVN,
} from "@/service/api.service";
import { banksdata } from "../../data/banks";
import { stateslist } from "../../data/states";
import { COMMISSION_TYPES } from "@/@core/utils/constants";
import { useApi } from '@core/comp-functions/useApi'
const [res, apiRequest] = useApi("commissions");

export default {
  components: {
    // BTable,
    BButton,
    // // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    // BButton,
    BCard,
    BRow,
    BModal,
    BSpinner,
    // BImg,
    BCol,
    BBadge,
    BAvatar,
    // BPagination,
    // FeatherIcon,
  },
  data() {
    return {
      userId: null,
      response: null,
      categories: [
        { text: "Select user category", value: null },
        { text: "Standard", value: "Standard" },
        { text: "VIP", value: "VIP" },
        { text: "Charity", value: "Charity" },
      ],
      commissions: [
        { text: "Select commission type", value: null },
        { text: "Standard", value: COMMISSION_TYPES.STANDARD },
        { text: "VIP", value: COMMISSION_TYPES.VIP },
        { text: "Charity", value: COMMISSION_TYPES.CHARITY },
      ],
      countries: [
        { text: "Select country", value: null },
        { text: "Nigeria", value: "ng" },
      ],
      idTypes: [
        { text: "Select ID type", value: null },
        { text: "International Passport", value: "International Passport" },
        { text: "Drivers License", value: "Drivers License" },
      ],
      genders: [
        { text: "Select gender", value: null },
        { text: "Male", value: "MALE" },
        { text: "Female", value: "FEMALE" },
      ],
      gender: null,
      dob: null,
      commission: null,
      userCategory: null,
      country: "ng",
      isLoading: false,
      state: null,
      idType: null,
      bank: null,
      bvn: null,
      accountnumber: null,
      phonenumber: null,
      email: null,
      firstname: null,
      lastname: null,
      address: null,
      fileBase64: null,
      idFile: null,
      username: null,
      bankResponse: null
    };
  },
  async created() {},
  computed: {
    banks() {
      const bankOptions = [{ text: "Select bank", value: null }];
      banksdata.forEach((bank) => {
        bankOptions.push({ text: bank.name, value: bank.code });
      });
      return bankOptions;
    },
    states() {
      const stateOptions = [{ text: "Select state", value: null }];
      stateslist.forEach((state) => {
        stateOptions.push({ text: state, value: state.toUpperCase() });
      });
      return stateOptions;
    },
  },
  methods: {
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    onChange(value, id) {
      if (id === 'userCategory'){
        this.commission = this.commissions.find(val => val.text === value).value
      }
      this[id] = value;
    },
    discard() {
      this.$router.push("/users");
    },
    verifyBankDetails() {
      const payload = {
        account_bank: this.bank,
        account_number: this.accountnumber,
      };
      return verifyBankAccount(payload);
    },
    verifyBVNNumber() {
      const { firstname, lastname, bank, bvn, accountnumber } = this;
      const payload = {
        firstname,
        lastname,
        bvn,
        bankCode: bank,
        accountNumber: accountnumber,
      };
      return verifyBVN(payload);
    },

    onSubmit(e) {
      e.preventDefault();
      if (this.idFile) {
        this.createUser();
      } else {
        this.showToast("Error", "danger", "Please upload ID");
      }
    },

    submitUserDetails() {
      const {
        firstname,
        lastname,
        email,
        phonenumber,
        bvn,
        userCategory,
        commission,
        idType,
        address,
        country,
        state,
        dob,
        gender,
        idFile,
        username,
      } = this;

      const payload = {
        firstname,
        lastname,
        email,
        phonenumber,
        bvn,
        userCategory,
        commission,
        idType,
        address,
        country,
        state,
        dob,
        gender,
        idFile,
        username,
        accounttype: "INDIVIDUAL",
      };
      return createNewUser(payload);
    },
    addBankDetails(userid) {
      const { text } = this.banks.find((obj) => obj.value === this.bank);
      const payload = {
        userid,
        bankname: text,
        bankcode: this.bank,
        accountnumber: this.accountnumber,
        bvn: this.bvn,
        accountname: this.bankResponse.data.account_name,
      };
      return createBankDetails(payload);
    },
    async createUser() {
      this.isLoading = true;
      const bankVerificationResponse = await this.verifyBankDetails();
      const resp = await bankVerificationResponse.json();
      this.bankResponse = resp
      if (resp.valid) {
        this.showToast(
          "Success",
          "success",
          "Bank account verification successful"
        );
        const bvnVerificationResponse = await this.verifyBVNNumber();
        const bvnResp = await bvnVerificationResponse.json();
        if (bvnResp.valid) {
          this.showToast("Success", "success", "BVN verification successful");
          const createUserResponse = await this.submitUserDetails();
          const response = await createUserResponse.json();
          if (response.valid) {
            const userid = response.data.id;
            this.showToast("Success", "success", "User created successfully");
            await this.addBankDetails(userid);
            this.isLoading = false;
            this.$router.push("/users");
          } else {
            this.showToast("Error", "danger", response.message);
            this.isLoading = false;
          }
        } else {
          this.showToast("Error", "danger", bvnResp.message);
          this.isLoading = false;
        }
      } else {
        this.showToast("Error", "danger", resp.message);
        this.isLoading = false;
      }
    },
    async onFileSelected(event) {
      const file = event.target.files[0];
      console.log(file);
      this.fileBase64 = await this.toBase64(file);
      this.idFile = {
        name: file.name,
        mimetype: file.type,
        base64: this.fileBase64,
      };
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    removeFile() {
      this.fileBase64 = null;
    },
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}

.item-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.item-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-left: 8px;
}

.value {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
}

.item {
  margin-top: 14px;
}
</style>
