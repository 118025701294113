<template>
  <b-card>
    <div
      v-if="!data || !data.comments.length"
      style="text-align: center; font-size: 18px"
      class="mt-4 mb-4"
    >
      No comments
    </div>
    <div class="" v-else>
      <div style="font-size: 18px; font-weight: 500;" class="">
        Comments ({{ data.count }})
      </div>
      <b-row>
        <b-col cols="12">
          <div
            style="display: flex; border-bottom: 1px solid #f5f6f9"
            class="mt-1 pb-1"
            v-for="(comment, index) in data.comments"
            :key="index"
          >
            <div>
              <b-avatar :text="getAvatarText(comment.userdetail)" variant="light-danger" />
            </div>
            <div class="ml-1">
              <div style="font-weight: 500; color: #161722">
                {{ comment.userdetail.firstname }}
                {{ comment.userdetail.lastname }}
              </div>
              <div style="margin-top: 10px">{{ comment.comment }}</div>
              <div style="margin-top: 10px" class="text-muted font-small-2">{{ dayjs().to(comment.createdAt) }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import {
  fetchBlessors,
  fetchComments,
  getFundUpdates,
} from "@/service/api.service";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
const flatObject = (object) => {
  let result = {};

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key]?.constructor === Object) {
        result = { ...result, ...flatObject(object[key]) };
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
};

export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
  },
  data() {
    return {
      fundraiserId: null,
      data: null,
    };
  },
  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getFundraiserComments();
  },
  methods: {
    dayjs,
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    getFundraiserComments() {
      fetchComments(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.data = response.data;
          }
        })
        .catch((e) => console.error(e));
    },
  },
};
</script>

<style scoped></style>
