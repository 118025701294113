<template>
  <div>
    <b-card>
      <div
        style="display: flex; justify-content: space-between; border-bottom: 1px solid #f5f6f9; padding-bottom: 1rem"
      >
        <div
          v-if="fundraiserData"
          style="font-size: 18px; color: #5E5873;"
        >
          {{ fundraiserData.title }}
        </div>
        <div>
          <feather-icon
            icon="EditIcon"
            size="20"
            style="cursor: pointer"
          />
        </div>
      </div>
      <transactions-filter
        @filter="filterTable($event)"
        @reset="resetTable"
      />

      <div>
        <div style="padding: 20px">
          <b-row>
            <b-col cols="5">
              <div style="display: flex;">
                <span style="margin-top: 7px">Show</span>
                <div style="margin-left: 15px">
                  <b-form-select
                    v-model="limit"
                    :options="options"
                    @change="setPerPage"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="4">
              <div style="display: flex;">
                <span style="margin-top: 7px">Search</span>
                <div style="margin-left: 15px; width: 100%">
                  <b-form-input
                    id="search"
                    v-model="filter"
                    placeholder="Search"
                    style="width: 100%"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="3">
              <b-button
                variant="outline-primary"
                style="width: 100%; color: #7367f0"
              >
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="16"
                  class="mr-1"
                />
                Export
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="response.isLoading"
          class="d-flex align-items-center justify-content-center h-100 mt-16"
          style="margin-top: 4rem"
        >
          <feather-icon
            icon="LoaderIcon"
            class="spinner"
            size="60"
          />
        </div>
        <b-table
          v-else
          :items="response.data"
          :fields="fields"
          responsive
          :filter="filter"
          class="mb-0"
        >
          <template #cell(date)="data">
            <div class="d-flex align-items-center">
              <div>
                <div>
                  {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
                </div>
                <div class="font-small-2 text-muted">
                  {{ dayjs(data.item.createdAt).format("h:mm a") }}
                </div>
              </div>
            </div>
          </template>

          <template #cell(name)="data">
            <div
              v-if="data.item.userdetail"
              style="display: flex"
            >
              <b-avatar
                :text="getAvatarText(data.item.userdetail)"
                variant="light-danger"
              />
              <div style="margin-left: 12px;">
                <div>
                  {{ data.item.userdetail.firstname }}
                  {{ data.item.userdetail.lastname }}
                </div>
                <div class="font-small-2 text-muted">
                  {{ data.item.userdetail.email }}
                </div>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </template>

          <template #cell(idFile)="data">
            <div
              v-if="data.value"
              v-b-modal.modal-center
              class="file"
              @click="viewImage(data)"
            >
              <img
                class="file-img"
                :src="data.value"
              >
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[0][data.value]">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(transactionType)="data">
            <div :style="'color:' + status[0][data.value]">
              {{ data.value }}
            </div>
          </template>

          <template #cell(amount)="data">
            <div>{{ getFormattedAmount(data.item) }}</div>
          </template>

          <template #cell(actions)="data">
            <feather-icon
              v-b-modal.transaction-details
              icon="EyeIcon"
              size="16"
              class="text-body align-middle mr-25"
              style="cursor: pointer"
              @click="setTransactionDetails(data)"
            />
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
              style="margin-left: 10px; cursor: pointer"
              @click="openEdit(data)"
            />
          </template>
        </b-table>
        <div
          style="padding: 20px; display: flex; justify-content: space-between;"
        >
          <div style="color: #B9B9C3;">
            Showing {{ getEntryNumber }} to {{ getEntriesCount }} of
            {{ response.metadata ? response.metadata.totalRecords : 0 }} entries
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="response.metadata ? response.metadata.totalRecords : 0"
            :per-page="limit"
            align="right"
            size="sm"
            class="my-0"
            @change="handlePaginationChange"
          />
        </div>
      </div>
    </b-card>
    <transaction-details-modal
      :data="transactionDetails"
    />
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import TransactionsFilter from "@/@core/components/filters/TransactionsFilter.vue";
import TransactionDetailsModal from "@/@core/components/transactions/modals/TransactionDetailsModal.vue";
import { getFundDetails } from "@/service/api.service";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    TransactionsFilter,
    TransactionDetailsModal,
  },
  data() {
    return {
      response: null,
      currentPage: 1,
      offset: 0,
      limit: 50,
      fundraiserData: null,
      perPage: 10,
      filters: {},
      filter: '',
      imageURL: "",
      transactionDetails: null,
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      fields: [
        // 'row_selector',
        "id",
        { key: "name", label: "Name" },
        "date",
        { key: "transactionType", label: "transaction type" },
        { key: "transactionReference", label: "transaction ref" },
        { key: "amount", label: "amount" },
        { key: "paymentChannel", label: "processor" },
        { key: "status", label: "status" },
        "actions",
      ],
      status: [
        {
          INITIATEDD: "light-primary",
          DONATION: "green",
          TOPUP: "green",
          "TOP UP": "green",
          PROMOTION: "red",
          PAID: "light-success",
          WITHDRAWAL: "red",
          PROLIFICFUNDRAISER: "light-danger",
          INITIATED: "light-warning",
        },
      ],
    };
  },
  props: {
    fundraiserId: {
      type: String,
    },
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  async created() {
    await this.getUsers();
    await this.getFundraiserDetails();
  },
  methods: {
    dayjs,
    async filterTable(filters) {
      this.filters = filters;
      await this.getUsers();
    },
    async resetTable() {
      this.filters = {};
      this.currentPage = 1;
      await this.getUsers();
    },
    getFundraiserDetails() {
      this.loading = true;
      getFundDetails(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.fundraiserData = response.data;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    getUsers(offset = 0) {
      const [res, apiRequest] = useApi(
        `transactions/findByFundraiser/${this.fundraiserId}`
      );
      this.response = res;
      apiRequest({ ...this.filters, offset, limit: this.limit });
    },
    getFormattedAmount(data) {
      return `${
        data.currency ? data.currency : "-"
      } ${data.dollarAmount ? data.dollarAmount.toLocaleString() : data.amount.toLocaleString()}`;
    },
    openEdit(data) {
      console.log("edit ", data);
    },
    setTransactionDetails(data) {
      this.transactionDetails = data.item;
      console.log("trds ", this.transactionDetails);
    },
    viewImage(data) {
      console.log("images ", data);
      this.imageURL = data.item.idFile;
    },
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    setPerPage(e) {
      console.log("valueee ", e);
      this.limit = e;
      this.getUsers(this.offset);
    },
    handlePaginationChange(event) {
      this.offset = (event - 1) * this.limit;
      this.getUsers(this.offset);
    },
    routeToNewUser() {
      this.$router.push(`/users/new-user`);
    },
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}

.red {
  background: #fd5f5f;
}
.green {
  background: #017622;
}
</style>
