<template>
  <b-modal id="response-modal" ref="response-modal" centered hide-footer>
    <div style="padding: 25px;" class="text-center">
      <div style="width: 75px; height: 75px; margin: auto" class="text-center">
        <img src="@/assets/icons/success.svg" alt="" class="text-center" style="width: 100%" />
      </div>
      <div
      class="mt-2"
        style="font-size: 24px; color: #5E5873; font-weight: 400; padding-top: 10px; padding-bottom: 24px;"
      >
        {{ response }}
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  data() {
    return {};
  },

  props: {
    response: {
      type: String,
      default: "Fundraiser created successfully ",
    },
    type: {
      type: String,
      default: "success",
    },
    open: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    open(value) {
      this.toggleModal();
      if (!value) {
        this.$emit("close");
      }
    },
  },

  methods: {
    toggleModal() {
      this.$nextTick(() => {
        this.$refs["response-modal"].toggle("#toggle-btn");
      });
    },
  },
};
</script>

<style scoped></style>
