<template>
  <b-modal id="edit-faq" ref="edit-faq-modal" centered hide-footer>
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Edit FAQ
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      ></div>
      <form @submit="onSubmit">
      <div>
        <b-form-group label="Question" label-for="question">
          <b-form-input
            placeholder="Question"
            id="question"
            :value="question"
            @change="onChange($event, 'question')"
          />
        </b-form-group>
      </div>
      <div class="mt-2">
        <label for="answer">Answer</label>
        <b-form-textarea
          id="answer"
          placeholder="Type answer here"
          rows="6"
          :value="answer"
          @change="onChange($event, 'answer')"
        />
      </div>

      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button variant="primary" type="submit">Save</b-button>
        <b-button variant="outline-secondary" class="ml-1" @click="closeModal"
          >Discard</b-button
        >
      </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { editFaq, sendInternalMessage } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  props: {
    data: {
      type: Object,
    },
  },

  data() {
    return {
      question: null,
      answer: null,
    };
  },

  watch: {
    data(data) {
      this.question = data.question;
      this.answer = data.answer;
    },
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.updateFaq()
    },
    updateFaq() {
      const payload = {
        id: this.data.id,
        question: this.question,
        answer: this.answer,
      };
      editFaq(payload)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.question = null;
            this.answer = null;
            this.$emit("refresh");
            this.showToast("Success", "success", response.message);
            this.closeModal();
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    closeModal() {
      this.$nextTick(() => {
        this.$refs["edit-faq-modal"].toggle("#toggle-btn");
      });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    onChange(value, id) {
      this[id] = value;
    },
  },
};
</script>

<style scoped></style>
