<template>
  <div class="mt-2">
    <div style="font-size: 18px;">
      Description
    </div>
    <div class="mt-1" v-html="data.fundraiserDesc"></div>
    <div
      style="font-size: 18px;"
      class="mt-2"
    >
      Updates
    </div>
    <div v-if="updates.length">
      <div
        v-for="(update, index) in updates"
        :key="index"
        class="mt-1"
      >
        {{ update.message }}
      </div>
    </div>
    <div
      v-else
      class="mt-1"
    >
      No updates.
    </div>
    <div
      style="font-size: 18px;"
      class="mt-2"
    >
      Fundraiser Members
    </div>
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col
            v-for="(obj, index) in data.members"
            :key="index"
            cols="6"
            class="mt-2"
          >
            <div style="display: flex">
              <b-avatar
                :text="getAvatarText(obj)"
                variant="light-danger"
              />
              <div class="ml-1">
                <div class="font-small-2 text-muted">
                  {{ obj.memberType }}
                </div>
                <div v-if="obj.memberId">
                  {{ obj.userdetail ? obj.userdetail.firstname : "" }}
                  {{ obj.userdetail ? obj.userdetail.lastname : "" }}
                </div>
                <div v-else>
                  {{ obj.fullname }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div
      class="mt-2"
      style="border: 1px solid #f5f6f9"
    />
    <div
      style="font-size: 18px;"
      class="mt-2"
    >
      Follow on Social Media
    </div>
    <div style="display: flex; justify-content: space-between">
      <div class="mt-2">
        <a
          v-for="(link, index) in data.socialmedialinks"
          :key="index"
          :href="link.link"
          class="ml-2"
        >
          <feather-icon
            v-if="link.smname !== 'YouTube'"
            :icon="link.smname + 'Icon'"
            size="24"
          />
          <feather-icon
            v-else
            icon="YoutubeIcon"
            size="24"
          />
        </a>
      </div>
      <div class="mt-2">
        <feather-icon
          icon="HeartIcon"
          size="20"
        />
        <span class="ml-1">{{ data.likes.totalLikes }}</span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import { getFundUpdates } from "@/service/api.service";
export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      fundraiserId: null,
      updates: [],
      members: [
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Creator",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Beneficiary",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Observer",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Observer",
        },
      ],
    };
  },
  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getFundraiserUpdates();
  },
  methods: {
    getAvatarText(obj) {
      const avatarText = obj.memberId
        ? `${obj.userdetail ? obj.userdetail.firstname.charAt(0) : "-"} ${
            obj.userdetail ? obj.userdetail.lastname.charAt(0) : "-"
          }`
        : `${obj.fullname.split(" ")[0].charAt(0)} ${obj.fullname
            .split(" ")[1]
            .charAt(0)}`;
      return avatarText.toUpperCase();
    },
    getFundraiserUpdates() {
      getFundUpdates(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.updates = response.data;
          }
        })
        .catch((e) => console.error(e));
    },
     
  },
};
</script>

<style scoped></style>
