<template>
  <div class="">
    <b-card>
      <div
        v-if="!flags || !flags.length"
        style="text-align: center; font-size: 18px"
        class="mt-4 mb-4"
      >
        No flags
      </div>
      <b-row v-else>
        <b-col cols="12">
          <div class="mt-2 reward" v-for="(flag, index) in flags" :key="index">
            <div style="display: flex; justify-content: space-between;">
              <div style="color: #161722">{{ flag.description }}</div>
              <div class="font-small-2">
                {{ dayjs().to(flag.createdAt) }}
              </div>
            </div>
            <div
              class="mt-1"
              style="display: flex; justify-content: space-between"
            >
              <div>
                <span style="color: #161722">{{ flag.reason }}</span>
              </div>
              <div
                style="font-weight: bold; color: #222C37"
                @click="setFlagObj(flag)"
                v-b-modal.view-flag
              >
                View
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <view-flag-details-modal :flag="flagObj"></view-flag-details-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import {
  fetchBlessors,
  fetchFundraiserFlags,
  fetchRewards,
  getFundUpdates,
} from "@/service/api.service";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ViewFlagDetailsModal from "@/@core/components/fundraisers/modals/ViewFlagDetailsModal.vue";
dayjs.extend(relativeTime);
const flatObject = (object) => {
  let result = {};

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key]?.constructor === Object) {
        result = { ...result, ...flatObject(object[key]) };
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
};

export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
    ViewFlagDetailsModal,
  },
  data() {
    return {
      fundraiserId: null,
      blessors: [],
      flags: [],
      flagObj: null,
    };
  },
  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getFlags();
  },
  methods: {
    dayjs,
    getFlags() {
      fetchFundraiserFlags(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.flags = response.data;
          }
        })
        .catch((e) => console.error(e));
    },
    setFlagObj(flag) {
      this.flagObj = flag;
    },
  },
};
</script>

<style scoped>
.reward {
  padding: 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
</style>
