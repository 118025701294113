<template>
  <b-modal id="view-bvn" ref="view-bvn" centered hide-footer>
    <div style="padding: 25px;">
      <div style="font-size: 20px; font-weight: 500; text-align: center;">
        Bank Verificaton Number (BVN)
      </div>
      <div class="mt-1 text-center">
        <div style="font-size: 18px">{{ bvn }}</div>
        <!-- <div class="mt-1">{{ flag.description }}</div> -->
      </div>
    </div>
    <div class="text-center mt-1 mb-2">
      <b-button
        variant="primary"
        @click="closeModal"
        style="text-transform: capitalize"
        >Done</b-button
      >
      <b-button
        variant="outline-secondary"
        @click="closeModal"
        class="ml-1"
        style="text-transform: capitalize"
        >Discard</b-button
      >
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  props: {
    bvn: {
      type: String,
    },
  },

  data() {
    return {
      question: null,
      answer: null,
    };
  },

  watch: {
    data(data) {
      this.question = data.question;
      this.answer = data.answer;
    },
  },

  methods: {
    closeModal() {
      this.$nextTick(() => {
        this.$refs["view-bvn"].toggle("#toggle-btn");
      });
    },
  },
};
</script>

<style scoped></style>
