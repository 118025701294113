/* eslint-disable */
import { $themeConfig } from '@themeConfig'

let authData = null;

const localStorageAuth = localStorage.getItem('auth')
if (localStorageAuth){
    const decoded = atob(localStorageAuth)
    authData = JSON.parse(decoded)
}

const initialState = {
    isLoggedIn: false,
    role: null,
    permissions: []
  }

const state = authData ? authData : initialState

export default {
  namespaced: false,
  state: state,
  getters: {},
  mutations: {
    SET_LOGGED_IN(state, val){
        state.isLoggedIn = val
    },
    SET_PERMISSIONS(state, val) {
      state.permissions = val
    },
    SET_USERROLE(state, val) {
      state.role = val
    }
  },
  actions: {
      setLoggedIn({ commit }, val){
        commit('SET_LOGGED_IN', val)
      },
      setRole({ commit }, val){
        commit('SET_USERROLE', val)
      },
      setPermissiona({ commit }, val){
        commit('SET_PERMISSIONS', val)
      }
  },
}
