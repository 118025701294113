<template>
  <main>
    <h3 style="font-weight: bold">FAQs</h3>
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center h-100 mt-16"
      style="margin-top: 4rem"
    >
      <feather-icon icon="LoaderIcon" class="spinner" size="60" />
    </div>
    <div class="mt-1" v-else>
      <b-card v-for="(faq, index) in data" :key="index">
        <b-row>
          <b-col>{{ faq.question }}</b-col>
          <b-col cols="1">
            <div style="color: #7367F0; cursor: pointer; text-align: right" @click="openEdit(faq)" v-b-modal.edit-faq>
              Edit
            </div>
          </b-col>
          <b-col cols="1">
            <div style="color: #7367F0; cursor: pointer; text-align: right" @click="deleteFaq(faq)">
              Delete
            </div>
          </b-col>
          <b-col cols="1">
            <div style="text-align: right">
              <feather-icon icon="ChevronRightIcon" size="16" />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-button variant="primary" v-b-modal.create-faq>Add New</b-button>
    </div>
    <edit-faq-modal :data="faqObj" @refresh="getFaqs"></edit-faq-modal>
    <create-faq-modal @refresh="getFaqs"></create-faq-modal>
  </main>
</template>

<script>
/* eslint-disable */
import { BCard, BRow, BCol, BFormTextarea, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { destroyFaq, editTerms, fetchFaqs } from "@/service/api.service";
import EditFaqModal from '../../@core/components/settings/modals/EditFaqModal.vue'
import CreateFaqModal from '../../@core/components/settings/modals/CreateFaqModal.vue'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    EditFaqModal,
    CreateFaqModal
  },

  data() {
    return {
      content: "",
      loading: false,
      faqs: [],
      faqObj: null,
    };
  },
  created() {
    this.getFaqs();
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
    onSubmit(e) {
      e.preventDefault();
      this.updateTerms();
    },
    getFaqs() {
      this.loading = true;
      fetchFaqs()
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.data = response.data;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.showToast("Error", "danger", e);
        });
    },
    openEdit(faqObj){
        this.faqObj = faqObj
    },
    deleteFaq(faq) {
      destroyFaq(faq.id)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast(
              "Success",
              "success",
              "FAQ successfully deleted"
            );
            this.getFaqs()
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped></style>
