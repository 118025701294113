<template>
  <div class="mt-2">
    <div style="font-size: 18px; font-weight: 500" class="mt-2">
      Rewards
    </div>
    <b-row>
      <b-col cols="12">
        <div class="mt-2 reward" v-for="(reward, index) in rewards" :key="index">
            <div><span style="font-weight: bold">&#8358;{{reward.blessingAmount}}</span><span class="text-muted"> or more</span></div>
            <div class="mt-1" style="font-weight: bold">Reward</div>
            <div class="mt-1">{{reward.reward}}</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import { fetchBlessors, getFundUpdates } from "@/service/api.service";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
const flatObject = (object) => {
  let result = {};

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key]?.constructor === Object) {
        result = { ...result, ...flatObject(object[key]) };
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
};

export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      fundraiserId: null,
      blessors: [],
      members: [
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Creator",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Beneficiary",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Observer",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Observer",
        },
      ],
    };
  },
  props: {
    rewards: {
      type: Array,
      default: [],
    },
  },
  methods: {
    dayjs,
  },
};
</script>

<style scoped>
.reward {
    padding: 1rem;
    border: 1px solid #e5e5e5;
}
</style>
