<!-- eslint-disable vue/require-default-prop -->
<template>
  <div>
    <b-card v-if="fundraiserData">
      <div
        class="mb-3"
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f5f6f9;
          padding-bottom: 1rem;
        "
      >
        <div style="font-size: 18px; color: #5e5873">
          {{ fundraiserData.title }}
        </div>
        <div>
          <feather-icon
            icon="EditIcon"
            size="20"
            style="cursor: pointer"
          />
        </div>
      </div>
      <b-row>
        <b-col md="3">
          <div
            class="
              balance-card
              bg-primary
              text-white
              d-flex
              flex-column
              justify-content-center
              align-items-center
              rounded
            "
          >
            <p>WALLET BALANCE</p>
            <h2 class="text-white">
              &#8358;{{ formatPrice(fundraiserData.wallet.balance) }}
            </h2>
          </div>
        </b-col>
        <b-col md="3">
          <div
            class="
              balance-card
              bg-info
              text-white
              d-flex
              flex-column
              justify-content-center
              align-items-center
              rounded
            "
          >
            <p>AMOUNT RAISED</p>
            <h2 class="text-white">
              &#8358;{{ formatPrice(fundraiserData.raisedAmount) }}
            </h2>
          </div>
        </b-col>
        <b-col md="3">
          <div
            class="
              balance-card
              bg-secondary
              text-white
              d-flex
              flex-column
              justify-content-center
              align-items-center
              rounded
            "
          >
            <p>FUNDRAISER TARGET</p>
            <h2 class="text-white">
              &#8358;{{ formatPrice(fundraiserData.targetAmount) }}
            </h2>
          </div>
        </b-col>
        <b-col md="3">
          <b-button
            v-b-modal.withdrawalModal
            block
            :loading="true"
            variant="success"
          >
            Withdraw Funds
          </b-button>
          <div class="d-flex justify-content-between align-items-center mt-2">
            <p
              style="font-size: 14px"
              class="font-weight-bold"
            >
              Enable Withdrawal: &nbsp;
            </p>

            <b-form-checkbox
              v-model="isManualWithdrawal"
              :value="1"
              :unchecked-value="0"
              switch
              @change="setManualWithdrawal"
            >
              {{ isManualWithdrawal ? "On" : "Off" }}
            </b-form-checkbox>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-1">
            <p
              style="font-size: 14px"
              class="font-weight-bold"
            >
              Auto-Approve Withdrawal: &nbsp;
            </p>

            <b-form-checkbox
              v-model="autoWithdrawalApproval"
              :value="true"
              :unchecked-value="false"
              switch
              @change="setAutoWithdrawalApproval"
            >
              {{ autoWithdrawalApproval ? "On" : "Off" }}
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <h3 class="mt-3 mb-2">
        Withdrawal History
      </h3>
      <b-table class="table-responsive"
        :fields="fields"
        :items="fundraiserData.withdrawalrequests"
      >
        <template #cell(amount)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>&#8358;{{ formatPrice(data.item.amount) }}</div>
            </div>
          </div>
        </template>
        <template #cell(commission)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>&#8358;{{ formatPrice(data.item.commission) }}</div>
            </div>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="
              data.item.status == 'PENDING'
                ? 'warning'
                : data.item.status == 'REJECTED'
                  ? 'danger'
                  : data.item.status == 'PAID'
                    ? 'success'
                    : null
            "
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(createdAt)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>
      </b-table>
      <hr class="my-3">

      <b-modal
        id="withdrawalModal"
        v-model="modal"
        centered
        hide-footer
        title="Withdraw Funds"
      >
        <div

          class=" d-flex  align-items-center  "
        >
          <!-- <div
            v-if="isEarlyWithdrawal"
            class=" rounded text-white font-weight-bold bg-warning d-flex justify-content-center align-items-center px-1"
            style="height: 40px;"
          >

            Early withdrawal charges of 5% is applicable
          </div>
          <b-button
            variant="success"
            class="ml-1"
            @click="isEarlyWithdrawal = !isEarlyWithdrawal"
          >
            {{ isEarlyWithdrawal ? 'Clear' : 'Apply Early withdrawal charges' }}
          </b-button> -->
        </div>
        <b-form
          class="my-3"
          @submit.prevent="withdrawFunds()"
        >
          <b-form-group
            label="Enter amount to withdraw"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="amount"
              type="number"
              :max="fundraiserData.wallet.balance"
              placeholder=""
            />
          </b-form-group>
          <b-form-checkbox class="mb-3" v-model="isEarlyWithdrawal">Early withdrawal charges (5%)</b-form-checkbox>
          <p
            v-if="amount > fundraiserData.wallet.balance"
            style="font-size: 12px"
            class="text-danger"
          >
            You cannot withdraw an amount greater than the wallet balance
          </p>
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="
              amount > fundraiserData.wallet.balance || loading || !amount
            "
          >
            <b-spinner v-if="loading" />
            <span v-else>Create Withdrawal</span></b-button>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormCheckbox,
  BTable,
  BBadge,
} from 'bootstrap-vue'
// import { useApi } from '@core/comp-functions/useApi'

import {
  createWithdrawal,
  getFundDetails,
  manualWithdrawal,
  updateAutoWithdrawalApproval,
} from '@/service/api.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BFormCheckbox,
    BTable,
    BBadge,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    fundraiserId: {
      type: String,
    },
  },
  data() {
    return {
      fundraiserData: null,
      isEarlyWithdrawal: false,
      amount: '',
      loading: false,
      modal: false,
      isManualWithdrawal: false,
      autoWithdrawalApproval: false,
      fields: [
        { key: 'fundraiserId', label: 'Fundraiser ID' },
        { key: 'transactionType', label: 'Transaction Type' },
        { key: 'amountRequested', label: 'Amount Requested' },
        { key: 'amount', label: 'Amount Paid' },
        { key: 'commission', label: 'Commission' },
        { key: 'earlyWithdrawalCommission', label: 'Early Withdrawal Comm' },
        { key: 'balanceBeforeWithdrawal', label: 'Balance before withdrawal' },
        { key: 'balanceAfterWithdrawal', label: 'Balance after withdrawal' },
        { key: 'createdAt', label: 'Date' },
        { key: 'status', label: 'Status' },
      ],
    }
  },
  async created() {
    await this.getFundraiserDetails()
  },
  methods: {
    dayjs,
    getFundraiserDetails() {
      this.loading = true
      // eslint-disable-next-line no-undef
      getFundDetails(this.fundraiserId)
        .then(res => res.json())
        .then(response => {
          this.loading = false
          if (response.valid) {
            this.fundraiserData = response.data
            this.isManualWithdrawal = this.fundraiserData.manualWithdrawal
            this.autoWithdrawalApproval = this.fundraiserData.autoWithdrawalApproval

            const nextPayoutDate = new Date(response.data.nextPayoutDate)
            const today = new Date()
            if (today < nextPayoutDate) {
              this.isEarlyWithdrawal = true
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    withdrawFunds() {
      this.loading = true
      createWithdrawal({
        amount: this.amount,
        fundraiserId: this.fundraiserData.id,
        userId: this.fundraiserData.userId,
        isEarlyWithdrawal: this.isEarlyWithdrawal,
      })
        .then(res => res.json())
        .then(response => {
          this.loading = false
          this.modal = false
          this.amount = ''
          if (response.valid) {
            this.showToast(
              'Success',
              'success',
              'Withdrawal request created successfully!',
            )
          }
        })
        .catch(e => {
          this.loading = false
          this.showToast('Error', 'danger', e)
        })
    },
    setManualWithdrawal() {
      manualWithdrawal({
        isManualWithdrawal: this.isManualWithdrawal,
        id: this.fundraiserData.id,
      })
        .then(res => res.json())
        .then(response => {
          if (response.valid) {
            this.showToast('Success', 'success', response.message)
          }
        })
        .catch(e => {
          this.loading = false
          this.showToast('An error occured', 'danger', e)
        })
    },

    setAutoWithdrawalApproval() {
      updateAutoWithdrawalApproval({
        autoWithdrawalApproval: this.autoWithdrawalApproval,
        id: this.fundraiserData.id,
      })
        .then(res => res.json())
        .then(response => {
          if (response.valid) {
            this.showToast('Success', 'success', response.message)
          }
        })
        .catch(e => {
          this.loading = false
          this.showToast('An error occured', 'danger', e)
        })
    },

    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: title === 'Error' ? 'TrashIcon' : 'DoneIcon',
          variant,
        },
      })
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style  lang="scss">
.balance-card {
  height: 140px;
  // width: 340px;
  border: 1px solid #ddd;
  // background-color: #7367f0;
}
</style>
