<template>
  <div>
    <b-card title="Row details support" no-body v-if="!isView">
      <div style="padding: 10px 20px">
        <div style="font-weight: 500; font-size: 18px;">
          Messages
        </div>
      </div>
      <div class="d-flex align-content-center justify-content-between w-100">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-prepend>
          <b-form-input :value="searchQuery" placeholder="Search messages" />
        </b-input-group>
      </div>

      <!-- App Action Bar -->
      <div
        class="d-flex"
        style="justify-content: space-between; padding: 10px 20px; border-bottom: 1px solid #22292f20"
      >
        <div class="app-action">
          <div class="action-left">
            <b-form-checkbox :checked="false">
              Select All
            </b-form-checkbox>
          </div>
        </div>
        <div>
          <feather-icon
            icon="FolderIcon"
            size="17"
            class=""
            @click.stop="toggleStarred(email)"
          />
          <feather-icon
            icon="TagIcon"
            size="17"
            class="ml-1"
            @click.stop="toggleStarred(email)"
          />
          <feather-icon
            icon="MailIcon"
            size="17"
            class="ml-1"
            @click.stop="toggleStarred(email)"
          />
          <feather-icon
            icon="Trash2Icon"
            size="17"
            class="ml-1"
            @click.stop="toggleStarred(email)"
          />
        </div>
      </div>

      <b-list-group flush striped>
        <b-list-group-item
          @click="openMessage(message, index)"
          style="cursor: pointer"
          v-for="(message, index) in messages"
          :key="index"
        >
          <b-row>
            <b-col>
              <div class="d-flex">
                <div>
                  <b-avatar
                    :text="
                      getSender(message)
                        .charAt(0)
                        .toUpperCase()
                    "
                    variant="light-primary"
                    style="margin-top: 4px"
                  />
                </div>
                <div class="ml-1">
                  <div style="color:#5E5873; font-weight: 500; font-size: 15px">
                    {{ getSender(message) }}
                  </div>
                  <div>{{ getSubject(message) }}</div>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="font-small-2 text-muted float-right">
                {{
                  dayjs(Object.values(message)[0].createdAt).format("h:mm a")
                }}
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-top: 8px">
            <b-col>
              <div class="d-flex">
                <b-form-checkbox :checked="false"> </b-form-checkbox>
                <feather-icon
                  icon="StarIcon"
                  size="17"
                  class="text-muted"
                  @click.stop="toggleStarred(email)"
                />
                <div
                  class="font-small text-muted"
                  style="margin-top: -1px; margin-left: 6px"
                >
                  {{ getMessage(message) }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <view-message
      v-if="isView"
      @back="isView = false"
      :messageData="messageData"
      :threadId="threadId"
    ></view-message>
    <b-modal id="modal-center" centered hide-footer hide-header>
      <div style="padding: 30px; text-align: center;">
        <div style="font-size: 24px; font-weight: 500">User ID</div>
        <div
          style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px;"
        >
          Uploaded User ID
        </div>
        <b-img
          :src="imageURL"
          fluid
          alt="Responsive image"
          style="width: 369px; height: 222px;"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BListGroup,
  BListGroupItem,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import ViewMessage from "./ViewMessage.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { getMessagesByUser } from "../../service/api.service";

export default {
  components: {
    BTable,
    // BButton,
    BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BListGroup,
    BListGroupItem,
    BInputGroupPrepend,
    BInputGroup,
    ViewMessage,
  },
  data() {
    return {
      isView: false,
      response: null,
      messages: [],
      userId: null,
      messageData: "",
      searchQuery: "",
      offset: 0,
      limit: 50,
      threadId: null,
      threads: [],
      perPage: 10,
      subjects: {
        1: {
          id: 1,
          description: "Welcome",
        },
        2: {
          id: 2,
          description: "Withdrawal Issue",
        },
        3: {
          id: 3,
          description: "Donation Issue",
        },
        4: {
          id: 4,
          description: "Fundraiser Issue",
        },
        5: {
          id: 5,
          description: "Profile Issue",
        },
        6: {
          id: 6,
          description: "Technical Issue",
        },
        7: {
          id: 7,
          description: "Suggestion",
        },
        8: {
          id: 8,
          description: "Other",
        },
      },
      imageURL: "",
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      catoptions: [
        { value: "Standard", text: "Standard" },
        { value: "VIP", text: "VIP" },
        { value: "Charity", text: "Charity" }
      ],
      fields: [
        { key: "fundraiser", label: "Fundraiser" },
        { key: "raisedAmount", label: "Raised" },
        { key: "targetAmount", label: "Target" },
      ],
      status: [
        {
          ABSENT: "light-primary",
          PAID: "light-success",
          PROLIFICFUNDRAISER: "light-danger",
          INITIATED: "light-warning",
          6: "light-info",
        },
      ],
    };
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  async created() {
    this.userId = this.$route.params.userId;
    await this.getMessages();
  },
  methods: {
    dayjs,
    getDescription(str = "") {
      return str && str.length > 80 ? str.substring(0, 80) + "..." : str;
    },
    getFormattedAmount(amount) {
      return amount.toLocaleString();
    },
    openMessage(data, index) {
      this.messageData = data;
      this.threadId = this.threads[index];
      this.isView = true;
    },
    getMessages() {
      getMessagesByUser(this.userId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.threads = Object.keys(response.data);
            this.messages = Object.values(response.data);
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    getSubject(message) {
      const subjectId = Object.keys(message)[0];
      const subject = this.subjects[`${subjectId}`];
      return subject.description;
    },
    getMessage(message) {
      const obj = Object.values(message)[0];
      return obj.message;
    },
    getSender(message) {
      const obj = Object.values(message)[0];
      return obj ? obj.sender === 2 ? "User" : "Admin" : '';
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}
</style>
