/* eslint-disable */
import jwtDefaultConfig from './jwtDefaultConfig'
import jwt from 'jsonwebtoken'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  // isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  // subscribers = []

  constructor(axiosIns, jwtOverrideConfig, setAuthUser) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    this.setAuthUser = setAuthUser
    const token = this.getToken()
    if (token) setAuthUser(jwt.decode(token))

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        if (!response?.data?.valid) throw response

        if (response.data.data?.token) {
          this.setToken(response.data.data.token)
          setAuthUser(jwt.decode(response.data.data.token))
        }

        return response.data
      },

      error => {
        if (error.response?.status === 401) {
          this.logout()
        } else if (error.response?.status === 500) {
          // !Alert user of server error
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(error?.response || { data: error })
      },
    )
  }

  // onAccessTokenFetched(accessToken) {
  //   this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  // }

  // addSubscriber(callback) {
  //   this.subscribers.push(callback)
  // }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  removeAuth() {
    localStorage.removeItem('auth')
  }

  // getRefreshToken() {
  //   return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  // }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  // setRefreshToken(value) {
  //   localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  // }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  logout() {
    this.removeToken()
    this.setAuthUser(null)
    setTimeout(() => {
      window.href = '/login'
    }, 0)
  }

  // register(...args) {
  //   return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  // }

  // refreshToken() {
  //   return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
  //     refreshToken: this.getRefreshToken(),
  //   })
  // }
}
