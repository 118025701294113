<template>
  <b-modal
    id="edit-fundraiser-overview"
    ref="edit-fundraiser-overview"
    centered
    hide-footer
  >

    <image-cropper
      v-if="openCoverCropper"
      :open="openCoverCropper"
      :image="image"
      :aspect-ratio="3/1"
      @add="addCoverImage($event)"
      @close="close"
    />

    <image-cropper
      v-else-if="openStoryCropper"
      :open="openStoryCropper"
      :image="image"
      :aspect-ratio="3/2"
      @add="addStoryImage($event)"
      @close="close"
    />

    <image-cropper
      v-else-if="openTeamCropper"
      :open="openTeamCropper"
      :image="image"
      :aspect-ratio="1"
      @add="addTeamMemberImage($event)"
      @close="close"
    />
    <form
      v-else
      @submit.prevent="onSubmit"
    >
      <div style="padding: 25px">
        <b-row>
          <b-col cols="12">
            <div>
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  :disabled="fundraiserType == 'PERMANENT'"
                  placeholder="Title"
                />
              </b-form-group>
            </div>
            <div
              v-if="fundraiserType == 'PERMANENT'"
              class="mb-2"
            >
              <label for="message">Change Cover Photo</label>
              <div style="text-align: center">
                <form id="fileInputForm">
                  <input
                    id="fileCoverInput"
                    type="file"
                    style="display: none"
                    @change="onCoverFileSelected"
                  >
                </form>
                <label
                  style="border: 2px dashed #7666F8; width: 100%; height: 69px"
                  for="fileCoverInput"
                >
                  <div
                    class="mt-2"
                    style="color: #7666F8;"
                  >
                    Click here to upload image
                  </div>
                </label>
              </div>
              <b-row v-if="coverImage">
                <b-col

                  cols="6"
                  class="mt-1"
                >
                  <div
                    class=""
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                  >
                    <div style="text-align: center; width: 100%">
                      <img
                        :src="coverImage"
                        style="width: 100%"
                      >
                    </div>
                    <div
                      style="text-align: right"
                      class="p-1 font-small"
                    >
                      <b-button
                        variant="outline-primary"
                        class="ml-1"
                        style="padding: 8px"
                        @click="removeCoverImage"
                      >Remove</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="mt-2">
              <label for="message">Description</label>
              <ckeditor
                v-model="fundraiserDesc"
              />
            </div>
          </b-col>
          <b-col cols="6">
            <div class="mt-2">
              <b-form-group
                label="Category"
                label-for="categoryId"
              >
                <b-form-select
                  id="categoryId"
                  v-model="categoryId"
                  :options="fundcatoptions"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="mt-2">
              <b-form-group
                label="Target Amount"
                label-for="targetAmount"
              >
                <b-form-input
                  id="targetAmount"
                  v-model="targetAmount"
                  placeholder="Target Amount"
                  type="number"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Duration"
              label-for="duration"
            >
              <b-form-input
                id="duration"
                v-model="duration"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <div class="">
              <b-form-group
                label="Status"
                label-for="status"
              >
                <b-form-select
                  id="status"
                  v-model="status"
                  :options="statuses"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col
            v-if="fundraiserType == 'PERMANENT'"
            cols="12"
            class="mt-1"
          >
            <b-form-group
              label="Add Contact Information"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="contactAddress"
                placeholder="Address"
              />
              <b-form-input
                id="email"
                v-model="contactEmail"
                placeholder="Email"
                class="mt-1"
              />
              <b-form-input
                id="phone"
                v-model="contactPhoneNumber"
                placeholder="Phone Number"
                class="mt-1"
              />

            </b-form-group>
          </b-col>

          <b-col
            v-if="fundraiserType == 'PERMANENT'"
            cols="12"
            class="mt-1"
          >
            <b-form-group
              label="Impact Story (Optional)"
            >
              <b-form-input
                id="title"
                v-model="impactStoryTitle"
                placeholder="Title"
              />
              <b-form-file
                v-model="impactStoryFile"
                placeholder="Add an image for the impact story"
                type="file"
                class="mt-1"
                @change="onStoryFileSelected"
              />

              <b-row v-if="impactStoryImage">
                <b-col

                  cols="6"
                  class="mt-1"
                >
                  <div
                    class=""
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                  >
                    <div style="text-align: center; width: 100%">
                      <img
                        :src="impactStoryImage"
                        style="width: 100%"
                      >
                    </div>
                    <div
                      style="text-align: right"
                      class="p-1 font-small"
                    >
                      <b-button
                        variant="outline-primary"
                        class="ml-1"
                        style="padding: 8px"
                        @click="removeStoryImage"
                      >Remove</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-form-textarea
                id="phone"
                v-model="impactStoryDescription"
                placeholder="Description"
                class="mt-1"
              />

              <b-button
                variant="info"
                class="block float-right mt-1"
                :disabled="!impactStoryTitle || !impactStoryDescription || !impactStoryImage"
                @click="addImpactStory"
              >
                Add Impact Story
              </b-button>

            </b-form-group>
            <b-row
              v-for="(story, index) in impactStories"
              :key="index"
              class="p-1"
              style="border: 1px dashed #ddd; border-radius: 8px;"
            >
              <b-col cols="3">
                <img
                  :src="story.image"
                  alt=""
                  style="width: 100%"
                >
              </b-col>
              <b-col cols="9">
                <feather-icon
                  icon="TrashIcon"
                  color="primary"
                  class="float-right"
                  @click="removeImpactStory(index)"
                />
                <h4>{{ story.title }}</h4>
                <p>{{ story.description }}</p>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            v-if="fundraiserType == 'PERMANENT'"
            cols="12"
            class="mt-1"
          >
            <b-form-group
              label="Team Members (Optional)"
            >
              <b-form-input
                id="name"
                v-model="teamMemberName"
                placeholder="Name"
              />

              <b-form-input
                id="role"
                v-model="teamMemberRole"
                placeholder="Role"
                class="mt-1"
              />
              <b-form-file
                v-model="teamMemberFile"
                placeholder="Add an image of the team member"
                type="file"
                class="mt-1"
                @change="onTeamMemberFileSelected"
              />

              <b-row v-if="teamMemberImage">
                <b-col

                  cols="6"
                  class="mt-1"
                >
                  <div
                    class=""
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                  >
                    <div style="text-align: center; width: 100%">
                      <img
                        :src="teamMemberImage"
                        style="width: 100%"
                      >
                    </div>
                    <div
                      style="text-align: right"
                      class="p-1 font-small"
                    >
                      <b-button
                        variant="outline-primary"
                        class="ml-1"
                        style="padding: 8px"
                        @click="removeTeamMemberImage"
                      >Remove</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-form-textarea
                id="phone"
                v-model="teamMemberDescription"
                placeholder="Description"
                class="mt-1"
              />

              <b-button
                variant="info"
                class="block float-right mt-1"
                :disabled="!teamMemberName || !teamMemberRole || !teamMemberDescription || !teamMemberImage"
                @click="addTeamMember"
              >
                Add Team Member
              </b-button>

            </b-form-group>
            <b-row
              v-for="(member, index) in teamMembers"
              :key="index"
              class="p-1"
              style="border: 1px dashed #ddd; border-radius: 8px;"
            >
              <b-col cols="3">
                <img
                  :src="member.image"
                  alt=""
                  style="width: 100%"
                >
              </b-col>
              <b-col cols="9">
                <feather-icon
                  icon="TrashIcon"
                  color="primary"
                  class="float-right"
                  @click="removeTeamMember(index)"
                />
                <h4>{{ member.name }}</h4>
                <h6>{{ member.role }}</h6>
                <p>{{ member.description }}</p>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            class="mt-1"
          >
            <b-form-group
              label=""
              label-for="allowMakeAWish"
            >
              <b-form-checkbox
                id="allowMakeAWish"
                v-model="allowMakeAWish"
                class=""
              >
                Allow Make-A-Wish
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- <b-col
            cols="12"
            class="mt-1"
          >
            <div
              style="background: #E0F2FF; border-radius: 4px"
              class="p-2"
            >
              <div style="font-size: 14px">
                Monthly Donation
              </div>
              <div
                class="mt-1 text-muted"
                style="font-size: 12px"
              >
                Solicit monthly donations from your donors by specifying
                subscription amounts. E.g. N1,000/Month
              </div>
              <b-form-group
                label="Silver Donor"
                label-for="silver"
                class="mt-1"
              >
                <b-row>
                  <b-col cols="9">
                    <b-form-input
                      id="silver"
                      v-model="silver"
                      placeholder="0"
                      type="number"
                    />
                  </b-col>
                  <b-col cols="3">
                    <div
                      class="mt-1"
                      style="font-weight: 500; font-size: 12px"
                    >
                      Per Month
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                label="Gold Donor"
                label-for="gold"
              >
                <b-row>
                  <b-col cols="9">
                    <b-form-input
                      id="gold"
                      v-model="gold"
                      placeholder="0"
                      type="number"
                    />
                  </b-col>
                  <b-col cols="3">
                    <div
                      class="mt-1"
                      style="font-weight: 500; font-size: 12px"
                    >
                      Per Month
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                label="Diamond Donor"
                label-for="diamond"
              >
                <b-row>
                  <b-col cols="9">
                    <b-form-input
                      id="diamond"
                      v-model="diamond"
                      placeholder="0"
                      type="number"
                    />
                  </b-col>
                  <b-col cols="3">
                    <div
                      class="mt-1"
                      style="font-weight: 500; font-size: 12px"
                    >
                      Per Month
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col> -->
        </b-row>

        <div class="mb-0 text-center mt-2">
          <!-- basic -->
          <b-button
            variant="primary"
            type="submit"
          >
            <b-spinner v-if="updating" />
            <span v-else>Update</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click="closeModal"
          >Discard</b-button>
        </div>
      </div>
    </form>

  </b-modal>
</template>

<script>
/* eslint-disable */
import ImageCropper from "@/@core/components/fundraisers/galley/ImageCropperNoModal.vue";
import {
  BTable,
    BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
  BFormFile,
  BSpinner
} from "bootstrap-vue";
import {
  editFundraiser,
  getCategories,
  sendEmail, postAnImage,
} from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    // BButton,
    BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea, ImageCropper,BFormFile,BSpinner,
  },

  data() {
    return {
      fundraiserType: '',
      fundraiserDesc: "",
      title: "",
      targetAmount: null,
      status: null,
      duration: null,
      categoryId: null,
      fundraiserId: null,
      fundraiserType: '',
      imageObject: null,
      coverImage: null,
      openCoverCropper: false,
      image: null,
      gold: '',silver: '', diamond: '',
      impactStories: [],
      teamMembers: [],
      allowMakeAWish: false,
      fundraiserCycle: "",
      updating: false,
      fundcatoptions: [
        // { value: null, text: "Select category" },
        // { value: "1", text: "Entertainment" },
        // { value: "2", text: "Religious" },
        // { value: "6", text: "Education" },
        // { value: "7", text: "Health" },
        // { value: "1", text: "Music" },
        // { value: "2", text: "Religion" },
        // { value: "11", text: "Funeral" },
        // { value: "8", text: "Startups" },
        // { value: "10", text: "Models" },
        // { value: "5", text: "Community Projects" },
      ],
      statuses: [
        "APPROVED",
        "REJECTED",
        "PENDING",
        "EXPIRED",
        "DELETED",
        "HIDDEN",
        "SUSPENDED",
        "ENDED",
      ],
      durations: ["7 days"],

      openStoryCropper: false,
        openTeamCropper: false,
        
        impactStoryDescription: '',
        impactStoryTitle: '',
        impactStoryImage: null,
        impactStoryFile: null,
        
        teamMemberName: '',
        teamMemberRole: '',
        teamMemberImage: null,
        teamMemberFile: null,
        teamMemberDescription: '',
        contactAddress: '', contactEmail: '', contactPhoneNumber: '',
    };
  },

  props: {
    userId: {
      type: String,
    },
    fundraiser: {
      type: Object,
    },
  },

  watch: {
    fundraiser(value) {
        this.duration = value.duration
        this.status = value.status
        this.targetAmount = value.targetAmount
        this.title = value.title
        this.fundraiserType = value.fundraiserType
        this.coverImage = value.coverImage
        this.categoryId = value.category.id
      this.fundraiserDesc = value.fundraiserDesc
      this.fundraiserCycle = value.fundraiserCycle
      this.fundraiserId = value.id
      this.impactStories = value.impactstories
      this.teamMembers = value.teammembers
      this.contactAddress = value.contactAddress
      this.contactEmail = value.contactEmail
      this.contactPhoneNumber = value.contactPhoneNumber
      this.allowMakeAWish = value.allowMakeAWish
      this.fundraiserType = value.fundraiserType

    },
  },

  async created() {
    await this.fetchCategories();
  },

  methods: {
    removeCoverImage() {
        this.coverImage = null
      },
      async onCoverFileSelected(e) {
        const fileObject = e.target.files[0];
        this.imageObject = {
          name: fileObject.name,
          mimetype: fileObject.type,
        };
        this.image = await this.getImageData(e);
        this.openCoverCropper = true;
      },
      async addCoverImage  (image){
        const data = await postAnImage({file: { name, mimetype: 'image/jpeg', base64: image }})
        const response = await data.json()
        this.coverImage = response.data.imageurl
        this.openCoverCropper = false
      },
      async onStoryFileSelected(e) {
        const fileObject = e.target.files[0];
        this.imageObject = {
          name: fileObject.name,
          mimetype: fileObject.type,
        };
        this.image = await this.getImageData(e);
        this.openStoryCropper = true;
      },
      async onTeamMemberFileSelected(e) {
        const fileObject = e.target.files[0];
        this.imageObject = {
          name: fileObject.name,
          mimetype: fileObject.type,
        };
        this.image = await this.getImageData(e);
        this.openTeamCropper = true;
      },
      async  addStoryImage(image){
        const data = await postAnImage({file: { name, mimetype: 'image/jpeg', base64: image }})
        const response = await data.json()
        this.impactStoryImage = response.data.imageurl
        this.openStoryCropper = false
      },
     async addTeamMemberImage(image){
        const data = await postAnImage({file: { name, mimetype: 'image/jpeg', base64: image }})
        const response = await data.json()
        this.teamMemberImage = response.data.imageurl
        this.openTeamCropper = false
      },
      removeStoryImage() {
        this.impactStoryImage = null
      },
      removeTeamMemberImage() {
        this.teamMemberImage = null
      },
      removeImpactStory  (index) {
      if (index > -1) {
        // only splice array when item is found
        this.impactStories.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    removeTeamMember  (index) {
      if (index > -1) {
        // only splice array when item is found
        this.teamMembers.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    addImpactStory() {
        const data = {
            title: this.impactStoryTitle,
            image: this.impactStoryImage,
            description: this.impactStoryDescription
        }
        this.impactStories.push(data)

        this.impactStoryTitle = ''
        this.impactStoryImage = null
        this.impactStoryDescription = ''
      },
      addTeamMember() {
        const data = {
            name: this.teamMemberName,
            image: this.teamMemberImage,
            description: this.teamMemberDescription,
            role: this.teamMemberRole
        }
        this.teamMembers.push(data)

        this.teamMemberDescription = ''
        this.teamMemberImage = null
        this.teamMemberName = ''
        this.teamMemberRole = ''
      },

      getImageData(e) {
        const input = e.target;
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = function() {
            const dataUrl = reader.result;
            resolve(dataUrl);
          };
          reader.readAsDataURL(input.files[0]);
          this.resetFileInput();
        });
      },
      close() {
        this.openCoverCropper = false;
      },
      resetFileInput() {
        const fileInput = document.getElementById("fileInputForm");
        fileInput.reset();
      },
    fetchCategories() {
      getCategories()
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.fundcatoptions = response.data.map((e) => {
              return {
                text: e.categoryname,
                value: e.id,
              };
            });
            console.log("Categories: ", this.fundcatoptions);
          }
        })
        .catch((e) => console.error(e));
    },
    updateFundraiser(payload) {
      this.updating = true
      editFundraiser(this.fundraiserId, payload)
        .then((res) => res.json())
        .then((response) => {
          this.updating = false
          if (response.valid) {
            this.showToast("Success", "success", response.message);
            this.$emit("refresh");
            this.closeModal();
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    onSubmit() {
     
      const payload = {
        fundraiserType: this.fundraiserType,
        title: this.title,
        fundraiserDesc: this.fundraiserDesc,
        fundraiserCycle: this.fundraiserCycle,
        targetAmount: +this.targetAmount,
        categoryId: this.categoryId,
        duration: this.duration,
        coverImage: this.coverImage,
        teamMembers: this.teamMembers,
        impactStories: this.impactStories,
        contactAddress: this.contactAddress,
        contactEmail: this.contactEmail,
        contactPhoneNumber: this.contactPhoneNumber,
        allowMakeAWish: this.allowMakeAWish,
        status: this.status,
      };
      this.updateFundraiser(payload);
    },
    closeModal() {
      this.$nextTick(() => {
        this.$refs["edit-fundraiser-overview"].toggle("#toggle-btn");
      });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    onChange(value, id) {
      this[id] = value;
    },
  },
};
</script>

<style scoped></style>
