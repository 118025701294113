<template>
  <div class="mt-2">
    <div style="font-size: 18px; font-weight: 500" class="mt-2">
      Blessors ({{ blessors.length }})
    </div>
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col
            cols="6"
            v-for="(obj, index) in blessors"
            :key="index"
            class="mt-2"
          >
            <div style="display: flex">
              <b-avatar
                :src="obj.userdetail.profilePhoto"
                variant="light-danger"
                v-if="
                  obj.userdetail &&
                    obj.userdetail.account.accounttype === 'CORPORATE'
                "
              />
              <b-avatar
                :text="getAvatarText(obj)"
                variant="light-danger"
                v-else
              />
              <div class="ml-1">
                <div
                  v-if="
                    obj.userdetail &&
                      obj.userdetail.account.accounttype === 'CORPORATE'
                  "
                >
                  {{ obj.userdetail.username }}
                </div>
                <div v-else>{{ obj.firstname }} {{ obj.lastname }}</div>
                <div class="font-small-2">
                  <span style="font-weight: bold; color: #000"
                    >&#8358;{{ getFormattedAmount(obj.amount) }}</span
                  >
                  <span class="text-muted font-small-2 ml-1">{{
                    dayjs().to(obj.createdAt)
                  }}</span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import { fetchBlessors, getFundUpdates } from "@/service/api.service";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
const flatObject = (object) => {
  let result = {};

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key]?.constructor === Object) {
        result = { ...result, ...flatObject(object[key]) };
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
};

export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      fundraiserId: null,
      blessors: [],
      members: [
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Creator",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Beneficiary",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Observer",
        },
        {
          firstname: "Fawale",
          lastname: "Gbolahan",
          title: "Observer",
        },
      ],
    };
  },
  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getFundraiserBlessors();
  },
  methods: {
    dayjs,
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    getFundraiserBlessors() {
      fetchBlessors(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.blessors = response.data;
          }
        })
        .catch((e) => console.error(e));
    },
    getFormattedAmount(amount) {
      return amount.toLocaleString();
    },
  },
};
</script>

<style scoped></style>
