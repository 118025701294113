<template>
  <b-card
    body-class="pb-50"
  >
    <h6>Logged in Today</h6>
    <div
      v-if="response.isLoading"
      class="d-flex align-items-center justify-content-center my-3"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="40"
      />
    </div>
    <template v-else>
      <h2 class="font-weight-bolder mb-1">
        {{ formatNumber(response.data.loggedInToday) }}
      </h2>
      <!-- chart -->
      <vue-apex-charts
        height="70"
        :options="statisticsOrder.chartOptions"
        :series="series"
      />
    </template>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useApi } from '@core/comp-functions/useApi'
import { formatNumber } from '@core/utils/utils'

const [res, apiRequest] = useApi('loggedInToday')

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      response: res,
      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '5%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor],
                backgroundBarRadius: 2,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  computed: {
    series() {
      const { data } = this.response
      const list = []
      if (data) list.push(data.loggedInToday)
      return [{ data: list }]
    },
  },
  watch: {
    'response.isFailure': function (isFailure) {
      if (isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: this.response.error || 'Failed to fetch resource',
            icon: 'TrashIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    apiRequest()
  },
  methods: {
    formatNumber,
  },
}
</script>
