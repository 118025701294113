<template>
  <div>
    <b-row>
      <b-col cols="9">
        <b-tabs>
          <b-tab active>
            <template #title>
              <span>Overview</span>
            </template>

            <overview />
          </b-tab>
          <b-tab>
            <template #title>
              <span>Gallery</span>
            </template>

            <gallery />
          </b-tab>
          <b-tab>
            <template #title>
              <span>Transactions</span>
            </template>

            <transactions :fundraiser-id="fundraiserId" />
          </b-tab>

          <b-tab>
            <template #title>
              <span>Withdrawals</span>
            </template>

            <withdrawals :fundraiser-id="fundraiserId" />
          </b-tab>
          <b-tab>
            <template #title>
              <span>Payment methods</span>
            </template>
            <payment-methods :fundraiser-id="fundraiserId" />
          </b-tab>
          <b-tab>
            <template #title>
              <span>Members</span>
            </template>

            <members />
          </b-tab>

          <b-tab>
            <template #title>
              <span>Blessors</span>
            </template>

            <blessors />
          </b-tab>

          <b-tab>
            <template #title>
              <span>Rewards</span>
            </template>

            <rewards />
          </b-tab>

          <b-tab>
            <template #title>
              <span>Comments</span>
            </template>

            <comments />
          </b-tab>

          <b-tab>
            <template #title>
              <span>Flags</span>
            </template>

            <flags />
          </b-tab>

          <b-tab>
            <template #title>
              <span>Appreciation Email</span>
            </template>

            <appreciation-email />
          </b-tab>

          <b-tab>
            <template #title>
              <span>Customization</span>
            </template>

            <customization :fundraiserId="fundraiserId" />
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col cols="3">
        <b-card class="mt-4">
          <div>
            <b-button
              v-b-modal.action-modal-2
              variant="outline-secondary"
              class="w-full mt-1"
              @click="setAction('hide')"
            >
              Hide
            </b-button>
          </div>
          <div>
            <b-button
              v-b-modal.action-modal-2
              variant="outline-secondary"
              class="w-full mt-1"
              @click="setAction('suspend')"
            >
              Suspend
            </b-button>
          </div>
          <div>
            <b-button
              v-b-modal.action-modal-2
              variant="outline-secondary"
              class="w-full mt-1"
              @click="setAction('delete')"
            >
              Delete
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <action-modal-2 :action="action" @action="actOnFundraiser($event)" />
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTabs,
  BTab,
  BCardText,
  BButton,
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue";
import Overview from "../../views/fundraisers/details/Overview.vue";
import ActionModal2 from "@/@core/components/fundraisers/modals/ActionModal2.vue";
import { setFundraiserStatus } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Gallery from "./details/Gallery.vue";
import Transactions from "./details/Transactions.vue";
import Withdrawals from "./details/Withdrawals.vue";
import Rewards from "./details/Rewards.vue";
import Comments from "./details/Comments.vue";
import Blessors from "./details/Blessors.vue";
import Flags from "./details/Flags.vue";
import Members from "./details/Members.vue";
import PaymentMethods from "./details/PaymentMethods.vue";
import AppreciationEmail from "./details/AppreciationEmail.vue";
import Customization from "../../views/fundraisers/details/Customization.vue";

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    Overview,
    BButton,
    BCard,
    BRow,
    BCol,
    ActionModal2,
    Gallery,
    Transactions,
    Withdrawals,
    Rewards,
    Comments,
    Blessors,
    Flags,
    Members,
    PaymentMethods,
    AppreciationEmail,
    Customization,
  },
  data() {
    return {
      action: null,
      fundraiserId: null,
    };
  },
  created() {
    this.fundraiserId = this.$route.params.fundraiserId;
  },
  methods: {
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    actOnFundraiser(type) {
      setFundraiserStatus(this.fundraiserId, type)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast("Success", "success", response.message);
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    setAction(type) {
      this.action = type;
    },
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}
</style>
