<template>
  <div>
    <b-card>
      <app-collapse accordion>
        <app-collapse-item title="Search & Filter">
          <!-- {{ filters }} -->
          <form @submit="onSubmit">
            <b-row>
              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group
                  label="Fundraiser Title"
                  label-for="fundraiserTitle"
                >
                  <b-form-input
                    id="fundraiserTitle"
                    v-model="filters.title"
                    placeholder="Fundraiser Title"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <!-- <b-form-group label="Organiser" label-for="organiser">
                  <b-form-input
                    id="organiser"
                    v-model="filters.organiser"
                    placeholder="Organiser"
                  />
                </b-form-group> -->
                <autocomplete @itemSelected="selectHandler"></autocomplete>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group label="User Category" label-for="category">
                  <b-form-select
                    v-model="filters.userCategory"
                    :options="usercatoptions"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group label="Fundraiser ID" label-for="fundraiserId">
                  <b-form-input
                    id="fundraiserId"
                    v-model="filters.id"
                    placeholder="Fundraiser ID"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group
                  label="Fundraiser Category"
                  label-for="fundraiserCategory"
                >
                  <b-form-select
                    id="fundraiserCategory"
                    v-model="filters.categoryId"
                    :options="fundcatoptions"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group
                  label="Fundraiser Type"
                  label-for="fundraiserType"
                >
                  <b-form-select
                    id="fundraiserType"
                    v-model="filters.fundraiserType"
                    :options="fundtypeoptions"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group
                  label="Fundraiser Cycle"
                  label-for="fundraiserCycle"
                >
                  <b-form-select
                    id="fundraiserCycle"
                    v-model="filters.fundraiserCycle"
                    :options="fundcycleoptions"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group label="Target Amount" label-for="targetamount">
                  <b-form-input
                    id="targetamount"
                    v-model="filters.targetamount"
                    placeholder="Target Amount"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group label="Duration" label-for="duration">
                  <b-form-select
                    v-model="filters.duration"
                    :options="durationoptions"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group label="Creation Period" label-for="startdate">
                  <date-range-picker
                    v-model="filters.dateRange"
                    style="width: 100%"
                    :start-date="startDate"
                    :end-date="endDate"
                    :locale-data="locale"
                    @update="setDateRange"
                  >
                    <!--Optional scope for the input displaying the dates -->

                    <template v-slot:input="picker" style="min-width: 350px">
                      {{
                        new Date(picker.startDate)
                          .toISOString()
                          .substring(0, 10)
                      }}
                      -
                      {{
                        new Date(picker.endDate).toISOString().substring(0, 10)
                      }}
                    </template>
                  </date-range-picker>
                </b-form-group>
              </b-col>

              <!-- <b-col
                md="6"
                xl="4"
                class="mb-0"
              >

                <b-form-group
                  label="End Date"
                  label-for="enddate"
                >
                  <b-form-input
                    id="enddate"
                    v-model="filters.enddate"
                    placeholder="End Date"
                    type="date"
                  />
                </b-form-group>
              </b-col> -->

              <b-col md="6" xl="4" class="mb-0">
                <!-- basic -->
                <b-form-group label="Status" label-for="status">
                  <b-form-select v-model="filters.status" :options="statuses" />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="" style="text-align: right">
              <b-button type="submit" variant="primary"> Filter </b-button>
              <b-button variant="outline-secondary" class="ml-1" @click="reset"
                >Reset</b-button
              >
            </div>
          </form>
        </app-collapse-item>
      </app-collapse>

      <!-- <div style="font-weight: 500; font-size: 18px; margin-bottom: 10px;">
        Search & Filter
      </div> -->
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import dayjs from "dayjs";
import Autocomplete from "@/@core/components/common/Autocomplete.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    DateRangePicker,
    Autocomplete
  },
  data() {
    return {
      daterange: "",
      startDate: new Date ("2022-01-01"),
      endDate: new Date("2022-12-31"),
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      usercatoptions: [
        { value: null, text: "Select category" },
        { value: "STANDARD", text: "Standard" },
        { value: "VIP", text: "VIP" },
        { text: "Charity", value: "Charity" },
      ],
      fundcatoptions: [
        { value: null, text: "Select category" },
        { value: "3", text: "Wedding" },
        { value: "4", text: "Charity" },
        { value: "6", text: "Education" },
        { value: "7", text: "Health" },
        { value: "1", text: "Music" },
        { value: "2", text: "Religion" },
        { value: "11", text: "Funeral" },
        { value: "8", text: "Startups" },
        { value: "10", text: "Models" },
        { value: "5", text: "Community Projects" },
      ],
      fundtypeoptions: [
        { value: null, text: "Select category" },
        { value: "ONEOFF", text: "One-Off" },
        { value: "RECURRING", text: "Recurring" },
      ],
      durationoptions: [
        { value: null, text: "Select category" },
        { value: "7", text: "7" },
        { value: "30", text: "30" },
        { value: "90", text: "90" },
        { value: "180", text: "180" },
        { value: "365", text: "365" },
      ],
      fundcycleoptions: [
        { value: null, text: "Select category" },
       { value: "WEEKLY", text: "Weekly" },
        { value: "MONTHLY", text: "Monthly" },
        { value: "QUARTERLY", text: "Quarterly" },
      ],
      statuses: [
        { value: null, text: "Select status" },
        { value: "APPROVED", text: "Approved" },
        { value: "PENDING", text: "Pending" },
        { value: "HIDDEN", text: "Hidden" },
        { value: "EXPIRED", text: "Expired" },
        { value: "SUSPENDED", text: "Suspended" },
        { value: "REJECTED", text: "Rejected" },
      ],
      filters: {
        title: "",
        userId: "",
        userCategory: "",
        categoryId: "",
        fundraiserType: "",
        fundraiserCycle: "",
        startDate: "",
        endDate: "",
        dateRange: {},
        id: "",
        targetamount: "",
        duration: "",
        status: "",
      },
    };
  },
  methods: {
    dayjs,
    onSubmit(e) {
      e.preventDefault();
      const filterKeys = Object.keys(this.filters);
      let query = {};
      Object.values(this.filters).forEach((filter, index) => {
        if (filter.length && filter !== null) {
          query = { ...query, [filterKeys[index]]: filter };
        }
      });
      this.$emit("filter", query);
    },
    reset() {
      const filterKeys = Object.keys(this.filters);
      let filters = {};
      Object.values(this.filters).forEach((filter, index) => {
        if (filter.length && filter !== null) {
          filters = { ...filters, [filterKeys[index]]: "" };
        }
      });
      this.filters = filters;
      this.$emit("reset");
    },
    setDateRange(value) {
      this.filters.startDate = new Date(value.startDate).toISOString().substring(0,10)
      this.filters.endDate = new Date(value.endDate).toISOString().substring(0,10)
      
    },
    selectHandler(data) {
      this.filters.userId = data.userid.toString()
    },
  },
};
</script>

<style scoped></style>
