<template>
  <b-modal id="push-notifications" centered hide-footer>
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Send Push Notifications
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Please use the form below to send the user a push notfication message
      </div>
      <div>
        <label for="textarea-default">Message</label>
        <b-form-textarea
          id="textarea-default"
          placeholder="Type message here"
          rows="4"
        />
      </div>

      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button variant="primary">Send Push Notification</b-button>
        <b-button variant="outline-secondary" class="ml-1">Discard</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea
  },

  data() {
    return {
      categories: [{ text: "Standard", value: "STANDARD" }],
    };
  },
};
</script>

<style scoped></style>
