<template>
  <div>
    <transactions-filter
      @filter="filterTable($event)"
      @reset="resetTable"
    ></transactions-filter>
    <b-card title="Row details support" no-body>
      <div style="padding: 20px">
        <b-row>
          <b-col cols="7">
            <div style="display: flex;">
              <span style="margin-top: 7px">Show</span>
              <div style="margin-left: 15px">
                <b-form-select
                  v-model="limit"
                  :options="options"
                  @change="setPerPage"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div style="display: flex;">
              <span style="margin-top: 7px">Search</span>
              <div style="margin-left: 15px; width: 100%">
                <b-form-input
                  id="search" v-model="filter"
                  placeholder="Search"
                  style="width: 100%"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="outline-primary"
              style="width: 100%; color: #7367f0"
            >
              <feather-icon icon="ExternalLinkIcon" size="16" class="mr-1" />
              Export
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center h-100 mt-16"
        style="margin-top: 4rem"
      >
        <feather-icon icon="LoaderIcon" class="spinner" size="60" />
      </div>
      <b-table
        :items="response.data"
        :fields="fields"
        responsive :filter="filter"
        class="mb-0"
        v-else
      >
        <template #cell(date)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(blessing)="data">
          <!-- <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div> -->
          <span>
            {{ data.item.blessing.firstname }} {{ data.item.blessing.lastname }}
          </span>
        </template>

        <template #cell(name)="data">
          <div
            style="display: flex"
            v-if="data.item.userdetail"
            class="cursor-pointer"
            @click="routeToViewUser(data.item.userdetail.userid)"
          >
            <b-avatar
              :text="getAvatarText(data.item.userdetail)"
              variant="light-danger"
            />
            <div style="margin-left: 12px;">
              <div>
                {{ data.item.userdetail.firstname }}
                {{ data.item.userdetail.lastname }}
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.userdetail.email }}
              </div>
            </div>
          </div>
          <div v-else>-</div>
        </template>

        <template #cell(idFile)="data">
          <div
            class="file"
            v-if="data.value"
            @click="viewImage(data)"
            v-b-modal.modal-center
          >
            <img class="file-img" :src="data.value" />
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[0][data.value]">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(transactionType)="data">
          <div :style="'color:' + status[0][data.value]">
            {{ data.value }}
          </div>
        </template>

        <template #cell(amount)="data">
          <div>{{ getFormattedAmount(data.item) }}</div>
        </template>

        <template #cell(actions)="data">
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="text-body align-middle mr-25"
            @click="setTransactionDetails(data)"
            style="cursor: pointer"
            v-b-modal.transaction-details
          />
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle mr-25"
            @click="openEdit(data)"
            style="margin-left: 10px; cursor: pointer"
          />
        </template>
      </b-table>
      <div
        style="padding: 20px; display: flex; justify-content: space-between;"
      >
        <div style="color: #B9B9C3;">
          Showing {{ getEntryNumber }} to {{ getEntriesCount }} of
          {{ response.metadata ? response.metadata.totalRecords : 0 }} entries
        </div>
        <b-pagination
          v-model="currentPage"
          @change="handlePaginationChange"
          :total-rows="response.metadata ? response.metadata.totalRecords : 0"
          :per-page="limit"
          align="right"
          size="sm"
          class="my-0"
        />
      </div>
    </b-card>
    <transaction-details-modal
      :data="transactionDetails"
    ></transaction-details-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import TransactionsFilter from "@/@core/components/filters/TransactionsFilter.vue";
import TransactionDetailsModal from "@/@core/components/transactions/modals/TransactionDetailsModal.vue";

const [res, apiRequest] = useApi("transactions");

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    TransactionsFilter,
    TransactionDetailsModal,
  },
  data() {
    return {
      response: res,
      currentPage: 1,
      offset: 0,
      limit: 50,
      perPage: 10,
      filters: {},
      filter: '',
      imageURL: "",
      transactionDetails: null,
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      fields: [
        // 'row_selector',
        "id",
        { key: "name", label: "Name" },
        {key: 'blessing',label: 'Payer'},
        {key: 'fundraiser.title', label: 'fundraiser'},
        {key: 'fundraiser.id', label: 'fundraiser id'},
        "date",
        { key: "transactionType", label: "transaction type" },
        { key: "transactionReference", label: "transaction ref" },
        { key: "amount", label: "amount" },
        { key: "paymentChannel", label: "processor" },
        {key: 'paymentMethod', label: 'payment method'},
        { key: "status", label: "status" },
        "actions",
      ],
      status: [
        {
          INITIATEDD: "light-primary",
          DONATION: "green",
          TOPUP: "green",
          "TOP UP": "green",
          PROMOTION: "red",
          PAID: "light-success",
          WITHDRAWAL: "red",
          PROLIFICFUNDRAISER: "light-danger",
          INITIATED: "light-warning",
        },
      ],
    };
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  watch: {
    "response.isFailure": function(isFailure) {
      if (!isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: this.response.error || "Failed to fetch resource",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    dayjs,
    async filterTable(filters) {
      this.filters = filters;
      await this.getUsers();
    },
    async resetTable() {
      this.filters = {};
      this.currentPage = 1;
      await this.getUsers();
    },
    getUsers(offset = 0) {
      apiRequest({ ...this.filters, offset, limit: this.limit });
    },
    getFormattedAmount(data) {
      return `${
        data.currency ? data.currency : "-"
      } ${data.dollarAmount ? data.dollarAmount.toLocaleString() : data.amount.toLocaleString()}`;
    },
    openEdit(data) {
      console.log("edit ", data);
    },
    setTransactionDetails(data) {
      this.transactionDetails = data.item;
      console.log("trds ", this.transactionDetails);
    },
    viewImage(data) {
      console.log("images ", data);
      this.imageURL = data.item.idFile;
    },
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    setPerPage(e) {
      console.log("valueee ", e);
      this.limit = e;
      this.getUsers(this.offset);
    },
    handlePaginationChange(event) {
      this.offset = (event - 1) * this.limit;
      this.getUsers(this.offset);
    },
    routeToNewUser() {
      this.$router.push(`/users/new-user`);
    },
    routeToViewUser(userId) {
      this.$router.push(`/users/${userId}/view`);
    },
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}

.red {
  background: #fd5f5f;
}
.green {
  background: #017622;
}
</style>
