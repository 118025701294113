<template>
  <main>
    <b-row>
      <b-col cols="3" v-for="(item, index) in cards" :key="index">
        <b-card>
          <div style="text-align: right">
            <feather-icon :icon="item.icon" size="16" />
          </div>
          <div class="mt-1" style="font-weight: bold">{{ item.name }}</div>
          <div
            class="font-small-2"
            style="margin-top: 6px; color: #7367F0; cursor: pointer"
            @click="routeTo(item.path)"
          >
            View
          </div>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
/* eslint-disable */
import { BCard, BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
  },

  data() {
    return {
      cards: [
        { name: "About Us", icon: "SettingsIcon", path: "/settings/about-us" },
        {
          name: "Terms & Conditions",
          icon: "SettingsIcon",
          path: "/settings/terms-conditions",
        },
        {
          name: "Privacy Policy",
          icon: "SettingsIcon",
          path: "/settings/privacy-policy",
        },
        { name: "FAQs", icon: "SettingsIcon", path: "/settings/faqs" },
        {
          name: "Manage Users",
          path: "/settings/manage-users",
          icon: "UserIcon",
        },
        {
          name: "Manage Roles",
          path: "/settings/manage-roles",
          icon: "UserIcon",
        },
        {
          name: "Exchange Rates",
          path: "/settings/exchange-rates",
          icon: "SettingsIcon",
        },
        {
          name: "Withdraw Funds",
          path: "/settings/withdraw-funds",
          icon: "SettingsIcon",
        },
        {
          name: "Charges Settings",
          path: "/settings/charges-settings",
          icon: "DollarSignIcon"
        }
      ],
    };
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped></style>
