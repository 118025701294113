<template>
  <div>
    <b-card class="pb-2">
      <div
        class="pb-1"
        style="border-bottom: 1px solid #EBE9F1; font-size: 18px; font-weight: 500; color: #5E5873"
      >
        Create New Onetime Fundraiser
      </div>
      <div
        style="max-width: 550px; margin: auto;"
        class="mt-3"
      >
        <autocomplete @itemSelected="selectHandler" />
      </div>
      <div
        v-if="userId"
        style="max-width: 600px; margin: auto"
      >
        <form
          id="onetimeform"
          @submit="onSubmit"
        >
          <div style="padding: 25px;">
            <b-row>
              <b-col cols="12">
                <div>
                  <b-form-group
                    label="Title"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="title"
                      maxlength="50"
                      placeholder="Title"
                      required
                    />
                  </b-form-group>
                  <p
                    v-if="title"
                    class="small font-medium text-right"
                    style="color: #fd5f5f"
                  >
                    {{ 50 - title.length }}
                  </p>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <div>
                  <label for="message">Description</label>
                  <!-- <b-form-textarea
                    id="description"
                    placeholder="Enter description here"
                    rows="4"
                    v-model="fundraiserDesc"
                    required
                  /> -->
                  <ckeditor
                    v-model="fundraiserDesc"
                    :config="editorConfig"
                  />
                  <p
                    v-if="fundraiserDesc"
                    class="small font-medium text-right"
                    style="color: #fd5f5f"
                  >
                    {{ 2500 - fundraiserDesc.length }}
                  </p>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Category"
                    label-for="categoryId"
                  >
                    <b-form-select
                      id="categoryId"
                      v-model="categoryId"
                      :options="fundcatoptions"
                      required
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Duration"
                    label-for="duration"
                  >
                    <b-form-select
                      id="duration"
                      v-model="duration"
                      :options="durationoptions"
                      required
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Target Amount"
                    label-for="targetAmount"
                  >
                    <b-form-input
                      id="targetAmount"
                      v-model="targetAmount"
                      placeholder="Target Amount"
                      type="number"
                      required
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="isReservedAccount"
                >
                  <b-form-checkbox
                    id="isReservedAccount"
                    v-model="isReservedAccount"
                    class=""
                  >
                    Set custom account name
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                v-if="isReservedAccount"
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Custom Account Name"
                  label-for="reservedName"
                >
                  <b-form-input
                    id="reservedName"
                    v-model="reservedName"
                    placeholder=""
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <div class="">
                  <label for="message">Add Images</label>
                  <div style="text-align: center">
                    <form id="fileInputForm">
                      <input
                        id="fileInput"
                        type="file"
                        style="display: none"
                        @change="onFileSelected"
                      >
                    </form>
                    <label
                      style="border: 2px dashed #7666F8; width: 100%; height: 129px"
                      for="fileInput"
                    >
                      <div
                        class="mt-4"
                        style="color: #7666F8;"
                      >
                        Click here to upload image
                      </div>
                    </label>
                  </div>
                  <b-row>
                    <b-col
                      v-for="(image, index) in selectedImages"
                      :key="index"
                      cols="6"
                      class="mt-1"
                    >
                      <div
                        class=""
                        style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                      >
                        <div style="text-align: center; width: 100%">
                          <img
                            :src="image"
                            style="width: 100%"
                          >
                        </div>
                        <div
                          style="text-align: right"
                          class="p-1 font-small"
                        >
                          <b-button
                            variant="outline-primary"
                            class="ml-1"
                            style="padding: 8px"
                            @click="removeImage(index)"
                          >Remove</b-button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                v-if="!isOrganiserBeneficiary"
                cols="12"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Name of beneficiary"
                    label-for="fullname"
                  >
                    <b-form-input
                      id="fullname"
                      v-model="fullname"
                      placeholder="Full name"
                    />
                    <b-form-input
                      id="link"
                      v-model="link"
                      class="mt-1"
                      placeholder="https://"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="isOrganiserBeneficiary"
                >
                  <b-form-checkbox
                    id="isOrganiserBeneficiary"
                    v-model="isOrganiserBeneficiary"
                    class=""
                  >
                    Make organiser beneficiary
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <div>
                  <b-form-group
                    label="Add YouTube video link (optional)"
                    label-for="youTubeLink"
                  >
                    <b-form-input
                      id="youTubeLink"
                      v-model="youTubeLink"
                      placeholder="https://"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <autocomplete
                  :title="'Invite Observers'"
                  @itemSelected="selectMemberHandler"
                />
                <div
                  v-for="(obs, index) in selectedMembers"
                  :key="index"
                  class="p-1 mt-1"
                  style="border: 1px solid #ebe9f1; border-radius: 4px"
                >
                  <feather-icon
                    icon="XIcon"
                    size="20"
                    style="cursor: pointer; float: right"
                    @click="removeObserver(index)"
                  />
                  <div style="display: flex">
                    <div>
                      <b-avatar
                        :text="getAvatarText(obs)"
                        variant="light-danger"
                        class="mr-50"
                      />
                    </div>
                    <div class="ml-1">
                      <b-card-text
                        class="mb-0"
                        style="color: #000"
                      >
                        {{ obs.firstname }} {{ obs.lastname }}
                      </b-card-text>
                      <small class="text-muted">@{{ obs.username }}</small>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label="Social Media Links"
                  label-for="twitter"
                >
                  <b-form-input
                    id="twitter"
                    v-model="twitter"
                    placeholder="Twitter"
                  />
                  <b-form-input
                    id="facebook"
                    v-model="facebook"
                    placeholder="Facebook"
                    class="mt-1"
                  />
                  <b-form-input
                    id="instagram"
                    v-model="instagram"
                    placeholder="Instagram"
                    class="mt-1"
                  />
                  <b-form-input
                    id="youtube"
                    v-model="youtube"
                    placeholder="YouTube"
                    class="mt-1"
                  />
                  <b-form-input
                    id="tiktok"
                    v-model="tiktok"
                    placeholder="Tiktok"
                    class="mt-1"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="makePrivate"
                >
                  <b-form-checkbox
                    id="makePrivate"
                    v-model="makePrivate"
                    class=""
                  >
                    Make private
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label=""
                  label-for="turnOnComments"
                >
                  <b-form-checkbox
                    id="turnOnComments"
                    v-model="turnOnComments"
                    class=""
                  >
                    Turn on comments
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="mb-0 text-center mt-2">
              <!-- basic -->
              <b-button
                variant="primary"
                type="submit"
              >Create Fundraiser</b-button>
            </div>
          </div>
        </form>
      </div>
    </b-card>
    <image-cropper
      :open="openCropper"
      :image="image"
      @add="addImage($event)"
      @close="close"
    />
    <response-modal
      :open="openResponseModal"
      @close="close"
    />
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BCardText,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormCheckbox,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import ImageCropper from "@/@core/components/fundraisers/galley/ImageCropper.vue";
import ResponseModal from "@/@core/components/fundraisers/modals/ResponseModal.vue";
import Autocomplete from "@/@core/components/common/Autocomplete.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { createFundraiser, uploadImages } from "@/service/api.service";
export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BFormInput,
    BModal,
    BButton,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BFormCheckbox,
    BPagination,
    ImageCropper,
    Autocomplete,
    ResponseModal,
  },
  data() {
    return {
      userId: null,
      categoryId: null,
      duration: null,
      title: null,
      targetAmount: null,
      fundraiserDesc: null,
      fullname: null,
      youTubeLink: null,
      facebook: null,
      twitter: null,
      instagram: null,
      tiktok: null,
      youtube: null,
      link: null,
      makePrivate: false,
      isOrganiserBeneficiary: false,
      openCropper: false,
      turnOnComments: false,
      openResponseModal: false,
      imageObject: null,
      image: null,
      selectedImages: [],
      filesToUpload: [],
      observers: [],
      selectedMembers: [],
      isReservedAccount: false,
      reservedName: '',
      fundcatoptions: [
        { value: null, text: "Select category" },
        { value: "3", text: "Wedding" },
        { value: "4", text: "Charity" },
        { value: "6", text: "Education" },
        { value: "7", text: "Health" },
        { value: "1", text: "Music" },
        { value: "2", text: "Religion" },
        { value: "11", text: "Funeral" },
        { value: "8", text: "Startups" },
        { value: "10", text: "Models" },
        { value: "5", text: "Community Projects" },
      ],
      durationoptions: [
        { text: null, text: "Select Duration" },
        { text: "7", value: "7" },
        { text: "30", value: "30" },
        { text: "90", value: "90" },
        // { text: "180", value: "180" },
        // { text: "365", value: "365" },
      ],
      editorData: "<p>Type terms and conditions here...</p>",
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  methods: {
    async onFileSelected(e) {
      console.log("E ", e.target.files[0]);
      const fileObject = e.target.files[0];
      this.imageObject = {
        name: fileObject.name,
        mimetype: fileObject.type,
      };
      this.image = await this.getImageData(e);
      this.openCropper = true;
    },
    getImageData(e) {
      const input = e.target;
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = function() {
          const dataUrl = reader.result;
          resolve(dataUrl);
        };
        reader.readAsDataURL(input.files[0]);
        this.resetFileInput();
      });
    },
    resetFileInput() {
      const fileInput = document.getElementById("fileInputForm");
      fileInput.reset();
    },
    addImage(image) {
      this.selectedImages.push(image);
      this.filesToUpload.push({ ...this.imageObject, base64: image });
      this.openCropper = false;
    },
    close() {
      this.openCropper = false;
      this.openResponseModal = false;
     this.resetFileInput()
    },
    removeImage(index) {
      this.selectedImages = this.selectedImages.filter(
        (_, idx) => idx !== index
      );
      this.filesToUpload = this.filesToUpload.filter((_, idx) => idx !== index);
    },
    selectHandler(data) {
      this.userId = data.userid;
    },
    selectMemberHandler(data) {
      this.selectedMembers.push(data);
    },
    removeObserver(index) {
      this.selectedMembers = this.selectedMembers.filter(
        (_, idx) => idx !== index
      );
    },
    onTextChange () {
      alert('Yesss')
    },
    getAvatarText(item) {
      const avatarText = `${item.firstname.charAt(0)} ${item.lastname.charAt(
        0
      )}`;
      return avatarText.toUpperCase();
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    getFundraiserPayload() {
      this.observers = [];
      this.selectedMembers.forEach((member) => {
        this.observers.push({
          memberId: member.userid,
          fullname: "",
          link: "",
          memberType: "OBSERVER",
        });
      });
      let beneficiary = {};
      if (this.fullname && !this.isOrganiserBeneficiary) {
        beneficiary = {
          fullname: this.fullname,
          link: this.link,
          memberId: "",
          memberType: "BENEFICIARY",
        };
      } else if (this.isOrganiserBeneficiary) {
        beneficiary = {
          fullname: "",
          link: "",
          memberId: this.userId,
          memberType: "BENEFICIARY",
        };
      } else {
        this.showToast("Error", "danger", "Please add beneficiary");
        return { payload: null, images: null };
      }
      this.observers = [...this.observers, beneficiary];
      const payload = {
        userId: this.userId,
        title: this.title,
        fundraiserDesc: this.fundraiserDesc,
        categoryId: this.categoryId,
        duration: +this.duration,
        targetAmount: +this.targetAmount,
        makePrivate: this.makePrivate,
        isOrganiserBeneficiary: this.isOrganiserBeneficiary,
        turnOnComments: this.turnOnComments,
        youTubeLink: this.youTubeLink,
        members: this.observers,
        reservedName: this.reservedName,
        fundraiserType: "ONEOFF",
        rewards: [],
        smlinks: [
          { smname: "Twitter", link: this.twitter },
          { smname: "Facebook", link: this.facebook },
          { smname: "Instagram", link: this.instagram },
          { smname: "YouTube", link: this.youtube },
          { smname: "TikTok", link: this.tiktok },
        ],
      };
      console.log("payload", payload, this.filesToUpload);
      if (this.filesToUpload.length >= 1) {
        return { payload, images: this.filesToUpload };
      } else {
        this.showToast("Error", "danger", "Please add at least one image");
        return { payload: null, images: null };
      }
    },
    async onSubmit(e) {
      e.preventDefault();
      const { payload, images } = this.getFundraiserPayload();
      if (payload && images) {
        try {
          const fundraiserResponse = await createFundraiser(payload);
          const response = await fundraiserResponse.json();
          if (response.valid) {
            const fundraiserId = response.data.id;
            const imageUploadResponse = await uploadImages({
              fundraiserId,
              deleteItems: [],
              files: images,
            });
            const resp = await imageUploadResponse.json();
            if (resp.valid) {
              this.resetForm();
              this.openResponseModal = true;
            }
          }
        } catch (e) {
          this.showToast("Error", "danger", `An error occurred: ${e}`);
        }
      }
    },
    resetForm() {
        this.title = null
        this.fundraiserDesc = null
        this.categoryId = null
        this.targetAmount = null
        this.duration = null
        this.youTubeLink = null
        this.userId = null
        this.fullname = null
        this.link = null
        this.youtube = null
        this.twitter = null
        this.facebook = null
        this.instagram = null
        this.tiktok = null
        this.image = null
        this.imageObject = null
        this.selectedMembers = []
        this.selectedImages = []
        this.filesToUpload = []
        this.observers = []
        this.makePrivate = false
        this.isOrganiserBeneficiary = false
        this.turnOnComments = true
    },
    // close() {
    //   this.openResponseModal = false;
    // },
  },
};
</script>

<style scoped></style>
