<template>
  <div>
    <users-filter
      @filter="filterTable($event)"
      @reset="resetTable"
    />
    <b-card
      title="Row details support"
      no-body
    >
      <div style="padding: 20px">
        <b-row>
          <b-col cols="5">
            <div style="display: flex;">
              <span style="margin-top: 7px">Show</span>
              <div style="margin-left: 15px">
                <b-form-select
                  v-model="limit"
                  :options="options"
                  @change="setPerPage"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div style="display: flex;">
              <span style="margin-top: 7px">Search</span>
              <div style="margin-left: 15px; width: 100%">
                <b-form-input
                  id="search"
                  v-model="filter"
                  placeholder="Search"
                  style="width: 100%"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="outline-primary"
              style="width: 100%; color: #7367f0"
            >
              <feather-icon
                icon="ExternalLinkIcon"
                size="16"
                class="mr-1"
              />
              Export
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="primary"
              style="width: 100%"
              @click="routeToNewUser"
            >
              Add New User
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center h-100 mt-16"
        style="margin-top: 4rem"
      >
        <feather-icon
          icon="LoaderIcon"
          class="spinner"
          size="60"
        />
      </div>
      <b-table
        v-else
        :items="response.data"
        :fields="fields"
        responsive
        :filter="filter"
        class="mb-0"
      >
        <template #cell(date)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(name)="data">
          <div
            style="display: flex"
            class="cursor-pointer"
            @click="viewUserDetails(data)"
          >
            <b-avatar
              :text="getAvatarText(data.item)"
              variant="light-danger"
            />
            <div style="margin-left: 12px;">
              <div>{{ data.item.firstname }} {{ data.item.lastname }}</div>
              <div class="font-small-2 text-muted">
                {{ data.item.email }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(idFile)="data">
          <div
            v-if="data.value"
            v-b-modal.modal-center
            class="file"
            @click="viewImage(data)"
          >
            <img
              class="file-img"
              :src="data.value"
            >
          </div>
        </template>

        <template #cell(userCategory)="data">
          <b-badge :variant="status[0][data.value]">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="text-body align-middle mr-25"
            style="cursor: pointer"
            @click="viewUserDetails(data)"
          />
          <feather-icon
            v-b-modal.delete-user-modal
            icon="TrashIcon"
            size="16"
            class="align-middle mr-25"
            style="margin-left: 10px; cursor: pointer"
            color="red"
            @click="userId = data.item.account.id"
          />
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle mr-25"
            style="margin-left: 10px; cursor: pointer"
            @click="openEdit(data)"
          />
        </template>
        <template #cell(verificationStatus)="data">
          <b-badge :variant="verstatus[0][data.value]">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(account.isEmailVerified)="data">
          <b-badge :variant="data.value ? 'light-success' : 'light-warning'">
            {{ data.value ? 'EMAIL VERIFIED' : 'EMAIL UNVERIFIED' }}
          </b-badge>
        </template>
      </b-table>
      <div
        style="padding: 20px; display: flex; justify-content: space-between;"
      >
        <div style="color: #B9B9C3;">
          Showing {{ getEntryNumber }} to {{ getEntriesCount }} of
          {{ response.metadata ? response.metadata.totalRecords : 0 }} entries
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="response.metadata ? response.metadata.totalRecords : 0"
          :per-page="limit"
          align="right"
          size="sm"
          class="my-0"
          @change="handlePaginationChange"
        />
      </div>
    </b-card>
    <b-modal
      id="modal-center"
      centered
      hide-footer
    >
      <div style="padding: 30px; text-align: center; padding-bottom: 40px">
        <div style="font-size: 24px; font-weight: 500">
          User ID
        </div>
        <div
          style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px;"
        >
          Uploaded User ID
        </div>
        <b-img
          :src="imageURL"
          fluid
          alt="Responsive image"
          style="width: 369px; height: 222px;"
        />
      </div>
    </b-modal>
    <delete-user-confirmation @delete="remove" />
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import UsersFilter from "@/@core/components/filters/UsersFilter.vue";
import { deleteUser } from "@/service/api.service";
import DeleteUserConfirmation from '@/@core/components/fundraisers/modals/DeleteUserConfirmation.vue';

const [res, apiRequest] = useApi("users");

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    UsersFilter,
    DeleteUserConfirmation,
  },
  data() {
    return {
      response: res,
      currentPage: 1,
      offset: 0,
      limit: 50,
      perPage: 10,
      filters: {},
      filter: '',
      imageURL: "",
      userId: '',
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      fields: [
        // 'row_selector',
        "id",
        { key: "name", label: "Name" },
        { key: "idFile", label: "Uploaded ID" },
        "date",
        { key: "userCategory", label: "Category" },
        { key: "verificationStatus", label: "Status" },
        { key: "account.isEmailVerified", label: "Email Verification" },
        "actions",
      ],
      status: [
        {
          ABSENT: "light-primary",
          STANDARD: "light-success",
          PROLIFICFUNDRAISER: "light-danger",
          VIP: "light-warning",
          CHARITY: 'light-primary',
          6: "light-info",
        },
      ],
      verstatus: [
        {
          VERIFIED: "light-success",
          UNVERIFIED: "light-warning",
        },
      ],
    };
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  watch: {
    "response.isFailure": function(isFailure) {
      if (!isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: this.response.error || "Failed to fetch resource",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    dayjs,
    async filterTable(filters) {
      this.filters = filters
      await this.getUsers();
    },

    async resetTable() {
      this.filters = {}
      this.currentPage = 1
      await this.getUsers();
    },
    remove() {
      deleteUser(this.userId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast(
              "Success",
              "success",
              "User successfully deleted"
            );
            this.getUsers()
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    getUsers(offset = 0) {
      apiRequest({ ...this.filters, offset, limit: this.limit });
    },
    openEdit(data) {
      console.log("edit ", data);
    },
    viewUserDetails(data) {
      console.log("view ", data);
      this.$router.push(`/users/${data.item.userid}/view`);
    },
    viewImage(data) {
      console.log("images ", data);
      this.imageURL = data.item.idFile;
    },
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    setPerPage(e) {
      console.log("valueee ", e);
      this.limit = e;
      this.getUsers(this.offset);
    },
    handlePaginationChange(event) {
      this.offset = (event - 1) * this.limit;
      this.getUsers(this.offset);
    },
    routeToNewUser() {
      this.$router.push(`/users/new-user`);
    },
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}
</style>
