<template>
  <div>
    <b-card>
      <div style="font-weight: 500; font-size: 18px; margin-bottom: 10px;">
        Search & Filter
      </div>
      <b-row>
        <b-col md="6" xl="4" class="mb-0">
          <!-- basic -->
          <b-form-group label="User" label-for="user">
            <b-form-input id="user" placeholder="User" />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-0">
          <!-- basic -->
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" placeholder="Email" />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-0">
          <!-- basic -->
          <b-form-group label="Date" label-for="date">
            <b-form-input id="date" placeholder="Date" />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-0">
          <!-- basic -->
          <b-form-group label="Category" label-for="category">
            <b-form-select :options="catoptions" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Row details support" no-body>
      <div style="padding: 20px">
        <b-row>
          <b-col cols="5">
            <div style="display: flex;">
              <span style="margin-top: 7px">Show</span>
              <div style="margin-left: 15px">
                <b-form-select
                  v-model="limit"
                  :options="options"
                  @change="setPerPage"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div style="display: flex;">
              <span style="margin-top: 7px">Search</span>
              <div style="margin-left: 15px; width: 100%">
                <b-form-input
                  id="search"
                  placeholder="Search"
                  style="width: 100%"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <b-button variant="outline-primary" style="width: 100%">
            <feather-icon icon="ExternalLinkIcon" size="16" class="mr-1"/>
              Export
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button variant="primary" style="width: 100%">
              Add New User
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center h-100 mt-16"
        style="margin-top: 4rem"
      >
        <feather-icon icon="LoaderIcon" class="spinner" size="60" />
      </div>
      <b-table
        :items="response.data"
        :fields="fields"
        responsive
        class="mb-0"
        v-else
      >
        <template #cell(createdAt)="data">
          <div class="d-flex align-items-center">
            <div>
              <div>
                {{ dayjs(data.item.createdAt).format("DD/MM/YYYY") }}
              </div>
              <div class="font-small-2 text-muted">
                {{ dayjs(data.item.createdAt).format("h:mm a") }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(paymentChannel)="data">
            <span style="text-transform: capitalize">
              {{ data.item.paymentChannel }}
            </span>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[0][data.value]">
            <span style="text-transform: capitalize">
              {{ data.item.status }}
            </span>
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="text-body align-middle mr-25"
            @click="viewUserDetails(data)"
            style="cursor: pointer"
          />
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="text-body align-middle mr-25"
            @click="openEdit(data)"
            style="margin-left: 10px; cursor: pointer"
          />
        </template>
      </b-table>
      <div
        style="padding: 20px; display: flex; justify-content: space-between;"
      >
        <div style="color: #B9B9C3;">
          Showing {{ getEntryNumber }} to {{ getEntriesCount }} of
          {{ response.metadata ? response.metadata.totalRecords : 0 }} entries
        </div>
        <b-pagination
          v-model="currentPage"
          @change="handlePaginationChange"
          :total-rows="response.metadata ? response.metadata.totalRecords : 0"
          :per-page="limit"
          align="right"
          size="sm"
          class="my-0"
        />
      </div>
    </b-card>
    <b-modal id="modal-center" centered hide-footer hide-header>
      <div style="padding: 30px; text-align: center;">
        <div style="font-size: 24px; font-weight: 500">User ID</div>
        <div
          style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px;"
        >
          Uploaded User ID
        </div>
        <b-img
          :src="imageURL"
          fluid
          alt="Responsive image"
          style="width: 369px; height: 222px;"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
  },
  data() {
    return {
      response: null,
      currentPage: 1,
      userId: null,
      offset: 0,
      limit: 50,
      perPage: 10,
      imageURL: "",
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      catoptions: [
        { value: "Standard", text: "Standard" },
        { value: "VIP", text: "VIP" },
        { value: "Charity", text: "Charity" }
      ],
      fields: [
        { key: "createdAt", label: "Date" },
        { key: "id", label: "Transaction ID" },
        { key: "transactionType", label: "Transaction Type" },
        { key: "transactionReference", label: "Transaction REF" },
        { key: "amount", label: "Amount" },
        { key: "paymentChannel", label: "Processor" },
        { key: "status", label: "Status" },
      ],
      status: [
        {
          ABSENT: "light-primary",
          PAID: "light-success",
          PROLIFICFUNDRAISER: "light-danger",
          INITIATED: "light-warning",
          6: "light-info",
        },
      ],
    };
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  // watch: {
  //   "response.isFailure": function(isFailure) {
  //     if (!isFailure) {
  //       this.$toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: "Error",
  //           text: this.response.error || "Failed to fetch resource",
  //           icon: "TrashIcon",
  //           variant: "danger",
  //         },
  //       });
  //     }
  //   },
  // },
  async created() {
    this.userId = this.$route.params.userId;
    await this.getTransactions();
  },
  methods: {
    dayjs,
    getTransactions(offset = 0) {
      const [res, apiRequest] = useApi(`transactions/${this.userId}`);
      apiRequest({ offset, limit: this.limit });
      this.response = res;
    },
    openEdit(data) {
      console.log("edit ", data);
    },
    viewUserDetails(data) {
      console.log("view ", data);
      this.$router.push(`/users/${data.item.userid}/view`);
    },
    viewImage(data) {
      console.log("images ", data);
      this.imageURL = data.item.idFile;
    },
    getAvatarText(obj) {
      const avatarText = `${obj.firstname.charAt(0)} ${obj.lastname.charAt(0)}`;
      return avatarText.toUpperCase();
    },
    setPerPage(e) {
      console.log("valueee ", e);
      this.limit = e;
      this.getTransactions(this.offset);
    },
    handlePaginationChange(event) {
      this.offset = (event - 1) * this.limit;
      this.getTransactions(this.offset);
    },
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}
</style>
