/* eslint-disable */
export const banksdata =  [ 
    { id: 1, name: 'Access Bank', code: '044', nipCode: '000014' },
  { id: 2, name: 'Coronation Bank', code: '559', nipCode: '060001' },
  { id: 3, name: 'Diamond Bank', code: '063', nipCode: '000005' },
  { id: 5, name: 'Ecobank Nigeria', code: '050', nipCode: '000010' },
  { id: 6, name: 'Fidelity Bank Nigeria', code: '070', nipCode: '000007' },
  { id: 7, name: 'First Bank of Nigeria', code: '011', nipCode: '000016' },
  { id: 8, name: 'First City Monument Bank', code: '214', nipCode: '000003' },
  { id: 9, name: 'Globus Bank', code: '103', nipCode: '000027' },
  { id: 10, name: 'Guaranty Trust Bank', code: '058', nipCode: '000013' },
  { id: 11, name: 'Heritage Bank Plc', code: '030', nipCode: '000020' },
  { id: 12, name: 'Jaiz Bank', code: '301', nipCode: '000006' },
  { id: 13, name: 'Keystone Bank Limited', code: '082', nipCode: '000002' },
  { id: 14, name: 'Polaris Bank', code: '076', nipCode: '000008' },
  { id: 15, name: 'Providus Bank Plc', code: '101', nipCode: '000023' },
  { id: 16, name: 'Stanbic IBTC Bank Nigeria Limited', code: '221', nipCode: '000012' },
  { id: 17, name: 'Standard Chartered Bank', code: '068', nipCode: '000021' },
  { id: 18, name: 'Sterling Bank', code: '232', nipCode: '000001' },
  { id: 19, name: 'Suntrust Bank Nigeria Limited', code: '100', nipCode: '000022' },
  { id: 20, name: 'Union Bank of Nigeria', code: '032', nipCode: '000018' },
  { id: 21, name: 'United Bank for Africa', code: '033', nipCode: '000004' },
  { id: 22, name: 'Unity Bank Plc', code: '215', nipCode: '000011' },
  { id: 23, name: 'Wema Bank', code: '035', nipCode: '000017' },
  { id: 24, name: 'Zenith Bank', code: '057', nipCode: '000015' },
  { id: 25, name: 'Citibank', code: '023', nipCode: '000009' },
  { id: 26, name: 'Kuda Bank', code: '50211', nipCode: '090267' },
  { id: 27, name: 'Opay', code: '305', nipCode: '100004' },
  { id: 28, name: 'Palmpay', code: '100033', nipCode: '100033' },
]