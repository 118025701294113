<template>
  <div class="">
    <b-card>
      <div
        v-if="!rewards || !rewards.length"
        style="text-align: center; font-size: 18px"
        class="mt-4 mb-4"
      >
        No rewards
      </div>
      <b-row v-else>
        <b-col cols="12">
          <div
            v-for="(reward, index) in rewards"
            :key="index"
            class="mt-2 reward"
          >
            <div style="display: flex; justify-content: space-between;">
              <div>
                <span
                  style="font-weight: bold; color: #577387"
                >&#8358;{{ reward.blessingAmount }}</span><span class="text-muted"> or more</span>
              </div>
              <div
                class=""
                style="display: flex;"
              >

                <div
                  class="font-small-2"
                  style="color: #567286; background: #E0F2FF; padding: 6px; border-radius: 10px; font-weight: 500"
                >
                  {{ reward.blessorsCount }} blessors
                </div>

                <feather-icon
                  icon="TrashIcon"
                  color="red"
                  class="ml-2"
                  @click="remove(reward)"
                />
              </div>
            </div>
            <div
              class="mt-1"
              style="font-weight: bold"
            >
              Reward
            </div>
            <div style="display: flex; justify-content: space-between">
              <div
                class="mt-1"
                style="color: #577387"
              >
                {{ reward.reward }}
              </div>
              <div
                v-b-modal.edit-reward
                style="font-weight: bold; cursor: pointer"
                @click="setEdit(reward)"
              >
                Edit
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <edit-reward-modal
      :reward-obj="rewardObject"
      @refresh="getRewards"
    />
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import { fetchRewards, deleteReward } from "@/service/api.service";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import EditRewardModal from "@/@core/components/fundraisers/modals/EditRewardModal";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
dayjs.extend(relativeTime);
const flatObject = (object) => {
  let result = {};

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key]?.constructor === Object) {
        result = { ...result, ...flatObject(object[key]) };
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
};

export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
    EditRewardModal,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      fundraiserId: null,
      rewards: [],
      rewardObject: null,
    };
  },
  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getRewards();
  },
  methods: {
    dayjs,
    remove(reward) {
      deleteReward(reward.id)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast(
              "Success",
              "success",
              "Reward successfully deleted"
            );
            this.getRewards()
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    getRewards() {
      fetchRewards(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.rewards = response.data;
          }
        })
        .catch((e) => console.error(e));
    },
    setEdit(reward) {
      this.rewardObject = reward;
    },
  },
};
</script>

<style scoped>
.reward {
  padding: 1rem;
  border: 1px solid #e5e5e5;
}
</style>
