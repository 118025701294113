import http from '@/auth/jwt/useJwt'

const defaultDataFormatter = (pre, { data }) => {
  if (data) return data
  return pre
}

// eslint-disable-next-line import/prefer-default-export
export const useApi = (
  url,
  initData = null,
  dataFormatter = defaultDataFormatter,
) => {
  const apiResult = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    error: null,
    data: initData,
    metadata: null,
  }

  const setError = error => { apiResult.error = error }
  const setData = data => { apiResult.data = data }
  const setMetadata = data => { apiResult.metadata = data }
  const setIsSuccess = value => { apiResult.isSuccess = value }
  const setIsFailure = value => { apiResult.isFailure = value }
  const toggleIsLoading = () => { apiResult.isLoading = !apiResult.isLoading }

  const callApi = async (params = {}) => {
    try {
      setIsSuccess(false)
      setIsFailure(false)
      toggleIsLoading()
      const response = await http.axiosIns.get(url, { params })
      setData(dataFormatter(apiResult.data, response))
      setMetadata(response.metadata)
      setError(null)
      setIsSuccess(true)
    } catch ({ data }) {
      setError(data.message)
      setIsFailure(true)
    } finally {
      toggleIsLoading()
    }
  }

  return [apiResult, callApi]
}
