<template>
  <b-modal id="new-admin-user" ref="new-admin-user" centered hide-footer>
    <h4 class="text-center mt-2">
      {{ isEditMode ? "Edit" : "Add new" }} admin user
    </h4>
    <form @submit="onSubmit">
      <div style="padding: 25px;">
        <b-row>
          <b-col cols="6">
            <div>
              <b-form-group label="Firstname" label-for="firstname">
                <b-form-input
                  id="firstname"
                  placeholder="firstname"
                  v-model="firstname"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="6">
            <div>
              <b-form-group label="Lastname" label-for="lastname">
                <b-form-input
                  id="lastname"
                  placeholder="lastname"
                  v-model="lastname"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="6">
            <div>
              <b-form-group label="Email" label-for="email">
                <b-form-input
                  id="email"
                  placeholder="email"
                  v-model="email"
                  :disabled="isEditMode"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="6">
            <div>
              <b-form-group label="Role" label-for="role">
                <b-form-select id="role" :options="userroles" v-model="role" />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" v-if="isEditMode">
            <div>
              <b-form-group label="Status" label-for="status">
                <b-form-select
                  id="status"
                  :options="statuses"
                  v-model="status"
                />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" v-if="!isEditMode">
            <div>
              <b-form-group>
                <label for="basic-password">Password</label>
                <b-input-group>
                  <b-form-input
                    id="basic-password"
                    :type="passwordFieldType"
                    v-model="password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <div class="mb-0 text-center mt-2">
          <!-- basic -->
          <b-button variant="primary" type="submit">Save</b-button>
          <b-button variant="outline-secondary" class="ml-1" @click="closeModal"
            >Discard</b-button
          >
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { getUserRoles, saveAdminUser, updateAdminUser } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BInputGroupAppend,
    BFormTextarea,
    BInputGroup,
  },

  data() {
    return {
      firstname: null,
      email: null,
      lastname: null,
      password: null,
      roles: [],
      role: null,
      isEditMode: false,
      categoryId: null,
      fundraiserId: null,
      passwordFieldType: "password",
      status: null,
      statuses: [
        { value: null, text: "Select status" },
        { value: "ACTIVE", text: "ACTIVE" },
        { value: "INACTIVE", text: "INACTIVE" },
      ],
      fundcatoptions: [
        { value: null, text: "Select category" },
        { value: "3", text: "Wedding" },
        { value: "4", text: "Charity" },
        { value: "6", text: "Education" },
        { value: "7", text: "Health" },
        { value: "1", text: "Music" },
        { value: "2", text: "Religion" },
        { value: "11", text: "Funeral" },
        { value: "8", text: "Startups" },
        { value: "10", text: "Models" },
        { value: "5", text: "Community Projects" },
      ],
    };
  },

  props: {
    adminUserData: {
      type: Object || null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    isEdit(value) {
      this.isEditMode = value;
    },
    adminUserData(value) {
      this.email = this.isEdit ? value.email : null;
      this.firstname = this.isEdit ? value.firstname : null;
      this.lastname = this.isEdit ? value.lastname : null;
      this.role = this.isEdit ? value.role : null;
      this.status = this.isEdit ? value.status : null;
    },
  },

  computed: {
    userroles() {
      const roles = [{ value: null, text: "Select role" }];
      this.roles.forEach((role) => {
        roles.push({ value: role.id, text: role.role });
      });
      return roles;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  async created() {
    await this.getRoles();
  },

  methods: {
    doAdminUser(payload) {
    const request = this.isEditMode ? updateAdminUser(this.adminUserData.id, payload) : saveAdminUser(payload)
        request.then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast("Success", "success", response.message);
            this.$emit("refresh");
            this.closeModal();
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    togglePasswordVisibility() {
      if (this.passwordFieldType === "password") {
        this.passwordFieldType = "text";
      } else {
        this.passwordFieldType = "password";
      }
    },
    getRoles() {
      getUserRoles()
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.roles = response.data;
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    onSubmit(e) {
      e.preventDefault();
      const createPayload = {
        role: this.role,
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        password: this.password,
      };
      const editPayload = {
        firstname: this.firstname,
        lastname: this.lastname,
        role: this.role,
        status: this.status,
      };
      const payload = this.isEditMode ? editPayload : createPayload
      this.doAdminUser(payload);
    },
    closeModal() {
      this.$nextTick(() => {
        this.$emit("close");
        this.$refs["new-admin-user"].toggle("#toggle-btn");
      });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    onChange(value, id) {
      this[id] = value;
    },
  },
};
</script>

<style scoped></style>
