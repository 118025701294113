<template>
  <b-card
    no-body
    class="card-company-table"
  >
    <div
      v-if="response.isLoading"
      class="d-flex align-items-center justify-content-center h-100"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="60"
      />
    </div>
    <b-table
      v-else
      :items="response.data"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <template #cell(date)="data">
        <div class="d-flex align-items-center">
          <div>
            <div>
              {{ dayjs(data.item.createdAt).format('DD/MM/YYYY') }}
            </div>
            <div class="font-small-2 text-muted">
              {{ dayjs(data.item.createdAt).format('h:mm a') }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(id)="data">
        <div class="font-weight-bold">
          {{ data.item.fundraiserId }}
        </div>
      </template>
      <template #cell(type)="data">
        <div class="font-weight-bold text-capitalize">
          {{ data.item.transactionType.toLowerCase() }}
        </div>
      </template>
      <template #cell(processor)="data">
        <div class="text-capitalize">
          {{ data.item.paymentChannel.toLowerCase() }}
        </div>
      </template>
      <template #cell(amount)="data">
        <div class="font-weight-bold">
          {{ data.item.amount }}
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useApi } from '@core/comp-functions/useApi'

const [res, apiRequest] = useApi('transactions')

export default {
  components: {
    BCard,
    BTable,
  },

  data() {
    return {
      response: res,
      fields: [
        { key: 'date', label: 'DATE' },
        { key: 'id', label: 'ID' },
        { key: 'type', label: 'TYPE' },
        { key: 'processor', label: 'PROCESSOR' },
        { key: 'amount', label: 'AMOUNT' },
      ],
    }
  },
  watch: {
    'response.isFailure': function (isFailure) {
      if (isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: this.response.error || 'Failed to fetch resource',
            icon: 'TrashIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    apiRequest({ offset: 0, limit: 6 })
  },
  methods: {
    dayjs,
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
