/* eslint-disable */
import Vue from 'vue'
import VueCompositionAPI, { ref } from '@vue/composition-api'
import dayjs from 'dayjs';

Vue.use(VueCompositionAPI)

const user = ref(null)

export const isUserLoggedIn = () => !!user.value

export const getAuthUser = () => user.value

export const setAuthUser = authData => {
  user.value = authData
}

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const isAuthValid = () => {
  const token = localStorage.getItem("accessToken");
  if (token){
    const data = parseJwt(token);
    const isTokenValid = dayjs.unix(data.exp).isAfter(Date.now());
    return isTokenValid;
  }
  return false
}
