<template>
  <main>
    <b-row>
      <b-col cols="12">
        <b-card>
          <div
            style="padding-bottom: 16px; border-bottom: 1px solid #EBE9F1; font-size: 18px; font-weight: 600"
          >
            Add an Organisation Account
          </div>
          <div
            style="max-width: 500px; margin: auto"
            class="mt-4 mb-4"
          >
            <form @submit="onSubmit">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Organisation Name"
                    label-for="firstname"
                  >
                    <b-form-input
                      id="firstname"
                      v-model="firstname"
                      required
                      placeholder="Organisation Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="RC Number"
                    label-for="rcnumber"
                  >
                    <b-form-input
                      id="rcnumber"
                      v-model="rcnumber"
                      required
                      placeholder="RC Number"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Brand Name"
                    label-for="username"
                  >
                    <b-form-input
                      id="username"
                      v-model="username"
                      required
                      placeholder="Brand Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Account Number"
                    label-for="accountnumber"
                  >
                    <b-form-input
                      id="accountnumber"
                      v-model="accountnumber"
                      required
                      placeholder="Account No."
                      type="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Bank"
                    label-for="bank"
                  >
                    <b-form-select
                      id="bank"
                      :options="banks"
                      required
                      :value="bank"
                      @change="onChange($event, 'bank')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="BVN (Optional)"
                    label-for="bvn"
                  >
                    <b-form-input
                      id="bvn"
                      v-model="bvn"
                      required
                      type="number"
                      placeholder="BVN"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Contact Person"
                    label-for="contactperson"
                  >
                    <b-form-input
                      id="contactperson"
                      v-model="contactperson"
                      required
                      placeholder="Contact Person"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Contact Phone Number"
                    label-for="phonenumber"
                  >
                    <b-form-input
                      id="phonenumber"
                      v-model="phonenumber"
                      placeholder="+234 (81) 23456789"
                      type="number"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      placeholder="Email"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    v-model="address"
                    label="Full Address"
                    label-for="address"
                  >
                    <b-form-input
                      id="address"
                      v-model="address"
                      placeholder="Address"
                      type="text"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="State/Province"
                    label-for="state"
                  >
                    <b-form-select
                      id="state"
                      v-model="state"
                      :options="states"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Country"
                    label-for="country"
                  >
                    <b-form-select
                      id="country"
                      v-model="country"
                      :options="countries"
                      required
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!fileBase64"
                  cols="12"
                >
                  <div style="text-align: center">
                    <input
                      id="file2"
                      type="file"
                      style="display: none"
                      @change="onFileSelected"
                    >
                    <label
                      style="border: 2px dashed #7666F8; width: 100%; height: 129px"
                      for="file2"
                    >
                      <div
                        class="mt-4"
                        style="color: #7666F8;"
                      >
                        Click here to upload logo
                      </div>
                    </label>
                  </div>
                </b-col>
                <b-col
                  v-if="fileBase64"
                  cols="5"
                >
                  <div
                    class=""
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                  >
                    <div style="text-align: center; width: 100%">
                      <img
                        :src="fileBase64"
                        style="width: 100%"
                      >
                    </div>
                    <div
                      style="text-align: right"
                      class="p-1 font-small"
                    >
                      <b-button
                        variant="outline-primary"
                        class="ml-1"
                        style="padding: 8px"
                        @click="removeFile"
                      >Remove</b-button>
                    </div>
                  </div>
                </b-col>
                <b-col
                  md="12"
                  class="mt-1"
                >
                  <b-form-group
                    label="Means of Identification"
                    label-for="meansOfId"
                  >
                    <b-form-select
                      id="meansOfId"
                      v-model="idType"
                      :options="idTypes"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!fileBase64Id"
                  cols="12"
                >
                  <div style="text-align: center">
                    <input
                      id="file3"
                      type="file"
                      style="display: none"
                      @change="onFileSelectedId"
                    >
                    <label
                      style="border: 2px dashed #7666F8; width: 100%; height: 129px"
                      for="file3"
                    >
                      <div
                        class="mt-4"
                        style="color: #7666F8;"
                      >
                        Click here to upload ID
                      </div>
                    </label>
                  </div>
                </b-col>
                <b-col
                  v-if="fileBase64Id"
                  cols="5"
                >
                  <div
                    class=""
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                  >
                    <div style="text-align: center; width: 100%">
                      <img
                        :src="fileBase64Id"
                        style="width: 100%"
                      >
                    </div>
                    <div
                      style="text-align: right"
                      class="p-1 font-small"
                    >
                      <b-button
                        variant="outline-primary"
                        class="ml-1"
                        style="padding: 8px"
                        @click="removeFileId"
                      >Remove</b-button>
                    </div>
                  </div>
                </b-col>

                <b-col
                  v-if="!fileBase64Cac"
                  cols="12" class="mt-2"
                >
                  <div style="text-align: center">
                    <input
                      id="file3"
                      type="file"
                      style="display: none"
                      @change="onFileSelectedCac"
                    >
                    <label
                      style="border: 2px dashed #7666F8; width: 100%; height: 129px"
                      for="file3"
                    >
                      <div
                        class="mt-4"
                        style="color: #7666F8;"
                      >
                        Click here to upload CAC Certificate
                      </div>
                    </label>
                  </div>
                </b-col>
                <b-col
                  v-if="fileBase64Cac"
                  cols="5"
                >
                  <div
                    class=""
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
                  >
                    <div style="text-align: center; width: 100%">
                      <img
                        :src="fileBase64Cac"
                        style="width: 100%"
                      >
                    </div>
                    <div
                      style="text-align: right"
                      class="p-1 font-small"
                    >
                      <b-button
                        variant="outline-primary"
                        class="ml-1"
                        style="padding: 8px"
                        @click="removeFileCac"
                      >Remove</b-button>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-1"
                >
                  <b-form-group
                    label="Website"
                    label-for="website"
                  >
                    <b-form-input
                      id="website"
                      v-model="website"
                      placeholder="Website"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class=""
                >
                  <b-form-group
                    label="Add Social Media"
                    label-for=""
                    class="font-small-2"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="TwitterIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="twitter"
                        placeholder="https://twitter.com/"
                      />
                    </b-input-group>

                    <b-input-group class="mt-1">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="FacebookIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="facebook"
                        placeholder="https://facebook.com/"
                      />
                    </b-input-group>

                    <b-input-group class="mt-1">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="InstagramIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="instagram"
                        placeholder="https://instagram.com/"
                      />
                    </b-input-group>

                    <b-input-group class="mt-1">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="YoutubeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="youtube"
                        placeholder="https://youtube.com/"
                      />
                    </b-input-group>

                    <b-input-group class="mt-1">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="TvIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="telegram"
                        placeholder="https://telegram.com/"
                      />
                    </b-input-group>

                    <b-input-group class="mt-1">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="TwitchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="tiktok"
                        placeholder="https://tiktok.com/"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="mb-0 text-center mt-2">
                <!-- basic -->
                <b-button
                  variant="primary"
                  type="submit"
                >{{
                  isLoading
                    ? "Creating account..."
                    : "Create Organisation Account"
                }}</b-button>

                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  @click="discard"
                >Discard</b-button>
              </div>
            </form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
/* eslint-disable */
import {
  //   BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  //   BImg,
  BFormInput,
  BModal,
  BSpinner,
  BButton,
  BFormSelect,
  BBadge,
  BInputGroupPrepend,
  BInputGroup,
  //   BPagination,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  createBankDetails,
  createNewCorporate,
  createNewUser,
  resetUserPassword,
  verifyBankAccount,
  verifyBVN,
} from "@/service/api.service";
import { banksdata } from "../../data/banks";
import { stateslist } from "../../data/states";

export default {
  components: {
    // BTable,
    BButton,
    // // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    // BButton,
    BCard,
    BRow,
    BModal,
    BSpinner,
    // BImg,
    BCol,
    BBadge,
    BAvatar,
    BInputGroupPrepend,
    BInputGroup,
    // BPagination,
    // FeatherIcon,
  },
  data() {
    return {
      userId: null,
      response: null,
      countries: [
        { text: "Select country", value: null },
        { text: "Nigeria", value: "ng" },
      ],
      idTypes: [
        { text: "Select ID type", value: null },
        { text: "International Passport", value: "International Passport" },
        { text: "Drivers License", value: "Drivers License" },
        { text: "National ID", value: "National ID" },
        { text: "Voter's Card", value: "Voter's Card" },
      ],
      genders: [
        { text: "Select gender", value: null },
        { text: "Male", value: "MALE" },
        { text: "Female", value: "FEMALE" },
      ],
      country: "ng",
      isLoading: false,
      state: null,
      bank: null,
      accountnumber: null,
      phonenumber: null,
      email: null,
      bvn: null,
      firstname: null,
      address: null,
      fileBase64: null,
      fileBase64Id: null,
      fileBase64Cac: null,
      contactperson: null,
      username: null,
      rcnumber: null,
      website: null,
      profilePhoto: null,
      twitter: "",
      facebook: "",
      instagram: "",
      youtube: "",
      telegram: "",
      tiktok: "",
      idType: null,
      idFile:null,
      cacFile:null,
      bankResponse: null

    };
  },
  async created() {},
    computed: {
    banks() {
      const bankOptions = [{ text: "Select bank", value: null }];
      banksdata.forEach((bank) => {
        bankOptions.push({ text: bank.name, value: bank.code });
      });
      return bankOptions;
    },
    states() {
      const stateOptions = [{ text: "Select state", value: null }];
      stateslist.forEach((state) => {
        stateOptions.push({ text: state, value: state.toUpperCase() });
      });
      return stateOptions;
    },
  },
  methods: {
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    onChange(value, id) {
      this[id] = value;
    },
    discard() {
      this.$router.push("/users");
    },
    verifyBankDetails() {
      const payload = {
        account_bank: this.bank,
        account_number: this.accountnumber,
      };
      return verifyBankAccount(payload);
    },
    verifyBVNNumber() {
      const { firstname, bank, bvn, accountnumber } = this;
      const payload = {
        firstname,
        bvn,
        bankCode: bank,
        accountNumber: accountnumber,
      };
      return verifyBVN(payload);
    },

    onSubmit(e) {
      e.preventDefault();
      if (this.profilePhoto) {
        this.createUser();
      } else {
        this.showToast("Error", "danger", "Please upload a company logo");
      }
    },

    submitUserDetails() {
      const {
        firstname,
        email,
        phonenumber,
        address,
        country,
        state,
        username,
        rcnumber,
        contactperson,
        profilePhoto,
        idType, idFile, cacFile,
        bvn,
        website,
        twitter,
        facebook,
        instagram,
        telegram,
        tiktok,
        youtube,

      } = this;

      const payload = {
        firstname,
        email,
        phonenumber,
        address,
        country,
        state,
        username,
        rcnumber,
        contactperson,
        profilePhoto,
        cacFile,
        idType, idFile,
        bvn,
        sociallinks: [
          { smname: "Twitter", link: twitter },
          { smname: "Facebook", link: facebook },
          { smname: "Instagram", link: instagram },
          { smname: "Youtube", link: youtube },
          { smname: "Telegram", link: telegram },
          { smname: "Tiktok", link: tiktok },
          { smname: "Web", link: website },
        ],
        accounttype: "CORPORATE",
      };
      console.log("paypay ", payload);
      return createNewCorporate(payload);
    },
    addBankDetails(userid) {
      const { text } = this.banks.find((obj) => obj.value === this.bank);
      const payload = {
        userid,
        bankname: text,
        bankcode: this.bank,
        bvn: this.bvn,
        accountnumber: this.accountnumber,
        accountname: this.bankResponse.data.account_name,
      };
      return createBankDetails(payload);
    },
    async createUser() {
      this.isLoading = true;
      const bankVerificationResponse = await this.verifyBankDetails();
      const resp = await bankVerificationResponse.json();
      this.bankResponse = resp
      if (resp.valid) {
        this.showToast(
          "Success",
          "success",
          "Bank account verification successful"
        );
        const bvnVerificationResponse = this.bvn
          ? await this.verifyBVNNumber()
          : null;
        const bvnResp = this.bvn ? await bvnVerificationResponse.json() : { valid: true, message: ''};
        if (bvnResp.valid) {
          this.showToast("Success", "success", "BVN verification successful");
          const createUserResponse = await this.submitUserDetails();
          const response = await createUserResponse.json();
          if (response.valid) {
            const userid = response.data.id;
            this.showToast("Success", "success", "Corporate created successfully");
            await this.addBankDetails(userid);
            this.isLoading = false;
            this.$router.push("/users");
          } else {
            this.isLoading = false;
          }
        } else {
          this.showToast("Error", "error", bvnResp.message);
          this.isLoading = false;
        }
      } else {
        this.showToast("Error", "error", bvnResp.message);
        this.isLoading = false;
      }
    },
    async onFileSelected(event) {
      const file = event.target.files[0];
      console.log(file);
      this.fileBase64 = await this.toBase64(file);
      this.profilePhoto = {
        name: file.name,
        mimetype: file.type,
        base64: this.fileBase64,
      };
    },

    async onFileSelectedId(event) {
      const file = event.target.files[0];
      console.log(file);
      this.fileBase64Id = await this.toBase64(file);
      this.idFile = {
        name: file.name,
        mimetype: file.type,
        base64: this.fileBase64Id,
      };
    },

    async onFileSelectedCac(event) {
      const file = event.target.files[0];
      console.log(file);
      this.fileBase64Cac = await this.toBase64(file);
      this.cacFile = {
        name: file.name,
        mimetype: file.type,
        base64: this.fileBase64Cac,
      };
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    removeFile() {
      this.fileBase64 = null;
    },
    removeFileId(){
      this.fileBase64Id = null
    },
    removeFileCac(){
      this.fileBase64Cac = null
    }
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}

.item-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.item-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-left: 8px;
}

.value {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
}

.item {
  margin-top: 14px;
}
</style>
