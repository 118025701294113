<template>
  <b-card
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <h6 style="font-size: 9px;">
      Account wallet balance
    </h6>
    <div
      v-if="response.isLoading"
      class="d-flex align-items-center justify-content-center my-3"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="40"
      /></div>
    <div v-else>
      <h4 style="font-size: 14px">&#8358;{{ response.data.accountWalletBalance.toLocaleString() }}</h4>

      <h6 style="font-size: 9px;">
        Fundraiser wallet bal
      </h6>
      <h4 style="font-size: 14px">&#8358;{{ response.data.fundraiserWalletBalance.toLocaleString() }}</h4>

      <h6 style="font-size: 9px;">
        PnE wallet bal
      </h6>
      <h4 style="font-size: 14px">&#8358;{{ response.data.promoteAndEarnWalletBalance.toLocaleString() }}</h4>

      <h6 style="font-size: 9px">Total wallet balance</h6>
      <h3 style="font-size: 15px">&#8358;{{ response.data.totalWalletBalance.toLocaleString() }}</h3>

    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { useApi } from '@core/comp-functions/useApi'

const [res, apiRequest] = useApi('wallets-total')

export default {
  components: {
    BCard,
  },
  data() {
    return {
      response: res,
    }
  },
  created() {
    apiRequest()
  },
}
</script>
