<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>Blessings by Processor</b-card-title>
        <b-card-text class="font-small-2">
          {{ selectedFilter }}
        </b-card-text>
      </div>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item
          v-for="option in filterOptions"
          :key="option"
          @click="selectedFilter = option"
        >
          {{ option }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <!-- apex chart -->
    <div
      v-if="response.isLoading"
      class="d-flex align-items-center justify-content-center h-100"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="60"
      />
    </div>
    <vue-apex-charts
      v-else
      height="200"
      class="my-2 flex-grow-1"
      :options="blessingProcessor.chartOptions"
      :series="series"
    />
    <b-row class="text-center mx-0 border-top">
      <b-col
        cols="3"
        class="d-flex align-items-center justify-content-center py-cus-1"
      >
        <span
          class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
        />
        <span class="text-muted">Apple Pay</span>
      </b-col>

      <b-col
        cols="3"
        class="d-flex align-items-center justify-content-center py-cus-1"
      >
        <span
          class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
        />
        <span class="text-muted">Bank</span>
      </b-col>
      <b-col
        cols="3"
        class="d-flex align-items-center py-cus-1"
      >
        <span
          class="bullet bullet-success justify-content-center svg-font-small-3 mr-50 cursor-pointer"
        />
        <span class="text-muted">Card</span>
      </b-col>
      <b-col
        cols="3"
        class="d-flex align-items-center py-cus-1"
      >
        <span
          class="bullet bullet-dark justify-content-center svg-font-small-3 mr-50 cursor-pointer"
        />
        <span class="text-muted">USSD</span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardTitle, BCardText, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useApi } from '@core/comp-functions/useApi'
import { formatNumber } from '@core/utils/utils'

const [res, apiRequest] = useApi('blessingsByProcessor')
const filterOptions = ['All', 'Today', 'This Week', 'This Month', 'This Year']

const $paypal = '#7367F0'
const $monnify = '#FF9F43'
const $paystack = '#55D38D'
const $ussd = '#4b4b4b'

const num = res.data
const series = num ? [num.applepay, num.bank, num.card, num.ussd] : []

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCardText,
    BCol,
  },
  data() {
    return {
      selectedFilter: filterOptions[3],
      response: res,
      blessingProcessor: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: { show: false },
          },
          dataLabels: { enabled: false },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['Apple pay', 'Bank Transfer', 'Card', 'USSD'],
          tooltip: {
            y: {
              formatter(value) {
                return `₦${value} ${series}`
              },

            },

          },
          stroke: { width: 0 },
          colors: [$paypal, $monnify, $paystack, $ussd],
          grid: {
            padding: { right: -20, bottom: -8, left: -20 },
          },
          plotOptions: {
            pie: {
              // startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: { offsetY: 20 },
                  value: {
                    offsetY: -20,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${formatNumber(val)}`
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
  },
  computed: {
    filterOptions: () => filterOptions,
    series() {
      const { data } = this.response

      // return data ? [data.paypal, data.monnify, data.paystack] : []
      return data ? [data.applepay, data.bank, data.card, data.ussd] : []
    },
    totalValue() {
      const numbers = this.series
      let sum = 0

      for (let i = 0; i < numbers.length; i += 1) {
        sum += numbers[i]
      }
      return sum
    },
  },
  watch: {
    'response.isFailure': function (isFailure) {
      if (isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: this.response.error || 'Failed to fetch resource',
            icon: 'TrashIcon',
            variant: 'danger',
          },
        })
      }
    },

    selectedFilter: {
      handler(value) {
        apiRequest({
          filter: value.replace(/^This\s+/, '').toUpperCase(),
        })
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.py-cus-1 {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}
</style>
