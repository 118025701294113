<template>
  <main>
    <div
      v-if="response.isLoading"
      class="d-flex align-items-center justify-content-center h-100 mt-16"
      style="margin-top: 4rem"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="60"
      />
    </div>
    <b-row v-else>
      <b-col cols="9">
        <b-card>
          <div
            style="border-bottom: 1px solid #EBE9F1;"
            class="pb-2"
          >
            <b-row>
              <b-col cols="3">
                <form id="form">
                  <input
                    id="imagefile"
                    type="file"
                    style="display: none"
                    @change="onFileSelected"
                  >
                </form>
                <label
                  style=" border-radius: 6px; cursor: pointer"
                  for="imagefile"
                >
                  <b-avatar
                    size="120px"
                    :src="response.data.profilePhoto"
                  />
                </label>
                <div
                  class="item-value"
                  style="font-size: 10px; margin-left: 36px; margin-top: 4px"
                >
                  Category
                </div>
                <b-badge
                  class="ml-2"
                  variant="light-primary"
                  style="margin-top: 4px; text-transform: capitalize"
                >{{ response.data.userCategory }}</b-badge>
              </b-col>
              <b-col>
                <div
                  style="border-bottom: 1px solid #EBE9F1;"
                  class="pb-1"
                >
                  <b-row>
                    <b-col>
                      <div style="font-weight: 600; font-size: 18px">
                        {{ response.data.firstname }}
                        {{ response.data.lastname }}
                      </div>
                    </b-col>
                    <b-col>
                      <div style="text-align: right">
                        <b-badge variant="light-secondary">{{
                          response.data.account
                            ? response.data.account.accounttype
                            : "N/A"
                        }}</b-badge>
                        <b-badge
                          class="ml-2"
                          :variant="
                            response.data.verificationStatus === 'VERIFIED'
                              ? 'light-success'
                              : 'light-warning'
                          "
                        >{{ response.data.verificationStatus }}</b-badge>

                        <b-badge
                          class="ml-2"
                          :variant="
                            response.data.account.isEmailVerified
                              ? 'light-success'
                              : 'light-warning'
                          "
                        >{{
                          response.data.account.isEmailVerified
                            ? "EMAIL VERIFIED"
                            : "EMAIL UNVERIFIED"
                        }}</b-badge>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-row>
                  <b-col>
                    <div class="item mt-2">
                      <span class="item-label">Username:</span>
                      <span class="item-value">{{
                        response.data.username
                      }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Email:</span>
                      <span class="item-value">{{ response.data.email }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Phone:</span>
                      <span class="item-value">{{
                        response.data.phonenumber
                      }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Date Joined:</span>
                      <span class="item-value">{{
                        response.data.createdAt
                      }}</span>
                    </div>
                    <div
                      v-if="response.data.verificationStatus == 'UNVERIFIED'"
                      class="item text-danger"
                    >
                      <span class="item-label ">Reason for Rejection:</span>
                      <span class="item-value">
                        <ol>
                          <li
                            v-for="(reason, index) in JSON.parse(
                              response.data.reasonForRejection
                            )"
                            :key="index"
                          >
                            {{ reason }}
                          </li>
                        </ol>
                      </span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="item mt-2">
                      <span class="item-label">Bank Name:</span>
                      <span class="item-value">{{
                        response.data.bankdetail
                          ? response.data.bankdetail.bankname
                          : "N/A"
                      }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Account Name:</span>
                      <span class="item-value">{{
                        response.data.bankdetail
                          ? response.data.bankdetail.accountname
                          : "N/A"
                      }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Account Number:</span>
                      <span class="item-value">{{
                        response.data.bankdetail
                          ? response.data.bankdetail.accountnumber
                          : "N/A"
                      }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Commission:</span>
                      <span
                        class="item-value"
                      >{{ response.data.commission }}%</span>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div
            style="border-bottom: 1px solid #EBE9F1;"
            class="pb-2 pt-2"
          >
            <b-row>
              <b-col cols="3" />
              <b-col>
                <b-row>
                  <b-col>
                    <div class="item mt-2">
                      <span class="item-label">ID Type:</span>
                      <span class="item-value">{{
                        response.data.idType ? response.data.idType : "N/A"
                      }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Country:</span>
                      <span
                        class="item-value"
                        style="text-transform: uppercase"
                      >{{
                        response.data.account
                          ? response.data.account.country
                          : "N/A"
                      }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Region:</span>
                      <span class="item-value">{{
                        response.data.state ? response.data.state : "N/A"
                      }}</span>
                    </div>
                    <div class="item">
                      <span class="item-label">Address:</span>
                      <span class="item-value">{{
                        response.data.address ? response.data.address : "N/A"
                      }}</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div
                      v-if="response.data.idFile"
                      v-b-modal.overview-view-image
                      style="width: 249px;"
                    >
                      <img
                        :src="response.data.idFile"
                        style="width: 100%; height: 150px;"
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div
            style="border-bottom: 1px solid #EBE9F1;"
            class="pb-2 pt-2"
          >
            <b-row>
              <b-col cols="3" />
              <b-col>
                <b-row>
                  <b-col>
                    <div class="item mt-2">
                      <span class="item-label">RC Number:</span>
                      <span class="item-value">{{
                        response.data.rcnumber ? response.data.rcnumber : "N/A"
                      }}</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div
                      v-if="response.data.cacFile"
                      v-b-modal.overview-view-cacfile
                      style="width: 249px;"
                    >
                      <img
                        :src="response.data.cacFile"
                        style="width: 100%; height: 150px;"
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div
            style="border-bottom: 1px solid #EBE9F1;"
            class="pb-2 pt-2"
          >
            <b-row>
              <b-col cols="3" />
              <b-col>
                <b-row>
                  <b-col>
                    <div class="item mt-2">
                      <div class="value">
                        N{{ getFormattedAmount(response.data.totalBlessings) }}
                      </div>
                      <div class="label">
                        Total Blessings Received
                      </div>
                    </div>
                    <div class="item mt-3">
                      <div class="value">
                        {{ response.data.totalFundraisers }}
                      </div>
                      <div class="label">
                        Total Projects Created
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="item mt-2">
                      <div class="value">
                        N{{ getFormattedAmount(response.data.totalDonations) }}
                      </div>
                      <div class="label">
                        Total Donations
                      </div>
                    </div>
                    <div class="item mt-3">
                      <div class="value">
                        N{{ getFormattedAmount(response.data.totalCommission) }}
                      </div>
                      <div class="label">
                        Total Commissions Paid
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col cols="3">
        <b-card>
          <div>
            <b-button
              v-b-modal.edit-user
              variant="primary"
              class="w-full"
            >
              Edit User
            </b-button>
          </div>
          <div>
            <b-button
              v-b-modal.change-verification
              variant="outline-secondary"
              class="w-full mt-1"
            >
              Change Verification
            </b-button>
          </div>
          <div>
            <b-button
              v-b-modal.change-email-verification
              variant="outline-secondary"
              class="w-full mt-1"
            >
              Change Email Verification
            </b-button>
          </div>
          <div>
            <b-button
              v-b-modal.sms-user
              variant="outline-secondary"
              class="w-full mt-1"
            >
              SMS User
            </b-button>
          </div>
          <div>
            <b-button
              v-b-modal.email-user
              variant="outline-secondary"
              class="w-full mt-1"
            >
              Email User
            </b-button>
          </div>
          <div v-if="isViewBvnPermitted">
            <b-button
              v-b-modal.view-bvn
              variant="outline-secondary"
              class="w-full mt-1"
            >
              View BVN
            </b-button>
          </div>
          <div v-if="isInternalMessagePermitted">
            <b-button
              v-b-modal.internal-message
              variant="outline-secondary"
              class="w-full mt-1"
            >
              Internal Message
            </b-button>
          </div>
          <div>
            <b-button
              v-b-modal.push-notifications
              variant="outline-secondary"
              class="w-full mt-1"
            >
              Push Notifications
            </b-button>
          </div>
          <div>
            <b-button
              v-b-modal.delete-user-modal
              variant="outline-secondary"
              class="w-full mt-1"
            >
              Delete User
            </b-button>
          </div>
          <div>
            <b-button
              variant="success"
              class="w-full mt-1"
              @click="resetPassword"
            >
              Reset Password
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-modal
        id="overview-view-image"
        centered
        hide-footer
      >
        <div style="padding: 30px; text-align: center; padding-bottom: 40px">
          <div style="font-size: 24px; font-weight: 500">
            User ID
          </div>
          <div
            style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px;"
          >
            Uploaded User ID
          </div>
          <img
            :src="response.data.idFile"
            alt="Responsive image"
            style="width: 369px; height: 222px;"
          >
        </div>
      </b-modal>
      <b-modal
        id="overview-view-cacfile"
        centered
        hide-footer
      >
        <div style="padding: 30px; text-align: center; padding-bottom: 40px">
          <div style="font-size: 24px; font-weight: 500">
            CAC Document
          </div>
          <div
            style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px;"
          >
            Company Registration Document
          </div>
          <img
            :src="response.data.cacFile"
            alt="Responsive image"
            style="width: 369px; height: 222px;"
          >
        </div>
      </b-modal>
      <change-verification-modal
        :verification-status="response.data.verificationStatus"
        :user-id="userId"
        @refresh="getData"
      />
      <change-email-verification-modal
        :is-email-verified="response.data.account.isEmailVerified"
        :email="response.data.email"
        @refresh="getData"
      />
      <edit-user-modal
        :data="response.data"
        @refresh="getData"
      />
      <sms-user-modal :user-id="userId" />
      <email-user-modal :user-id="userId" />
      <internal-message-modal
        :user-id="userId"
        @refresh="getData"
      />
      <push-notifications-modal />
      <reset-password-modal />
      <view-bvn-modal :bvn="response.data.bankdetail.bvn" />
    </b-row>
    <delete-user-confirmation @delete="remove" />
    <image-cropper
      :aspect-ratio="1"
      :open="openCropper"
      :image="image"
      @add="addImage($event)"
      @close="close"
    />
  </main>
</template>

<script>
/* eslint-disable */
import { useApi } from "@core/comp-functions/useApi";
import {
  //   BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  //   BFormGroup,
  //   BImg,
  //   BFormInput,
  BModal,
  BButton,
  //   BFormSelect,
  BBadge
  //   BPagination,
} from "bootstrap-vue";
import ChangeVerificationModal from "../../@core/components/users/modals/ChangeVerificationModal.vue";
import EditUserModal from "../../@core/components/users/modals/EditUserModal.vue";
import PushNotificationsModal from "@/@core/components/users/modals/PushNotificationsModal.vue";
import ResetPasswordModal from "@/@core/components/users/modals/ResetPasswordModal.vue";
import InternalMessageModal from "@/@core/components/users/modals/InternalMessageModal.vue";
import SmsUserModal from "@/@core/components/users/modals/SmsUserModal.vue";
import EmailUserModal from "@/@core/components/users/modals/EmailUserModal.vue";
import ViewBvnModal from "@core/components/users/modals/ViewBvnModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  resetUserPassword,
  deleteUser,
  updateProfile
} from "@/service/api.service";
import { isPermitted } from "../../@core/utils/utils";
import { USER_PERMISSIONS } from "@/data/permissions";
import ChangeEmailVerificationModal from "@/@core/components/users/modals/ChangeEmailVerificationModal.vue";
import DeleteUserConfirmation from "@/@core/components/fundraisers/modals/DeleteUserConfirmation.vue";
import ImageCropper from "@/@core/components/fundraisers/galley/ImageCropper.vue";

export default {
  components: {
    // BTable,
    BButton,
    // // BFormCheckbox,
    // BFormSelect,
    // BFormGroup,
    // BFormInput,
    // BButton,
    BCard,
    BRow,
    BModal,
    // BImg,
    BCol,
    BBadge,
    BAvatar,
    // BPagination,
    // FeatherIcon,
    ChangeVerificationModal,
    EditUserModal,
    SmsUserModal,
    EmailUserModal,
    InternalMessageModal,
    PushNotificationsModal,
    ResetPasswordModal,
    ViewBvnModal,
    ChangeEmailVerificationModal,
    DeleteUserConfirmation,
    ImageCropper
  },
  data() {
    return {
      userId: null,
      response: null,
      openCropper: false,
      image: null,
      imageObject: null
    };
  },
  async created() {
    this.userId = this.$route.params.userId;
    await this.getData();
  },
  computed: {
    isViewBvnPermitted() {
      const permissions = this.$store.state.auth.permissions;
      return isPermitted(USER_PERMISSIONS.VIEW_BVN, permissions);
    },
    isInternalMessagePermitted() {
      const permissions = this.$store.state.auth.permissions;
      return isPermitted(USER_PERMISSIONS.VIEW_BVN, permissions);
    }
  },
  methods: {
    getData() {
      const [res, apiRequest] = useApi(`users/${this.userId}`);
      apiRequest();
      this.response = res;
    },
    getFormattedAmount(num) {
      return num?.toLocaleString();
    },
    resetPassword() {
      resetUserPassword({ email: this.response.data.email })
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast(
              "Success",
              "success",
              "An email has been sent to the use to change their password"
            );
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    remove() {
      deleteUser(this.userId)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast("Success", "success", "User successfully deleted");
            this.$router.push("/users");
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    getImageData(e) {
      const input = e.target;
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = function() {
          const dataUrl = reader.result;
          resolve(dataUrl);
        };
        reader.readAsDataURL(input.files[0]);
        this.resetFileInput();
      });
    },
    async onFileSelected(e) {
      console.log("E ", e.target.files[0]);
      const fileObject = e.target.files[0];
      this.imageObject = {
        name: fileObject.name,
        mimetype: fileObject.type
      };
      this.image = await this.getImageData(e);
      this.openCropper = true;
    },
    addImage(image) {
      updateProfile({
        userid: this.userId,
        profilePhoto: image
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.getData();
          }
        });

      this.close()
    },
    close() {
      this.openCropper = false;
    },
    resetFileInput() {
      const form = document.getElementById("form");
      form.reset();
    },

    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant
        }
      });
    }
  }
};
</script>

<style scoped>
.w-full {
  width: 100%;
}

.item-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.item-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-left: 8px;
}

.value {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
}

.item {
  margin-top: 14px;
}
</style>
