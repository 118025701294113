var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-transaction",attrs:{"no-body":""}},[_c('b-card-header',[_c('div',[_c('b-card-title',[_vm._v("Top Fundraisers")]),_c('b-card-text',{staticClass:"font-small-2"},[_vm._v(" "+_vm._s(_vm.selectedFilter)+" ")])],1),_c('b-dropdown',{staticClass:"chart-dropdown",attrs:{"variant":"link","no-caret":"","toggle-class":"p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body cursor-pointer",attrs:{"icon":"MoreVerticalIcon","size":"18"}})]},proxy:true}])},_vm._l((_vm.filterOptions),function(option){return _c('b-dropdown-item',{key:option,on:{"click":function($event){_vm.selectedFilter = option}}},[_vm._v(" "+_vm._s(option)+" ")])}),1)],1),_c('b-card-body',[(_vm.response.isLoading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100"},[_c('feather-icon',{staticClass:"spinner",attrs:{"icon":"LoaderIcon","size":"60"}})],1):_vm._l((_vm.response.data),function(fundraiser){return _c('div',{key:fundraiser.id,staticClass:"transaction-item"},[_c('b-media',{staticClass:"flex-grow-1 mr-1",attrs:{"no-body":""}},[_c('b-media-body',[_c('div',{staticClass:"d-flex"},[_c('h6',{staticClass:"transaction-title text-truncate flex-grow-1"},[_vm._v(" "+_vm._s(fundraiser.id)+" ")])]),_c('small',{staticClass:"round font-weight-bold d-inline-block",style:({
                padding: '2px 6px',
                fontSize: '8px',
                backgroundColor: _vm.randomColor({
                  luminosity: 'light',
                  seed: ("" + (fundraiser.category.categoryname)),
                  format: 'rgba',
                  alpha: 0.3,
                }),
                color: _vm.randomColor({
                  luminosity: 'bright',
                  seed: ("" + (fundraiser.category.categoryname)),
                }),
              })},[_vm._v(" "+_vm._s(fundraiser.category.categoryname)+" ")])])],1),_c('div',{staticClass:"font-weight-bold text-body-heading"},[_vm._v(" ₦"+_vm._s(fundraiser.raisedAmount.toLocaleString())+" ")])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }