<template>
  <div class="">
    <b-card>
      <div
        style="display: flex; justify-content: space-between; border-bottom: 1px solid #f5f6f9; padding-bottom: 1rem"
      >
        <div style="font-size: 18px; color: #5E5873;">
          {{ fundraiserData.title }}
        </div>
        <div>
          <feather-icon
            v-b-modal.add-modal
            icon="PlusIcon"
            size="20"
            style="cursor: pointer"
          />
        </div>
      </div>
      <div
        style="max-width: 500px; margin: auto"
        class="mt-2 mb-2"
      >
        <!-- <form action="">
          <b-form-group label="Beneficiary" label-for="beneficiary">
            <b-form-input
              placeholder="Beneficiary"
              id="beneficiary"
              disabled
              v-model="beneficiary"
              :value="getBenDetails().fullname"
            />
            <b-form-input
              placeholder="https://"
              id="link"
              class="mt-1"
              disabled
              v-model="getBenDetails().link"
            />
            <div class="font-small-2 text-muted">
              This could be a link to a public profile, article or post about
              the beneficiary
            </div>
          </b-form-group>

          <b-form-group label="Observers" label-for="observer">
            <b-form-input
              placeholder="Observer"
              id="observer"
              v-model="observer"
            />
          </b-form-group>
        </form> -->
        <!-- {{ fundraiserData.members }} -->
        <div
          v-for="(member, index) in fundraiserData.members"
          :key="index"
          class="p-1 mt-1"
          style="background: #E0F2FF; width: 100%; border-radius: 4px"
        >
          <feather-icon
            v-if="member.memberType !== 'BENEFICIARY'"
            icon="XIcon"
            size="20"
            style="cursor: pointer; float: right"
            @click="remove(member)"
          />
          <div v-if="member.userdetail">
            <div style="font-size: 16px">
              <span style="color: #161722">
                {{ member.userdetail.firstname }}
                {{ member.userdetail.lastname }}
              </span>
              <span class="text-muted">@{{ member.userdetail.username }}</span>
            </div>
            <div class="text-muted font-small">
              {{ member.memberType }}
            </div>
          </div>
          <div v-else>
            <div style="font-size: 16px">
              <span style="color: #161722">
                {{ member.fullname }}
              </span>
              <span class="text-muted">{{ member.link }}</span>
            </div>
            <div class="text-muted font-small">
              {{ member.memberType }}
            </div>
          </div>
          <!-- <div v-else>
            <div style="font-size: 16px">
              <span style="color: #161722">
                N/A
              </span>
              <span class="text-muted">N/A</span>
            </div>
            <div class="text-muted font-small">{{ member.memberType }}</div>
          </div> -->
        </div>
      </div>
    </b-card>
    <b-modal
      id="add-modal"
      v-model="addModal"
      centered
      hide-footer
      title="Add Member"
    >
      <b-form @submit.prevent="registerMember">
        <b-form-group
          class="mt-2"
          label="Select member type"
        >
          <b-form-select
            v-model="form.memberType"
            :options="options"
            @change="form.fullname = '', form.memberId = ''"
          />
        </b-form-group>
        <b-form-group
          v-if="form.memberType == 'BENEFICIARY'"
          label="Enter member's name"
        >
          <b-form-input v-model="form.fullname" />
        </b-form-group>
        <autocomplete
          v-if="form.memberType == 'OBSERVER'"
          class="mb-2"
          title="Select member"
          @itemSelected="selectHandler"
        />

        <b-form-group
          v-if="form.memberType == 'BENEFICIARY'"
          label="Link"
        >
          <b-form-input v-model="form.link" />
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
        >
          Add Member
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText, BForm,
} from "bootstrap-vue";
import { fetchFundraiserFlags, getFundDetails, addMember, deleteMember } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Autocomplete from '@/@core/components/common/Autocomplete.vue';
dayjs.extend(relativeTime);
const flatObject = (object) => {
  let result = {};

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key]?.constructor === Object) {
        result = { ...result, ...flatObject(object[key]) };
      } else {
        result[key] = object[key];
      }
    }
  }

  return result;
};

export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
    Autocomplete, BForm,
  },
  data() {
    return {
      fundraiserId: null,
      blessors: [],
      flags: [],
      beneficiary: "",
      fundraiserData: "",
      form: {},
      observer: "",
      options: ['OBSERVER', 'BENEFICIARY'], addModal: false,
    };
  },
  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getFundraiserDetails();
  },
  methods: {
    dayjs,
    selectHandler(data) {
      this.form.memberId = data.userid.toString();
      this.form.fullname = data.firstname + ' ' + data.lastname
      
    },
    getFundraiserDetails() {
      this.loading = true;
      getFundDetails(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.fundraiserData = response.data;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    registerMember() {
      this.form.fundraiserId =this.fundraiserId
      addMember(this.form)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast("Success", "success", response.message);
            this.getFundraiserDetails()
            // this.getFundraiserBlessors()
           this.addModal = false
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    remove(member) {
      deleteMember(member.id)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast(
              "Success",
              "success",
              "Member successfully deleted"
            );
            this.getFundraiserDetails()
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    getBenDetails(){
        if (this.fundraiserData && !this.fundraiserData.isOrganiserBeneficiary){
            const { fullname, link } = this.fundraiserData.members.find(item => item.memberType === 'BENEFICIARY')
            return { fullname, link}
        }
        return { fullname: null, link: null}
    }
  },
};
</script>

<style scoped>
.reward {
  padding: 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
</style>
