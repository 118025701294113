<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-dropdown
        :text="selectedFilter"
        size="sm"
        class="budget-dropdown"
        variant="outline-primary"
      >
        <b-dropdown-item
          v-for="option in filterOptions"
          :key="option"
          @click="selectedFilter = option"
        >
          {{ option }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body class="statistics-body">
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center"
      >
        <feather-icon
          icon="LoaderIcon"
          class="spinner"
          size="60"
        />
      </div>
      <b-row v-else>
        <b-col
          v-for="item in statisticsItems"
          :key="item.key"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.sign }}{{ formatNumber(response.data[item.key]) }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { useApi } from '@core/comp-functions/useApi'
import { formatNumber } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const filterOptions = ['Today', 'This week', 'This Month', 'This Year']
const [res, apiRequest] = useApi('statistics')
const statisticsItems = [{
  icon: 'TrendingUpIcon', sign: '', color: 'light-primary', key: 'totalBlessings', subtitle: 'Blessings', customClass: 'mb-2 mb-xl-0',
}, {
  icon: 'DollarSignIcon', sign: '₦', color: 'light-danger', key: 'totalWithdrawals', subtitle: 'Withdrawals', customClass: 'mb-2 mb-xl-0',
}, {
  icon: 'BoxIcon', sign: '', color: 'light-success', key: 'totalFundraisers', subtitle: 'Fundraisers', customClass: 'mb-2 mb-sm-0',
}, {
  icon: 'UserIcon', sign: '', color: 'light-info', key: 'totalUsers', subtitle: 'Signed Up', customClass: '',
}]

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
  },

  data() {
    return {
      response: res,
      selectedFilter: filterOptions[3],
      statisticsItems,
    }
  },

  computed: {
    filterOptions: () => filterOptions,
  },

  watch: {
    'response.isFailure': function (isFailure) {
      if (isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: this.response.error || 'Failed to fetch resource',
            icon: 'TrashIcon',
            variant: 'danger',
          },
        })
      }
    },

    selectedFilter: {
      handler(value) {
        apiRequest({ filter: value.replace(/^This\s+/, '').toUpperCase() })
      },
      immediate: true,
    },
  },

  methods: {
    formatNumber,
  },
}
</script>
