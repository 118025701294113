<template>
  <div>
    <b-card>
      <app-collapse accordion>
        <app-collapse-item title="Search & Filter">
          <form @submit="onSubmit">
            <b-row>
              <b-col
                md="6"
                xl="4"
                class="mb-0"
              >
                <!-- basic -->
                <autocomplete
                  title="User"
                  @itemSelected="selectHandler"
                />
              </b-col>

              <b-col
                md="6"
                xl="4"
                class="mb-0"
              >
                <!-- basic -->
                <b-form-group
                  label="Fundraiser ID"
                  label-for="fundraiserId"
                >
                  <b-form-input
                    id="fundraiserId"
                    v-model="filters.fundraiserId"
                    placeholder="Fundraiser ID"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="4"
                class="mb-0"
              >
                <!-- basic -->
                <b-form-group
                  label="Transaction Ref"
                  label-for="transactionReference"
                >
                  <b-form-input
                    id="transactionReference"
                    v-model="filters.transactionReference"
                    placeholder="Transaction Reference"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="4"
                class="mb-0"
              >
                <!-- basic -->
                <b-form-group
                  label="Date"
                  label-for="date"
                >
                  <date-range-picker
                    v-model="filters.dateRange"
                    style="width: 100%"
                    :start-date="startDate"
                    :end-date="endDate"
                    :locale-data="locale"
                    @update="setDateRange"
                  >
                    <!--Optional scope for the input displaying the dates -->

                    <template
                      v-slot:input="picker"
                      style="min-width: 350px"
                    >
                      {{
                        new Date(picker.startDate)
                          .toISOString()
                          .substring(0, 10)
                      }}
                      -
                      {{
                        new Date(picker.endDate).toISOString().substring(0, 10)
                      }}
                    </template>
                  </date-range-picker>
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="4"
                class="mb-0"
              >
                <!-- basic -->
                <b-form-group
                  label="Transaction Type"
                  label-for="transactionType"
                >
                  <b-form-select
                    id="transactionType"
                    v-model="filters.transactionType"
                    :options="transtypeoptions"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="4"
                class="mb-0"
              >
                <!-- basic -->
                <b-form-group
                  label="Amount"
                  label-for="amount"
                >
                  <b-form-input
                    id="amount"
                    v-model="filters.amount"
                    placeholder="Amount"
                    type="number"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="4"
                class="mb-0"
              >
                <!-- basic -->
                <b-form-group
                  label="Payment System"
                  label-for="paymentChannel"
                >
                  <b-form-select
                    id="paymentChannel"
                    v-model="filters.paymentChannel"
                    :options="paysysoptions"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                xl="4"
                class="mb-0"
              >
                <!-- basic -->
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <b-form-select
                    id="status"
                    v-model="filters.status"
                    :options="statuses"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div
              class=""
              style="text-align: right"
            >
              <b-button
                type="submit"
                variant="primary"
              >
                Filter
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-1"
                @click="reset"
              >Reset</b-button>
            </div>
          </form>
        </app-collapse-item>
      </app-collapse>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import dayjs from "dayjs";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Autocomplete from "@/@core/components/common/Autocomplete.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem, Autocomplete, DateRangePicker
  },
  data() {
    return {
      daterange: "",
      startDate: new Date ("2022-01-01"),
      endDate: new Date("2022-12-31"),
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      paysysoptions: [
        { value: null, text: "Select category" },
        { value: "PAYSTACK", text: "Paystack" },
        { value: "MONNIFY", text: "Monnify" },
        { value: "FLUTTERWAVE", text: "Flutterwave" },
      ],
      transtypeoptions: [
        { value: null, text: "Select category" },
        { value: "DONATION", text: "Donation" },
        { value: "WITHDRAWAL", text: "Withdrawal" },
        { value: "TOP UP", text: "Top Up" },
        { value: "PROMOTION", text: "Promotion" },
      ],
      statuses: [
        { value: null, text: "Select category" },
        { value: "INITIALIZED", text: "Initialized" },
        { value: "PAID", text: "Paid" },
        { value: "UNSUCCESSFUL", text: "Unsuccessful" },
      ],
      filters: {
        userId: "",
        name: "",
        fundraiserId: "",
        transactionReference: "",
        transactionType: "",
        amount: "",
        date: "",
        userCategory: "",
        status: "",
        startDate: "",
        endDate: "",
        dateRange: {},
        paymentChannel: "",
      },
    };
  },
  methods: {
    dayjs,
    onSubmit(e) {
      e.preventDefault();
      const filterKeys = Object.keys(this.filters);
      let query = {};
      Object.values(this.filters).forEach((filter, index) => {
        if (filter.length) {
          query = { ...query, [filterKeys[index]]: filter };
        }
      });
      this.$emit("filter", query);
    },
    reset() {
      const filterKeys = Object.keys(this.filters);
      let filters = {};
      Object.values(this.filters).forEach((filter, index) => {
        if (filter.length) {
          filters = { ...filters, [filterKeys[index]]: "" };
        }
      });
      this.filters = filters;
      this.$emit("reset");
    },
    selectHandler(data){
      this.filters.userId = data.userid.toString()
    },
    setDateRange(value) {
      this.filters.startDate = new Date(value.startDate).toISOString().substring(0,10)
      this.filters.endDate = new Date(value.endDate).toISOString().substring(0,10)
      
    },
  },
};
</script>

<style scoped></style>
