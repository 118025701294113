<template>
  <b-modal
    id="change-email-verification"
    centered
    hide-footer
  >
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Change Email Verification
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Update user email verification
      </div>
      <div>
        <div
          class="verification"
          :class="selectedType == 0 ? 'selected' : ''"
          @click="selectVerification(0)"
        >
          <b-row>
            <b-col cols="3">
              <feather-icon
                icon="MinusCircleIcon"
                size="40"
                class="ml-2 mt-1"
              />
            </b-col>
            <b-col>
              <div class="header">
                Unverified
              </div>
              <div class="description">
                This is the default state when user hasn't verified their email.
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          class="verification mt-2"
          :class="selectedType == 1 ? 'selected' : ''"
          @click="selectVerification(1)"
        >
          <b-row>
            <b-col cols="3">
              <feather-icon
                icon="CheckCircleIcon"
                size="40"
                class="ml-2 mt-1"
              />
            </b-col>
            <b-col>
              <div class="header">
                Verified
              </div>
              <div class="description">
                This badge is assigned to users whose email has been verified
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button
          variant="primary"
          @click="updateVerification"
        >
          Submit
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          Discard
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
  import {
    BTable,
    //   BFormCheckbox,
    BCard,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    BFormInput,
    BModal,
    BButton,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BPagination,
  } from "bootstrap-vue";
  import { updateEmailVerificationStatus } from '@/service/api.service';
  
  export default {
    name: "ChangeVerificationModal",
    components: {
      BTable,
      // BButton,
      // BFormCheckbox,
      BFormSelect,
      BFormGroup,
      BFormInput,
      BButton,
      BCard,
      BRow,
      BModal,
      BImg,
      BCol,
      BBadge,
      BAvatar,
      BPagination,
      BFormTextarea,
    },
  
    props: {
      email: {
        type: String
      },
      isEmailVerified: {
        type: Boolean
      }
    },
  
    data() {
      return {
        selectedType: this.isEmailVerified,
      };
    },
  
    methods: {
      selectVerification(type) {
        this.selectedType = type;
      },
      updateVerification(){
        updateEmailVerificationStatus({ email: this.email, isEmailVerified: this.selectedType })
        .then(res => res.json())
        .then(response => {
          this.$emit('refresh')
        })
      }
    },
  };
  </script>
  
  <style scoped>
  .verification {
    padding: 20px;
    border-radius: 6px;
    background: rgba(186, 191, 199, 0.12);
    cursor: pointer;
  }
  
  .selected {
    border: 1px solid #7367f0 !important;
    background: rgba(115, 103, 240, 0.12) !important;
    color: #7367f0 !important;
  }
  
  .header {
    font-weight: 500;
    font-size: 21px;
  }
  
  .description {
    font-weight: 400;
    font-size: 14px;
  }
  </style>
  