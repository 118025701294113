<template>
  <b-modal
    id="view-flag"
    ref="view-flag"
    centered
    hide-footer
  >
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Report Details
      </div>
      <div class="mt-1">
        <div
          v-if="flag"
          style="font-size: 18px"
        >
          {{ flag.reason }}
        </div>
        <div
          v-if="flag"
          class="mt-1"
        >
          {{ flag.description }}
        </div>
      </div>
    </div>
    <div class="text-center mt-1 mb-2">
      <b-button
        variant="primary"
        style="text-transform: capitalize"
        @click="closeModal"
      >Done</b-button>
      <b-button
        variant="outline-secondary"
        class="ml-1"
        style="text-transform: capitalize"
        @click="closeModal"
      >Discard</b-button>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  props: {
    flag: {
      type: Object,
    },
  },

  data() {
    return {
      question: null,
      answer: null,
    };
  },

  watch: {
    data(data) {
      this.question = data.question;
      this.answer = data.answer;
    },
  },

  methods: {
    closeModal() {
      this.$nextTick(() => {
        this.$refs["view-flag"].toggle("#toggle-btn");
      });
    },
  },
};
</script>

<style scoped></style>
