<template>
  <b-modal id="edit-reward" ref="edit-reward" centered hide-footer>
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Edit Reward
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Please use the form below to edit the fundraiser rewards
      </div>
      <form @submit="onSubmit">
        <div>
          <b-form-group label="Blessing Amount" label-for="blessingAmount">
            <b-form-input
              placeholder=""
              id="blessingAmount"
              v-model="blessingAmount"
            />
          </b-form-group>
        </div>
        <div class="mt-2">
          <label for="message">Reward</label>
          <b-form-textarea
            id="reward"
            placeholder="Type reward here"
            rows="4"
            v-model="reward"
          />
        </div>

        <div class="mb-0 text-center mt-2">
          <!-- basic -->
          <b-button variant="primary" type="submit">Update Reward</b-button>
          <b-button variant="outline-secondary" class="ml-1" @click="closeModal"
            >Discard</b-button
          >
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { updateReward } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  props: {
    rewardObj: {
      type: Object,
    },
  },

  watch: {
    rewardObj(value) {
      this.blessingAmount = value.blessingAmount;
      this.reward = value.reward;
    },
  },

  data() {
    return {
      blessingAmount: null,
      reward: "",
    };
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      updateReward(this.rewardObj.id, {
        reward: this.reward,
        blessingAmount: this.blessingAmount,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.$emit("refresh");
            this.showToast("Success", "success", response.message);
            this.closeModal();
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },

    closeModal() {
      this.$nextTick(() => {
        this.$refs["edit-reward"].toggle("#toggle-btn");
      });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    onChange(value, id) {
      this[id] = value;
    },
  },
};
</script>

<style scoped></style>
