<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'

import { getCommissions, updateCommissions } from '@/service/api.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BButton,
  },
  data() {
    return {
      commissions: [],

      basic: '',
      standard: '',
      vip: '',
      charity: '',
      promoteAndEarnCommission: '',
      fundraiserEarlyWithdrawal: '',
      promoteAndEarnServiceCharge: '',

    }
  },
  mounted() {
    this.fetchCommissions()
  },
  methods: {
    fetchCommissions() {
      this.loading = true
      // eslint-disable-next-line no-undef
      getCommissions()
        .then(res => res.json())
        .then(response => {
          this.loading = false

          if (response.valid) {
            this.commissions = response.data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    update() {
      this.loading = true
      updateCommissions({ commissions: this.commissions })
        .then(res => res.json())
        .then(response => {
          this.loading = false

          if (response.valid) {
            this.showToast(
              'Success',
              'success',
              'Commissions updated successfully!',
            )
          }
        }).catch(e => {
          this.loading = false
          this.showToast('Error', 'danger', e)
        })
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: title === 'Error' ? 'TrashIcon' : 'DoneIcon',
          variant,
        },
      })
    },
  },
}
</script>
<template>
  <div>
    <b-card>
      <h3>Charges Settings</h3>
      <hr class="mb-5">

      <!-- <h4>Fundraiser Commission</h4>
      <hr>
      <b-form class="">
        <b-row
          align-v="center"
        >
          <b-col md="2">
            <p class="font-weight-bold">
              Basic:
            </p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="basic"
                type="number"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row
          align-v="center"
        >
          <b-col md="2">
            <p class="font-weight-bold">
              Standard:
            </p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="standard"
                type="number"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row
          align-v="center"
        >
          <b-col md="2">
            <p class="font-weight-bold">
              VIP:
            </p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="vip"
                type="number"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row
          align-v="center"
        >
          <b-col md="2">
            <p class="font-weight-bold">
              Charity:
            </p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="charity"
                type="number"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <b-col md="12">

            <h4 class="mt-3">
              Other Charges
            </h4>
            <hr>
          </b-col>
        </b-row>
        <b-row
          align-v="center"
        >
          <b-col md="2">
            <p class="font-weight-bold">
              Promote and Earn Default Commission:
            </p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="promoteAndEarnCommission"
                type="number"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row
          align-v="center"
        >
          <b-col md="2">
            <p class="font-weight-bold">
              Fundraiser Early Withdrawal Charges:
            </p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="fundraiserEarlyWithdrawal"
                type="number"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row
          align-v="center"
        >
          <b-col md="2">
            <p class="font-weight-bold">
              PnE Withdrawal Service Charge:
            </p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="promoteAndEarnServiceCharge"
                type="number"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-button variant="primary mt-2">
          Save Changes
        </b-button>
      </b-form> -->

      <h4>Fundraiser Commission</h4>
      <hr>
      <b-form @submit.prevent="update">
        <b-row
          v-for="(commission, index) in commissions.slice(0,4)"
          :key="index"
          align-v="center"
        >
          <b-col md="2">
            <p>{{ commission.title }}</p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="commission.commission"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>

        <h4 class="mt-3">Other Charges</h4>
        <hr>
        <b-row
          v-for="(commission, index) in commissions.slice(4)"
          :key="index"
          align-v="center"
        >
          <b-col md="2">
            <p>{{ commission.title }} </p>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <b-form-input
                v-model="commission.commission"
                required
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="3">
            <b-button
              v-if="commissions.length"
              class="mt-4"
              block
              variant="primary"
              type="submit"
            >
              Save Changes
            </b-button>

          </b-col>
        </b-row>

      </b-form>
    </b-card>
  </div>
</template>
