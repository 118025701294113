/* eslint-disable */
export const data = (blessings, commissions, blessingsTotal, commissionsTotal) => {
  return {
    revenue: {
      years: ['2020', '2019', '2018'],
      price: blessingsTotal.toLocaleString(),
      budget: commissionsTotal.toLocaleString(),
      revenueReport: {
        series: [
          {
            name: 'Blessing',
            data: blessings,
          },
          {
            name: 'Commission',
            data: commissions,
          },
        ],
      },
      budgetChart: {
        series: [
          {
            data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
          },
          {
            data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
          },
        ],
      },
    },
  }
}
