import { render, staticRenderFns } from "./PushNotificationsModal.vue?vue&type=template&id=0ef44615&scoped=true&"
import script from "./PushNotificationsModal.vue?vue&type=script&lang=js&"
export * from "./PushNotificationsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef44615",
  null
  
)

export default component.exports