<template>
  <b-modal
    id="create-fundraiser-type"
    ref="create-fundraiser-type"
    centered
    hide-footer
  >
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Create Fundraiser
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Please select the fundraiser type below
      </div>
      <div>
        <div
          class="verification onetime"
          @click="selectFundraiserType('onetime')"
        >
          <div
            v-if="selectedType === 'onetime'"
            class="text-right mr-1"
          >
            <img
              src="@/assets/icons/green-check.png"
              alt=""
              style="position: absolute"
            >
          </div>
          <b-row>
            <b-col>
              <div class="header headtextcolor">
                One-Off
              </div>
              <div class="description">
                I’d like to create a fundraiser for a specific event/situation.
                E.g. Wedding, Emergency, etc
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          class="verification mt-2 recurring"
          @click="selectFundraiserType('recurring')"
        >
          <div
            v-if="selectedType === 'recurring'"
            class="text-right mr-1"
          >
            <img
              src="@/assets/icons/green-check.png"
              alt=""
              style="position: absolute"
            >
          </div>
          <b-row>
            <b-col>
              <div class="header headtextcolor">
                Permanent
              </div>
              <div class="description">
                I'd like to create a permanent fundraiser page for my organisation or charity.
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="mb-0 text-right mt-2">
        <!-- basic -->
        <b-button
          variant="outline-secondary"
          @click="closeModal"
        >Cancel</b-button>
        <b-button
          variant="primary"
          class="ml-1"
          @click="routeTo"
        >Continue</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { updateVerificationStatus } from "@/service/api.service";

export default {
  name: "ChangeVerificationModal",
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  props: {
    userId: {
      type: String,
    },
    verificationStatus: {
      type: String,
    },
  },

  data() {
    return {
      selectedType: "onetime",
    };
  },

  methods: {
    selectFundraiserType(type) {
      this.selectedType = type;
    },

    routeTo() {
      if (this.selectedType === "onetime") {
        this.$router.push("/fundraisers/onetime/create");
      } else if (this.selectedType === "recurring") {
        this.$router.push("/fundraisers/permanent/create");
      } else {
        this.closeModal();
      }
    },

    closeModal() {
      this.$nextTick(() => {
        this.$refs["create-fundraiser-type"].toggle("#toggle-btn");
      });
    },
  },
};
</script>

<style scoped>
.verification {
  padding: 20px;
  border-radius: 6px;
  background: rgba(186, 191, 199, 0.12);
  cursor: pointer;
  height: 8.5rem;
}

.selected {
  border: 1px solid #7367f0 !important;
  background: rgba(115, 103, 240, 0.12) !important;
  color: #7367f0 !important;
}

.header {
  font-weight: 500;
  font-size: 16px;
}

.description {
  font-weight: 400;
  font-size: 14px;
}

.onetime {
  background: #e0f2ff;
  border: 1px solid #a8bacb;
}
.recurring {
  background: #feedc8;
  border: 1px solid #ffb001;
}
.headtextcolor {
  color: #161722;
}
</style>
