<template>
  <div>
    <b-row>
      <b-col>
        <label for="message">{{ title }}</label>
        <vue-autosuggest
          v-model="currentUser"
          :suggestions="filteredOptions"
          :limit="10"
          :input-props="{
            id: 'autosuggest__input',
            class: 'form-control',
            placeholder: 'Search by username, email, firstname, lastname',
          }"
          :render-suggestion="renderSuggestion"
          @input="onInputChange"
          @selected="selectHandler"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { BCard, BRow, BCol, BAvatar, BCardText } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { searchUsers } from "@/service/api.service";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardText,
    VueAutosuggest,
  },
  data() {
    return {
      filteredOptions: [],
      currentUser: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "Organiser",
    },
    type: {
      type: String,
      default: "individual"
    }
  },
  methods: {
    selectHandler(data) {
      this.currentUser = `${data.item.firstname} ${data.item.lastname}`;
      this.$emit("itemSelected", data.item);
    },
    renderSuggestion(suggestion) {
      return (
        <div class="d-flex align-items-center mt-1 cursor-pointer">
          <b-avatar
            text={this.getAvatarText(suggestion.item)}
            variant="light-danger"
            class="mr-50"
          ></b-avatar>
          <div class="detail">
            <b-card-text class="mb-0">
              {suggestion.item.firstname} {suggestion.item.lastname}
            </b-card-text>
            <small class="text-muted">@{suggestion.item.username}</small>
          </div>
        </div>
      );
    },
    onInputChange(query) {
      if (query.length > 1) {
        searchUsers(query)
          .then((res) => res.json())
          .then((response) => {
            if (response.valid) {
              if(this.type == 'organisation'){
                const data = response.data.filter(item => item.rcnumber)
                this.filteredOptions = [
                  {
                    data
                  }
                ]
              }else{
                this.filteredOptions = [
                  {
                    data: response.data,
                  },
                ];

              }
            }
          })
          .catch((e) => console.error(e));
      }
    },
    getAvatarText(item) {
      const avatarText = `${item.firstname.charAt(0)} ${item.lastname.charAt(
        0
      )}`;
      return avatarText.toUpperCase();
    },
  },
};
</script>
