<template>
  <div>
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center h-100 mt-16"
      style="margin-top: 4rem"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="60"
      />
    </div>
    <div v-else>
      <b-card style="min-height: 40rem">
        <div
          style="display: flex; justify-content: space-between; border-bottom: 1px solid #f5f6f9; padding-bottom: 1rem"
        >
          <div style="font-size: 18px; color: #5E5873;">
            {{ fundraiserData.title }}
          </div>
          <div>
            <feather-icon
              v-b-modal.edit-fundraiser-overview
              icon="EditIcon"
              size="20"
              style="cursor: pointer"
              @click="setFundraiser"
            />
          </div>
        </div>
        <div>
          <div style="border-bottom: 1px solid #f5f6f9">
            <b-row class="mb-3 mt-2">
              <b-col cols="12">
                <img
                  v-if="fundraiserData.fundraiserType == 'PERMANENT'"
                  :src="fundraiserData.coverImage"
                  width="100%"
                  alt=""
                  class="mb-2"
                >
              </b-col>
              <b-col cols="6">
                <div>
                  <span style="font-weight: 500">Fundraiser ID: </span>
                  <span>{{ fundraiserData.id }}</span>
                </div>
                <div class="mt-1">
                  <span style="font-weight: 500">Organiser: </span>
                  <span>{{ fundraiserData.userdetail.firstname }}
                    {{ fundraiserData.userdetail.lastname }}</span>
                </div>
                <div class="mt-1">
                  <span style="font-weight: 500">Start Date: </span>
                  <span>{{ fundraiserData.createdAt }}</span>
                </div>
                <div class="mt-1">
                  <span style="font-weight: 500">Target: </span>
                  <span>{{
                    getFormattedAmount(fundraiserData.targetAmount)
                  }}</span>
                </div>
                <div class="mt-1">
                  <span style="font-weight: 500">Fundraiser URL: </span>
                  <span class=""> <a
                    target="_blank"
                    :href="`https://blessmi.com/fundraiser/${slug}}`"
                  >https://blessmi.com/fundraiser/{{ slug }}</a> <feather-icon
                    class="cursor-pointer"
                    icon="CopyIcon"
                    @click="copyText(`https://blessmi.com/fundraiser/${slug}`)"
                  /></span>
                </div>
              </b-col>
              <b-col cols="6">
                <div>
                  <span style="font-weight: 500">Status: </span>
                  <span>{{ fundraiserData.status }}</span>
                </div>
                <div class="mt-1">
                  <span style="font-weight: 500">Category: </span>
                  <span>{{ fundraiserData.category.categoryname }}</span>
                </div>
                <div class="mt-1">
                  <span style="font-weight: 500">End Date: </span>
                  <span>{{ fundraiserData.nextPayoutDate }}</span>
                </div>
                <div class="mt-1">
                  <span style="font-weight: 500">Raised: </span>
                  <span>{{
                    getFormattedAmount(fundraiserData.raisedAmount)
                  }}</span>
                </div>
                <div class="mt-1">
                  <span style="font-weight: 500">Remaining days: </span>
                  <span>{{
                    getTime()
                  }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
          <div
            class="mt-3 pb-3"
            style="border-bottom: 1px solid #f5f6f9"
          >
            <span style="font-weight: 500">Description: </span>
            <!-- <span>{{ fundraiserData.fundraiserDesc }}</span> -->
            <div v-html="fundraiserData.fundraiserDesc" />

            <hr>

            <b-tabs class="mt-4">

              <b-tab v-if="fundraiserData.fundraiserType == 'PERMANENT'">
                <template #title>
                  <span>Gallery</span>
                </template>
                <b-row>
                  <b-col
                    v-for="(image, index) in fundraiserData.images"
                    :key="index"
                    cols="3"
                    class="mb-2"
                  >
                    <img
                      :src="image.imageurl"
                      width="100%"
                      alt=""
                    >
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab v-if="fundraiserData.fundraiserType == 'PERMANENT' && fundraiserData.teammembers.length">
                <template #title>
                  <span>Team Members</span>
                </template>
                <b-row
                  v-for="(member, index) in fundraiserData.teammembers"
                  :key="index"
                  class="p-1 mb-2"
                  style="border: 1px dashed #ddd; border-radius: 8px;"
                >
                  <b-col cols="3">
                    <img
                      :src="member.image"
                      alt=""
                      style="width: 100%"
                    >
                  </b-col>
                  <b-col cols="9">
                    <h4>{{ member.name }}</h4>
                    <h6>{{ member.role }}</h6>
                    <p>{{ member.description }}</p>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab v-if="fundraiserData.fundraiserType == 'PERMANENT' && fundraiserData.impactstories.length">
                <template #title>
                  <span>Impact Stories</span>
                </template>
                <b-row
                  v-for="(story, index) in fundraiserData.impactstories"
                  :key="index"
                  class="p-1 mb-2"
                  style="border: 1px dashed #ddd; border-radius: 8px;"
                >
                  <b-col cols="3">
                    <img
                      :src="story.image"
                      alt=""
                      style="width: 100%"
                    >
                  </b-col>
                  <b-col cols="9">
                    <h4>{{ story.title }}</h4>
                    <p>{{ story.description }}</p>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab v-if="fundraiserData.fundraiserType == 'PERMANENT'">
                <template #title>
                  <span>Contact</span>
                </template>
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-1"
                    /> {{ fundraiserData.contactPhoneNumber }}
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      class="mr-1"
                    /> {{ fundraiserData.contactAddress }}
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <feather-icon
                      icon="MailIcon"
                      class="mr-1"
                    /> {{ fundraiserData.contactEmail }}
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-card>
    </div>
    <action-modal
      :action="action"
      @action="actOnFundraiser($event)"
    />
    <edit-fundraiser-overview
      :fundraiser="fundraiserObj"
      @refresh="getFundraiserDetails"
    />
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText,
  BCarousel,
  BCarouselSlide,
  BProgress,
} from "bootstrap-vue";
import FundraiserOverview from "@/@core/components/fundraisers/FundraiserOverview.vue";
import Blessors from "@/@core/components/fundraisers/Blessors.vue";
import Rewards from "@/@core/components/fundraisers/Rewards.vue";
import Comments from "@/@core/components/fundraisers/Comments.vue";
import { getFundDetails } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dayjs from "dayjs";
import ActionModal from "../../../@core/components/fundraisers/modals/ActionModal.vue";
import EditFundraiserOverview from "../edit/EditFundraiserOverview.vue";
export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
    FundraiserOverview,
    BCarousel,
    BCarouselSlide,
    BProgress,
    Blessors,
    Rewards,
    Comments,
    ActionModal,
    EditFundraiserOverview,
  },
  data() {
    return {
      fundraiserId: null,
      fundraiserData: null,
      fundraiserObj: null,
      loading: false,
      action: null,
    };
  },

  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getFundraiserDetails();
  },

  computed: {
    slug () {
      const { id, title } = this.fundraiserData;
      return `${id}-${title
        .trim()
        .toLowerCase()
        .replace(/[^a-z\d\s:]/g, '')
        .replace(/\s+/g, '-')}`;
    }
  },

  methods: {
    dayjs,
    getFundraiserDetails() {
      this.loading = true;
      getFundDetails(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.fundraiserData = response.data;
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.showToast("Error", "danger", e);
        });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    getFormattedAmount(amount) {
      return `N${amount.toLocaleString()}`;
    },
    setFundraiser() {
      this.fundraiserObj = this.fundraiserData;
    },
    // getTime () {
    //   const { createdAt, duration } = this.fundraiserData;
    //   console.log('Date created: ', createdAt, duration);
    //   if (this.fundraiserData.status == 'EXPIRED') return 'Expired';
    //   const daysLeft = Math.abs(
    //     dayjs().diff(dayjs(createdAt).add(duration, 'day'), 'day')
    //   );
    //   return `${daysLeft} days left`;
    // },
    getTime  () {
      const { createdAt, duration, nextPayoutDate } = this.fundraiserData;
      console.log('Date created: ', createdAt, duration);
      if (this.fundraiserData.status == 'EXPIRED') return 'Expired';
      if(dayjs().isAfter(dayjs(nextPayoutDate))) return 'Expired'
      let daysLeft = Math.abs(
        // dayjs().diff(dayjs(createdAt).add(duration, 'day'), 'day')
        dayjs().diff(dayjs(nextPayoutDate), 'day')
      );
      daysLeft += 1;

      if (daysLeft == 0) {
        return `Few hours left`;
        // this.showCountdown = true;
      }
      return `${daysLeft} ${daysLeft == 1 ? 'day' : 'days'} left`;
    },
    async copyText(text) {
      await navigator.clipboard.writeText(text);
      this.showToast("Success", 'success', 'Fundraiser link copied')
    }
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}

.image-card .card-body {
  padding: 0px !important;
}
</style>
