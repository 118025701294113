<template>
  <b-card no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="8" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            B/C Chart
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span
                class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Blessings</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span
                class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Commission</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="revenue.revenueReport.series"
        />
      </b-col>

      <b-col md="4" class="budget-wrapper">
        <b-dropdown
          :text="selectedFilter"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="option in filterOptions"
            :key="option"
            @click="selectedFilter = option"
          >
            {{ option }}
          </b-dropdown-item>
        </b-dropdown>

        <h2 class="mb-25 text-primary">&#8358;{{ revenue.price }}</h2>
        <h5 class="text-warning">&#8358;{{ revenue.budget }}</h5>
        <vue-apex-charts
          id="budget-chart"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="revenue.budgetChart.series"
        />

        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          View Analytics
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

import { data } from "./dashboard";
import { getBCData } from "../../service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const filterOptions = ["Today", "This week", "This Month", "This Year", 'All Time'];
export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      revenue: null,
      selectedFilter: filterOptions[3],
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            sparkline: { enabled: true },
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 5],
            width: [2, 2],
          },
          colors: [$themeColors.primary, $themeColors.warning],
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },

  async mounted() {
    await this.getBlessingsCommissionData();
  },

  methods: {
    getDataTotal(data, key) {
      const arr = data.reduce((acc, val) => acc + val[key], 0);
      return arr;
    },
    getDataArray(data, key) {
      const arr = [];
      data.forEach((item) => {
        arr.push(item[key]);
      });
      return arr;
    },
    loadXAxis(data) {
      const cats = [];
      data.forEach((item) => {
        const monthStr = this.months[new Date(item.date).getMonth()];
        if (cats.indexOf(monthStr) === -1) {
          cats.push(monthStr);
        }
      });
      this.revenueReport.chartOptions.xaxis.categories = cats;
    },
    getBlessingsCommissionData(filter = "YEAR") {
      getBCData(filter)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            const blessingsArray = this.getDataArray(response.data, "amount");
            const commissionsArray = this.getDataArray(
              response.data,
              "commission"
            );
            const blessingsTotal = this.getDataTotal(response.data, "amount");
            const commissionsTotal = this.getDataTotal(
              response.data,
              "commission"
            );
            this.loadXAxis(response.data);
            this.revenue = this.chartData(
              blessingsArray,
              commissionsArray,
              blessingsTotal,
              commissionsTotal
            ).revenue;
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) =>
          this.showToast(
            "Error",
            "danger",
            "Could not fetch Blessing Commission chart data"
          )
        );
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },

    handleChartFilterChange(e) {
      // this.getBlessingsCommissionData()
    },

    chartData(blessings, commissions, blessingsTotal, commissionsTotal) {
      return {
        revenue: data(blessings, commissions, blessingsTotal, commissionsTotal)
          .revenue,
      };
    },
  },

  computed: {
    filterOptions: () => filterOptions,
  },
  watch: {
    selectedFilter(value) {
      let filter = null;
      if (value === "Today") {
        filter = "TODAY";
      } else if (value === "This week") {
        filter = "WEEK";
      } else if (value === "This Month") {
        filter = "MONTH";
      } else if (value === 'This Year'){
        filter = "YEAR";
      }else if (value === 'All Time'){
        filter = 'ALL'
      }
      this.getBlessingsCommissionData(filter)
    },
  },
};
</script>
