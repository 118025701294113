<template>
  <b-card
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <h6>This Month’s Sales</h6>
    <div
      v-if="response.isLoading"
      class="d-flex align-items-center justify-content-center my-3"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="40"
      />
    </div>
    <template v-else>
      <h2 class="font-weight-bolder mb-1">
        {{ formatNumber(response.data.sales) }}
      </h2>
      <!-- chart -->
      <vue-apex-charts
        height="70"
        :options="statisticsProfit.chartOptions"
        :series="series"
      />
    </template>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useApi } from '@core/comp-functions/useApi'
import { formatNumber } from '@core/utils/utils'

const [res, apiRequest] = useApi('monthlysales')

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      response: res,
      statisticsProfit: {
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  computed: {
    series() {
      const { data } = this.response
      const list = []
      if (data) list.push(data.sales)
      return [{ data: list }]
    },
  },
  watch: {
    'response.isFailure': function (isFailure) {
      if (isFailure) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: this.response.error || 'Failed to fetch resource',
            icon: 'TrashIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    apiRequest()
  },
  methods: {
    formatNumber,
  },
}
</script>
