<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { getUserWallets } from "@/service/api.service";

export default {
  components: {
    BCard,
  },
  data() {
    return {
      userWallets: null,
      loading: false,
      userId: null,
    };
  },
  async created() {
    this.userId = this.$route.params.userId;
    await this.fetchUserWallets();
  },
  methods: {
    fetchUserWallets() {
      this.loading = true;
      // eslint-disable-next-line no-undef
      getUserWallets(this.userId)
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.userWallets = response.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <b-card>
      <h3>User Wallets</h3>
      <div class="row mt-5">
        <div class="col-lg-3">
          <div
            class="py-2 balance-card bg-warning text-white d-flex flex-column justify-content-center align-items-center rounded"
          >
            <p>FUNDRAISERS WALLET</p>
            <h2 class="text-white">&#8358;{{userWallets.fundraiserWallet.toLocaleString()}}</h2>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="py-2 balance-card bg-secondary text-white d-flex flex-column justify-content-center align-items-center rounded"
          >
            <p>ACCOUNT WALLET</p>
            <h2 class="text-white">&#8358;{{userWallets.accountWallet.toLocaleString()}}</h2>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="py-2 balance-card bg-primary text-white d-flex flex-column justify-content-center align-items-center rounded"
          >
            <p>REFER & EARN WALLET</p>
            <h2 class="text-white">&#8358;{{userWallets.referAndEarnWallet.toLocaleString()}}</h2>
          </div>
        </div>
        <div class="col-lg-3">
          <div
            class="py-2 balance-card bg-info text-white d-flex flex-column justify-content-center align-items-center rounded"
          >
            <p>PROMOTE & EARN WALLET</p>
            <h2 class="text-white">&#8358;{{userWallets.promoteAndEarnWallet.toLocaleString()}}</h2>
          </div>
        </div>
        
      </div>
    </b-card>
  </div>
</template>
