<template>
  <div>
    <b-card>

      <div
        class="mb-3"
        style="display: flex; justify-content: space-between; border-bottom: 1px solid #f5f6f9; padding-bottom: 1rem"
      >
        <!-- {{ fundraiserData}} -->
        <div
          v-if="fundraiserData"
          style="font-size: 18px; color: #5E5873;"
        >
          {{ fundraiserData.title }}
        </div>
        <div>
          <feather-icon
            icon="EditIcon"
            size="20"
            style="cursor: pointer"
          />
        </div>
      </div>
      <hr>

      <b-row
        class="justify-content-center align-center"
        align-content="center"
      >
        <b-col md="8">
          <b-form
            class="my-3"
            @submit.prevent="updateComponents()"
          >
            <b-form-group
              class="pt-2"
              label="Select all relevant payment methods and click the update button"
            >
              <b-form-checkbox-group
                id="fundraisercomponents"
                v-model="fundraiserComponents"
                class=" "
                :options="options"
                stacked

                name="fundraiserComponents"
              />
            </b-form-group>

            <b-button
              type="submit"
              :disabled="loading"
              variant="primary"
              class="mt-3"
            >
              <b-spinner v-if="loading" />
              <span v-else>Update Components</span>
            </b-button>
          </b-form>

        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { savefundraiserComponents, getFundDetails } from '@/service/api.service'
import {
  BCard, BForm, BFormGroup, BFormCheckboxGroup, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BForm, BFormGroup, BFormCheckboxGroup, BRow, BCol, BButton, BSpinner,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    fundraiserId: {
      type: String,
    },
  },
  data() {
    return {
      fundraiserData: null,
      fundraiserComponents: [],

      loading: false,
      options: [
        { text: 'Fundraiser Type', value: '1' },
        { text: 'Raised and Target Amount', value: '2' },
        { text: 'Top Blessor', value: '3' },
        { text: 'Related Fundraisers', value: '4' },
      ],
    }
  },
  async created() {
    await this.getFundraiserDetails()
  },
  methods: {
    getFundraiserDetails() {
      this.loading = true
      // eslint-disable-next-line no-undef
      getFundDetails(this.fundraiserId)
        .then(res => res.json())
        .then(response => {
          this.loading = false
          if (response.valid) {
            this.fundraiserData = response.data
            this.fundraiserComponents = this.fundraiserData.components.split(',')
          }
        })
        .catch(() => {
          this.loading = false
        })
    },

    updateComponents() {
      this.loading = true
      savefundraiserComponents(this.fundraiserId, {
        components: this.fundraiserComponents.toString(),
      })
        .then(res => res.json())
        .then(response => {
          this.loading = false

          if (response.valid) {
            this.showToast(
              'Success',
              'success',
              'Fundraiser components updated successfully!',
            )
          }
        }).catch(e => {
          this.loading = false
          this.showToast('Error', 'danger', e)
        })
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: title === 'Error' ? 'TrashIcon' : 'DoneIcon',
          variant,
        },
      })
    },

  },
}
</script>

  <style>

  </style>
