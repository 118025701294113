<template>
  <b-modal id="change-verification" centered hide-footer>
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Change Verification
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Update user verification
      </div>
      <div>
        <div
          class="verification"
          :class="selectedType === 'UNVERIFIED' ? 'selected' : ''"
          @click="selectVerification('UNVERIFIED')"
        >
          <b-row>
            <b-col cols="3">
              <feather-icon
                icon="MinusCircleIcon"
                size="40"
                class="ml-2 mt-1"
              />
            </b-col>
            <b-col>
              <div class="header">Unverified</div>
              <div class="description">
                This is the default state for every new user. It can be changed
                anytime
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          class="verification mt-2"
          :class="selectedType === 'VERIFIED' ? 'selected' : ''"
          @click="selectVerification('VERIFIED')"
        >
          <b-row>
            <b-col cols="3">
              <feather-icon
                icon="CheckCircleIcon"
                size="40"
                class="ml-2 mt-1"
              />
            </b-col>
            <b-col>
              <div class="header">Verified</div>
              <div class="description">
                This badge is assigned to users who have been verified on the
                platform.
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button variant="primary" @click="updateVerification">Submit</b-button>
        <b-button variant="outline-secondary" class="ml-1">Discard</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { updateVerificationStatus } from '@/service/api.service';

export default {
  name: "ChangeVerificationModal",
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  props: {
    userId: {
      type: String
    },
    verificationStatus: {
      type: String
    }
  },

  data() {
    return {
      selectedType: this.verificationStatus,
    };
  },

  methods: {
    selectVerification(type) {
      this.selectedType = type;
    },
    updateVerification(){
      updateVerificationStatus({ userid: this.userId, verificationStatus: this.selectedType })
      .then(res => res.json())
      .then(response => {
        this.$emit('refresh')
      })
    }
  },
};
</script>

<style scoped>
.verification {
  padding: 20px;
  border-radius: 6px;
  background: rgba(186, 191, 199, 0.12);
  cursor: pointer;
}

.selected {
  border: 1px solid #7367f0 !important;
  background: rgba(115, 103, 240, 0.12) !important;
  color: #7367f0 !important;
}

.header {
  font-weight: 500;
  font-size: 21px;
}

.description {
  font-weight: 400;
  font-size: 14px;
}
</style>
