export default [
  {
    header: 'CS Apps',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
  },
  {
    title: 'Fundraisers',
    route: 'fundraiser',
    icon: 'DropletIcon',
  },
  {
    title: 'Transactions',
    route: 'transactions',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Messages',
    route: 'messages',
    icon: 'MessageSquareIcon',
    // tag: '2',
    // tagVariant: 'primary',
  },
  {
    title: 'Email',
    route: 'apps-email',
    icon: 'MailIcon',
    // tag: '2',
    // tagVariant: 'primary',
  },
  {
    title: 'FAQs',
    route: 'faqs',
    icon: 'CheckSquareIcon',
  },
]
