<template>
  <div>
    <b-card title="Row details support" no-body>
      <div style="padding: 20px">
        <div style="font-weight: 500; font-size: 18px;">
          Log History
        </div>
      </div>
      <div
        v-if="response.isLoading"
        class="d-flex align-items-center justify-content-center h-100 mt-16"
        style="margin-top: 4rem"
      >
        <feather-icon icon="LoaderIcon" class="spinner" size="60" />
      </div>
      <b-table
        :items="response.data"
        :fields="fields"
        responsive
        class="mb-0"
        v-else
      >
        <template #cell(fundraiser)="data">
          <div class="d-flex align-items-center">
            <div>
              <div style="color: #7367F0">
                {{ data.item.title }}
              </div>
              <div class="font-small-2 text-muted">
                {{ getDescription(data.item.fundraiserDesc) }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(raisedAmount)="data">
          <div>
            {{ getFormattedAmount(data.item.raisedAmount) }}
          </div>
        </template>

        <template #cell(targetAmount)="data">
          <div>
            {{ getFormattedAmount(data.item.targetAmount) }}
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal id="modal-center" centered hide-footer hide-header>
      <div style="padding: 30px; text-align: center;">
        <div style="font-size: 24px; font-weight: 500">User ID</div>
        <div
          style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px;"
        >
          Uploaded User ID
        </div>
        <b-img
          :src="imageURL"
          fluid
          alt="Responsive image"
          style="width: 369px; height: 222px;"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useApi } from "@core/comp-functions/useApi";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
  },
  data() {
    return {
      response: null,
      userId: null,
      offset: 0,
      limit: 50,
      perPage: 10,
      imageURL: "",
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      catoptions: [
        { value: "Standard", text: "Standard" },
        { value: "VIP", text: "VIP" },
        { value: "Charity", text: "Charity" }
      ],
      fields: [
        { key: "fundraiser", label: "Fundraiser" },
        { key: "raisedAmount", label: "Raised" },
        { key: "targetAmount", label: "Target" },
      ],
      status: [
        {
          ABSENT: "light-primary",
          PAID: "light-success",
          PROLIFICFUNDRAISER: "light-danger",
          INITIATED: "light-warning",
          6: "light-info",
        },
      ],
    };
  },
  computed: {
    getEntryNumber() {
      return (
        this.offset + (this.response.data && this.response.data.length ? 1 : 0)
      );
    },
    getEntriesCount() {
      return this.offset + (this.response.data ? this.response.data.length : 0);
    },
  },
  // watch: {
  //   "response.isFailure": function(isFailure) {
  //     if (!isFailure) {
  //       this.$toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: "Error",
  //           text: this.response.error || "Failed to fetch resource",
  //           icon: "TrashIcon",
  //           variant: "danger",
  //         },
  //       });
  //     }
  //   },
  // },
  async created() {
    this.userId = this.$route.params.userId;
    await this.getFundraisers();
  },
  methods: {
    dayjs,
    getFundraisers(offset = 0) {
      const [res, apiRequest] = useApi(
        `fundraiser/findAllByUser/${this.userId}`
      );
      apiRequest();
      this.response = res;
    },
    getDescription(str = '') {
      return str && str.length > 80 ? str.substring(0, 80) + "..." : str;
    },
    getFormattedAmount(amount) {
      return amount.toLocaleString();
    },
  },
};
</script>

<style scoped>
.file {
  width: 60px;
  border: 2px solid #7367f0;
  border-radius: 2px;
  cursor: pointer;
}

.file-img {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}
</style>
