<template>
  <b-modal
    id="internal-message"
    ref="internal-message-modal"
    centered
    hide-footer
  >
    <div style="padding: 25px;">
      <div style="font-size: 24px; font-weight: 500; text-align: center;">
        Message User Internally
      </div>
      <div
        style="font-size: 14px; font-weight: 400; padding-top: 10px; padding-bottom: 24px; text-align: center;"
      >
        Please use the form below to send the user an internal message
      </div>
      <div>
        <b-form-group label="Subject" label-for="subject">
          <b-form-select
            :options="subjects"
            id="subject"
            :value="subject"
            @change="onChange($event, 'subject')"
          />
        </b-form-group>
      </div>
      <div class="mt-2">
        <label for="message">Message</label>
        <b-form-textarea
          id="message"
          placeholder="Type message here"
          rows="4"
          :value="message"
          @change="onChange($event, 'message')"
        />
      </div>

      <div class="mb-0 text-center mt-2">
        <!-- basic -->
        <b-button variant="primary" @click="sendMessage">Send Message</b-button>
        <b-button variant="outline-secondary" class="ml-1" @click="closeModal"
          >Discard</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  //   BFormCheckbox,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BPagination,
} from "bootstrap-vue";
import { sendInternalMessage } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTable,
    // BButton,
    // BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BFormTextarea,
  },

  props: {
    userId: {
      type: String,
    },
  },

  data() {
    return {
      categories: [{ text: "Standard", value: "STANDARD" }],
      subjects: [
        { text: "Select subject", value: null },
        { text: "Welcome", value: "1" },
        { text: "Withdrawal Issue", value: "2" },
        { text: "Donation Issue", value: "3" },
        { text: "Fundraiser Issue", value: "4" },
        { text: "Profile Issue", value: "5" },
        { text: "Technical Issue", value: "6" },
        { text: "Suggestion", value: "7" },
        { text: "Other", value: "8" },
      ],
      subject: null,
      message: "",
    };
  },

  methods: {
    sendMessage() {
      const payload = {
        userId: this.userId,
        subjectId: this.subject,
        message: this.message,
        sender: 1,
        recepient: 2,
      };
      sendInternalMessage(payload)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.message = "";
            this.subject = null;
            this.$emit("refresh");
            this.showToast("Success", "success", response.message);
            this.closeModal()
          } else {
             this.showToast("Error", "danger", response.message);
          }
        })
        .catch(e => this.showToast("Error", "danger", e))
    },
    closeModal() {
      this.$nextTick(() => {
        this.$refs["internal-message"].toggle("#toggle-btn");
      });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    onChange(value, id) {
      this[id] = value;
    },
  },
};
</script>

<style scoped></style>
