<template>
  <div>
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center h-100 mt-16"
      style="margin-top: 4rem"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="60"
      />
    </div>
    <div v-else>
      <b-row>
        <b-col cols="9">
          <div class="image-card">
            <b-card>
              <div
                :class="getBgColor()"
                style="padding-bottom: 2rem;"
              >
                <img
                  v-if="fundraiserData.fundraiserType == 'PERMANENT'"
                  :src="fundraiserData.coverImage"
                  width="100%"
                  alt=""
                >
                <b-carousel
                  v-else
                  controls
                  indicators
                >
                  <b-carousel-slide
                    v-for="(image, index) in galleryImages"
                    :key="index"
                    :img-src="image.imageurl"
                  />
                </b-carousel>
              </div>
              <div class="p-2">
                <div style="font-size: 21px;">
                  {{ fundraiserData.title }}
                </div>
                <div class="mt-1 d-flex justify-content-between">
                  <div>

                    <span class="text-muted font-small-2">Created by</span>
                    <span>
                      {{ fundraiserData.userdetail.firstname }}
                      {{ fundraiserData.userdetail.lastname }}</span>
                    <span class="text-muted font-small-2">
                      |
                      {{
                        dayjs(fundraiserData.createdAt).format("DD/MM/YYYY")
                      }}</span>
                    <span class="text-muted font-small-2">
                      Ends:
                      {{
                        dayjs(fundraiserData.nextPayoutDate).format("DD/MM/YYYY")
                      }}</span>
                  </div>
                  <span>{{fundraiserData.fundraiserType !== 'PERMANENT' ?  getTime() : 'PERMANENT' }}</span>
                </div>
                <b-badge
                  class="mt-1"
                  variant="light-danger"
                >
                  {{ fundraiserData.category.categoryname }}
                </b-badge>
                <b-badge
                  class="mt-1 ml-2"
                  variant="light-success"
                >
                  {{ fundraiserData.userdetail.userCategory }}
                </b-badge>
                <div
                  style="display: flex; justify-content: space-between"
                  class="mt-1"
                >
                  <div
                    style="font-size: 18px; color: #161722; font-weight: bold"
                  >
                    {{ getFormattedAmount(fundraiserData.raisedAmount) }}
                  </div>
                  <div
                    style="font-size: 18px; color: #161722; font-weight: bold"
                  >
                    {{ getFormattedAmount(fundraiserData.targetAmount) }}
                  </div>
                </div>
                <div class="mt-1">
                  <b-progress
                    :value="fundraiserData.raisedAmount"
                    :max="fundraiserData.targetAmount"
                    show-progress
                    variant="success"
                    class="progress-bar-success"
                  />
                </div>
                <div
                  style="display: flex; justify-content: space-between"
                  class="mt-1"
                >
                  <div>
                    Raised
                  </div>
                  <div>
                    Target
                  </div>
                </div>
              </div>
            </b-card>
          </div>
          <b-card>
            <b-tabs>
              <b-tab active>
                <template #title>
                  <span>Overview</span>
                </template>
                <fundraiser-overview
                  :data="fundraiserData"
                />
              </b-tab>
              <b-tab>
                <template #title>
                  <span>Blessors</span>
                </template>
                <blessors />
              </b-tab>
              <b-tab v-if="fundraiserData.fundraiserType !== 'PERMANENT'">
                <template #title>
                  <span>Rewards</span>
                </template>
                <rewards :rewards="fundraiserData.rewards" />
              </b-tab>
              <b-tab v-if="fundraiserData.fundraiserType !== 'PERMANENT'">
                <template #title>
                  <span>Comments</span>
                </template>
                <comments />
              </b-tab>
              <b-tab v-if="fundraiserData.fundraiserType == 'PERMANENT'">
                <template #title>
                  <span>Gallery</span>
                </template>
                <b-row>
                  <b-col
                    v-for="(image, index) in fundraiserData.images"
                    :key="index"
                    cols="3"
                    class="mb-2"
                  >
                    <img
                      :src="image.imageurl"
                      width="100%"
                      alt=""
                    >
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab v-if="fundraiserData.fundraiserType == 'PERMANENT' && fundraiserData.teammembers.length">
                <template #title>
                  <span>Team Members</span>
                </template>
                <b-row
                  v-for="(member, index) in fundraiserData.teammembers"
                  :key="index"
                  class="p-1 mb-2"
                  style="border: 1px dashed #ddd; border-radius: 8px;"
                >
                  <b-col cols="3">
                    <img
                      :src="member.image"
                      alt=""
                      style="width: 100%"
                    >
                  </b-col>
                  <b-col cols="9">
                    <h4>{{ member.name }}</h4>
                    <h6>{{ member.role }}</h6>
                    <p>{{ member.description }}</p>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab v-if="fundraiserData.fundraiserType == 'PERMANENT' && fundraiserData.impactstories.length">
                <template #title>
                  <span>Impact Stories</span>
                </template>
                <b-row
                  v-for="(story, index) in fundraiserData.impactstories"
                  :key="index"
                  class="p-1 mb-2"
                  style="border: 1px dashed #ddd; border-radius: 8px;"
                >
                  <b-col cols="3">
                    <img
                      :src="story.image"
                      alt=""
                      style="width: 100%"
                    >
                  </b-col>
                  <b-col cols="9">
                    <h4>{{ story.title }}</h4>
                    <p>{{ story.description }}</p>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab v-if="fundraiserData.fundraiserType == 'PERMANENT'">
                <template #title>
                  <span>Contact</span>
                </template>
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <feather-icon icon="PhoneIcon" class="mr-1"  /> {{ fundraiserData.contactPhoneNumber }}
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <feather-icon icon="MapPinIcon" class="mr-1" /> {{ fundraiserData.contactAddress }}
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <feather-icon icon="MailIcon" class="mr-1"  /> {{ fundraiserData.contactEmail }}
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card>
            <div>
              <b-button
                variant="primary"
                class="w-full"
                @click="routeTo"
              >
                View Details
              </b-button>
            </div>
            <div>
              <b-button
                v-b-modal.action-modal
                variant="outline-secondary"
                class="w-full mt-1"
                @click="setAction('hide')"
              >
                Hide
              </b-button>
            </div>
            <div>
              <b-button
                v-b-modal.action-modal
                variant="outline-secondary"
                class="w-full mt-1"
                @click="setAction('suspend')"
              >
                Suspend
              </b-button>
            </div>
            <div>
              <b-button
                v-b-modal.action-modal
                variant="outline-secondary"
                class="w-full mt-1"
                @click="setAction('delete')"
              >
                Delete
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <action-modal
      :action="action"
      @action="actOnFundraiser($event)"
    />
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BFormInput,
  BModal,
  BButton,
  BFormSelect,
  BBadge,
  BPagination,
  BTabs,
  BTab,
  BCardText,
  BCarousel,
  BCarouselSlide,
  BProgress,
} from "bootstrap-vue";
import FundraiserOverview from "@/@core/components/fundraisers/FundraiserOverview.vue";
import Blessors from "@/@core/components/fundraisers/Blessors.vue";
import Rewards from "@/@core/components/fundraisers/Rewards.vue";
import Comments from "@/@core/components/fundraisers/Comments.vue";
import { getFundDetails, setFundraiserStatus } from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getYouTubeVideoId } from "@/@core/utils/utils";
import dayjs from "dayjs";
import ActionModal from "../../@core/components/fundraisers/modals/ActionModal.vue";
export default {
  components: {
    BTable,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BRow,
    BModal,
    BImg,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    BTabs,
    BTab,
    BCardText,
    FundraiserOverview,
    BCarousel,
    BCarouselSlide,
    BProgress,
    Blessors,
    Rewards,
    Comments,
    ActionModal,
  },
  data() {
    return {
      fundraiserId: null,
      fundraiserData: null,
      loading: false,
      action: null,
    };
  },

  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getFundraiserDetails();
  },

  computed: {
    galleryImages () {
      // Separate images into true and false arrays
      let featuredImages = this.fundraiserData?.images.filter((image) => image.isFeatured);
      let nonFeaturedImages = this.fundraiserData?.images.filter((image) => !image.isFeatured).slice().reverse()

      // Concatenate the two arrays, placing featured images first
      let rearrangedImages = featuredImages.concat(nonFeaturedImages);

      return rearrangedImages
    }
  },

  methods: {
    dayjs,
    getFundraiserDetails() {
      this.loading = true;
      getFundDetails(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.fundraiserData = response.data;
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.showToast("Error", "danger", e);
        });
    },
    getId(link) {
      const { value } = getYouTubeVideoId(link);
      return value;
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    getFormattedAmount(amount) {
      return `N${amount.toLocaleString()}`;
    },
    actOnFundraiser(type) {
      setFundraiserStatus(this.fundraiserId, type)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.showToast("Success", "success", response.message);
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    setAction(type) {
      this.action = type;
    },
    routeTo(){
      this.$router.push(`/fundraisers/${this.fundraiserId}/view-details`)
    },
    getBgColor(){
      if (this.fundraiserData){
        return this.fundraiserData.fundraiserType.toLowerCase()
      }
      return 'onetime'
    },
    // getTime () {
    //   const { createdAt, duration } = this.fundraiserData;
    //   console.log('Date created: ', createdAt, duration);
    //   if (this.fundraiserData.status == 'EXPIRED') return 'Expired';
    //   const daysLeft = Math.abs(
    //     dayjs().diff(dayjs(createdAt).add(duration, 'day'), 'day')
    //   );
    //   return `${daysLeft} days left`;
    // },

     getTime  () {
      const { createdAt, duration, nextPayoutDate } = this.fundraiserData;
      console.log('Date created: ', createdAt, duration);
      if (this.fundraiserData.status == 'EXPIRED') return 'Expired';
      if(dayjs().isAfter(dayjs(nextPayoutDate))) return 'Expired'
      let daysLeft = Math.abs(
        // dayjs().diff(dayjs(createdAt).add(duration, 'day'), 'day')
        dayjs().diff(dayjs(nextPayoutDate), 'day')
      );
      daysLeft += 1;

      if (daysLeft == 0) {
        return `Few hours left`;
        // this.showCountdown = true;
      }
      return `${daysLeft} ${daysLeft == 1 ? 'day' : 'days'} left`;
    }
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}

.image-card .card-body {
  padding: 0px !important;
}

.onetime {
  background: #E0F2FF;
}

.recurring {
  background: #FEEDC8;
}
</style>
