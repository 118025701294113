<template>
  <main>
    <form @submit="onSubmit">
      <b-card>
        <h3 style="font-weight: bold">Privacy Policy</h3>
        <ckeditor v-model="content" :config="editorConfig"></ckeditor>
      </b-card>
      <b-button variant="primary" type="submit">{{
        loading ? "Saving" : "Save"
      }}</b-button>
    </form>
  </main>
</template>

<script>
/* eslint-disable */
import { BCard, BRow, BCol, BFormTextarea, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { editPrivacyPolicy, fetchPrivacyPolicy } from "@/service/api.service";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
  },

  data() {
    return {
      content: "",
      loading: false,
      data: null,
      editorData: "<p>Type privacy policy here...</p>",
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  created() {
    this.getPrivacyPolicy();
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
    onSubmit(e) {
      e.preventDefault();
      this.updatePrivacyPolicy();
    },
    getPrivacyPolicy() {
      fetchPrivacyPolicy()
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.data = response.data;
            this.content = this.data.content;
          }
        })
        .catch((e) => {
          this.showToast("Error", "danger", e);
        });
    },
    updatePrivacyPolicy() {
      this.loading = true;
      editPrivacyPolicy({ id: this.data.id, content: this.content })
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.showToast(
              "Success",
              "success",
              "Privacy Policy updated successfully"
            );
          }
        })
        .catch((e) => {
          this.loading = false;
          this.showToast("Error", "danger", e);
        });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped></style>
