<template>
  <main>
    <b-card>
      <div style="width: 600px" class="m-auto">
        <b-row class="mt-2 mb-2">
          <b-col cols="5" style="border-right: 1px solid #f3f3f3">
            <div class="mb-3" style="font-weight: 500; font-size: 20px;">
              Roles
            </div>
            <div
              v-for="(role, index) in roles"
              :key="index"
              class="pt-1 pl-1 pb-1 cursor-pointer "
              :class="currentRoleId === role.id ? 'active' : ''"
              style="border-bottom: 1px solid #f3f3f3"
              @click="setCurrentRoleId(role.id)"
            >
              {{ role.role }}
            </div>
          </b-col>
          <b-col cols="7">
            <div class="mb-3" style="font-weight: 500; font-size: 20px;">
              Permissions
            </div>
            <div
              v-for="(permission, index) in permissions"
              :key="index"
              class="mt-1 pb-1 ml-1"
            >
              <b-form-checkbox
                :id="permission.id.toString()"
                @change="onCheckboxChange($event, permission.id.toString())"
                ref="permissions"
                :checked="isChecked(permission.id.toString())"
              >
                <span class="ml-1">{{ permission.description }}</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <div class="mt-3 mb-2" style="">
          <b-button variant="primary" @click="savePermissions">Save</b-button>
        </div>
      </div>
    </b-card>
  </main>
</template>

<script>
/* eslint-disable */
import { BCard, BRow, BCol, BFormCheckbox, BButton } from "bootstrap-vue";
import {
  getAllPermissions,
  getUserRoles,
  updateAdminRole,
} from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      roles: [],
      permissions: [],
      currentRoleId: null,
    };
  },
  async created() {
    await this.getRoles();
    await this.getPermissions();
  },
  methods: {
    isChecked(permissionId) {
      if (this.currentRoleId && permissionId) {
        const roleObj = this.roles.find(
          (role) => role.id === this.currentRoleId
        );
        const splittedPermissions = roleObj.permissions.split(',')
        const findPerm = splittedPermissions.find(permission => permission === permissionId)

        if (roleObj && findPerm) {
          return true;
        }
      }
      return false;
    },
    setCurrentRoleId(id) {
      this.currentRoleId = id;
    },
    savePermissions() {
      let perms = [];
      this.$refs.permissions.forEach((permission, index) => {
        if (permission.checked) {
            perms.push(permission.id)
        }
      });
      const finalPermissions = perms.join(',')
      if (finalPermissions.length) {
        this.updateRole(finalPermissions, this.currentRoleId);
      } else {
        this.showToast(
          "Error",
          "danger",
          "Role should have at least one permission"
        );
      }
    },
    onCheckboxChange(event, id) {
      const ref = this.$refs.permissions.find((ref) => ref.id === id);
      ref.checked = event
    },
    updateRole(permissions, roleId) {
      updateAdminRole(roleId, { permissions })
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            // this.getPermissions();
            this.showToast("Success", "success", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    getRoles() {
      getUserRoles()
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.roles = response.data;
            this.currentRoleId = this.roles[0].id;
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    getPermissions() {
      getAllPermissions()
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            // this.permissions = []
            this.permissions = response.data
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
  },
};
</script>

<style scoped>
.active {
  background: #eceaf7 !important;
}
</style>
