<template>
  <div>
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center h-100 mt-16"
      style="margin-top: 4rem"
    >
      <feather-icon
        icon="LoaderIcon"
        class="spinner"
        size="60"
      />
    </div>
    <div v-else>
      <b-card style="height: auto">
        <div
          style="display: flex; justify-content: space-between; border-bottom: 1px solid #f5f6f9; padding-bottom: 1rem"
        >
          <div
            v-if="fundraiserData"
            style="font-size: 18px; color: #5E5873;"
          >
            {{ fundraiserData.title }}
          </div>
          <div>
            <feather-icon
              icon="EditIcon"
              size="20"
              style="cursor: pointer"
            />
          </div>
        </div>
        <b-row>
          <b-col
            v-for="(image, index) in images"
            :key="index"
            cols="6"
            class="mt-2"
          >
            <div>
              <gallery-image
                :id="image.id"
                :image="image.imageurl"
                :index="index"
                :is-featured.sync="image.isFeatured"
                @delete="deleteImage($event)"
                @setFeaturedImage="setFeaturedImage"
              />
            </div>
          </b-col>
          <b-col
            v-for="(imgData, index) in selectedImages"
            :key="index + 'a'"
            cols="6"
            class="mt-2"
          >
            <div>
              <gallery-image
                :image="imgData"
                :index="index"
                @delete="deleteImage($event)"
              />
            </div>
          </b-col>
          <b-col
            cols="6"
            class="mt-2"
          >
            <form id="form">
              <input
                id="imagefile"
                type="file"
                style="display: none"
                @change="onFileSelected"
              >
            </form>
            <label
              style="border: 1px dashed #7666F8; width: 100%; border-radius: 6px; height: 23rem; cursor: pointer"
              for="imagefile"
            >
              <div
                style="text-align: center; color: #7666F8; margin-top: 9rem"
                class="font-small"
              >
                Click files here to upload
              </div>
            </label>
          </b-col>
        </b-row>
        <div class="mb-0 text-center mt-4">
          <!-- basic -->
          <b-button
            variant="primary"
            :disabled="selectedImages.length ? false : true"
            @click="saveImages"
          >Update</b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
          >
            Discard
          </b-button>
        </div>
      </b-card>
    </div>
    <image-cropper
      :open="openCropper"
      :image="image"
      @add="addImage($event)"
      @close="close"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import {
  destroyImage,
  getFundDetails,
  getFundraiserImages,
  uploadImages,
  markAsFeaturedImage,
} from "@/service/api.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dayjs from "dayjs";
import GalleryImage from "@/@core/components/fundraisers/galley/GalleryImage.vue";
import ImageCropper from "@/@core/components/fundraisers/galley/ImageCropper.vue";
export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    GalleryImage,
    ImageCropper,
  },
  data() {
    return {
      fundraiserId: null,
      fundraiserData: null,
      images: [],
      selectedImages: [],
      loading: false,
      image: null,
      openCropper: false,
      filesToUpload: [],
      imageObject: null,
    };
  },

  async created() {
    this.fundraiserId = this.$route.params.fundraiserId;
    await this.getImages();
    await this.getFundraiserDetails();
  },

  methods: {
    dayjs,
    getFundraiserDetails() {
      this.loading = true;
      getFundDetails(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.fundraiserData = response.data;
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.showToast("Error", "danger", e);
        });
    },
    getImages() {
      this.loading = true;
      getFundraiserImages(this.fundraiserId)
        .then((res) => res.json())
        .then((response) => {
          this.loading = false;
          if (response.valid) {
            this.images = response.data;
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.showToast("Error", "danger", e);
        });
    },
    showToast(title, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: text,
          icon: title === "Error" ? "TrashIcon" : "DoneIcon",
          variant: variant,
        },
      });
    },
    getImageData(e) {
      const input = e.target;
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = function() {
          const dataUrl = reader.result;
          resolve(dataUrl);
        };
        reader.readAsDataURL(input.files[0]);
        this.resetFileInput();
      });
    },
    async onFileSelected(e) {
      console.log("E ", e.target.files[0]);
      const fileObject = e.target.files[0];
      this.imageObject = {
        name: fileObject.name,
        mimetype: fileObject.type,
      };
      this.image = await this.getImageData(e);
      this.openCropper = true;
    },
    addImage(image) {
      this.selectedImages.push(image);
      this.filesToUpload.push({ ...this.imageObject, base64: image });
      this.openCropper = false;
    },
    close() {
      this.openCropper = false;
    },
    resetFileInput() {
      const form = document.getElementById("form");
      form.reset();
    },
    deleteImage({ id, index }) {
      if (id === 0) {
        this.selectedImages = this.selectedImages.filter(
          (image, idx) => idx !== index
        );
        this.filesToUpload = this.filesToUpload.filter(
          (file, idx) => idx !== index
        );
      } else {
        this.removeImage(id);
      }
    },
    removeImage(id) {
      destroyImage(this.fundraiserId, id)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            this.getImages();
            this.showToast("Success", "success", "Image successfully deleted");
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },
    saveImages() {
      const payload = {
        fundraiserId: this.fundraiserId,
        deleteItems: [],
        files: this.filesToUpload,
      };
      uploadImages(payload)
        .then((res) => res.json())
        .then((response) => {
          if (response.valid) {
            setTimeout(() => {
              this.getImages();
              this.selectedImages = [];
              this.filesToUpload = [];
              this.imageObject = null;
            }, 2000);
          } else {
            this.showToast("Error", "danger", response.message);
          }
        })
        .catch((e) => this.showToast("Error", "danger", e));
    },

    setFeaturedImage(imageId){
      const payload = {
        imageId, fundraiserId: this.fundraiserId
      }

      markAsFeaturedImage(payload).then(async response => {
        console.log(response);
        if(response.valid){
          this.showToast('Success', 'success', 'Image marked as featured Image')
        }else{
          this.showToast('Error', 'danger', response.message)
        }
        await this.getFundraiserDetails()
        this.$forceUpdate();
      })
    }
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}

.image-card .card-body {
  padding: 0px !important;
}
</style>
