<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <admin-info />
      </b-col>
      <b-col
        xl="8"
        md="6"
        v-if="isViewDashboard"
      >
        <dash-statistics />
      </b-col>
    </b-row>

    <b-row class="match-height" v-if="isViewDashboard">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <auth-chart />
          </b-col>

          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <!-- <monthly-sales-chart /> -->
            <WalletBalances />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <traffic-chart />
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <blessing-commission-report />
      </b-col>
    </b-row>

    <b-row class="match-height" v-if="isViewDashboard">
      <b-col lg="8">
        <transaction-table />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <blessing-processor-overview />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <top-blessors />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <top-fundraisers />
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <latest-users />
      </b-col>
    </b-row>
  </section>
</template>

<script>
/* eslint-disable */
import { BRow, BCol } from 'bootstrap-vue'

import AdminInfo from './AdminInfo.vue'
import DashStatistics from './DashStatistics.vue'
import AuthChart from './AuthChart.vue'
import MonthlySalesChart from './MonthlySalesChart.vue'
import TrafficChart from './TrafficChart.vue'
import BlessingCommissionReport from './BlessingCommissionReport.vue'
import TransactionTable from './TransactionTable.vue'
import TopBlessors from './TopBlessors.vue'
import TopFundraisers from './TopFundraisers.vue'
import LatestUsers from './LatestUsers.vue'
import BlessingProcessorOverview from './BlessingProcessorOverview.vue'
import { isPermitted } from '@/@core/utils/utils'
import { USER_PERMISSIONS } from '@/data/permissions'
import WalletBalances from './WalletBalances.vue'

export default {
  components: {
    BRow,
    BCol,
    AdminInfo,
    DashStatistics,
    AuthChart,
    BlessingCommissionReport,
    MonthlySalesChart,
    TrafficChart,
    TransactionTable,
    TopBlessors,
    TopFundraisers,
    LatestUsers,
    BlessingProcessorOverview,
    WalletBalances
},

  computed: {
    isViewDashboard() {
      const permissions = this.$store.state.auth.permissions
      return isPermitted(USER_PERMISSIONS.VIEW_DASHBOARD, permissions)
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
